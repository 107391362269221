
import * as THREE from 'three';
import BobRoss from './BobRoss';
import Art from './Art';
import ArtField from './ArtField';
import People from './People';

class Exhibit extends THREE.Object3D {

	constructor(data, container, camera) {

		super();

    this.data = data || {};



		this.camera = camera;
		this.isGroup = true;
		this.type = 'Group';
		this.container = container;
    	this.random = Math.random();

	}

	zoomArt(z) {


		const zmin = -750;
		const zmax = 500;//2300


	}

	addArt() {

		if(!this.art){

			this.art = new ArtField();
			this.add(this.art);


		}

	}

	addBob() {
		if(!this.container)alert('no container bob ');
		if(!this.bobross){
			this.bob = new BobRoss(this.data, this.container, this.camera);
			this.add(this.bob);
		}
	}

	addPeople() {
		if(!this.people){
			this.people = new People(this.data, this.container, this.camera);
			this.add(this.people);
		}
	}

	render() {
		if(this.bob)this.bob.render();
		if(this.art)this.art.render();
	}



	layout(data, artChanged) {



		//console.log('layout - ' + this.container.id);

		if(!this.container.offsetHeight || !this.container.offsetWidth){
			console.log('BROKEN CONTAINER NO HEIGHT OR WIDTH!!!');
			return;
		}




		let camWidth = this.container.offsetWidth;
		this.camera.aspect = camWidth / this.container.offsetHeight;




		var horizontalFov = 90;
		this.camera.fov = (Math.atan(Math.tan(((horizontalFov / 2) * Math.PI) / 180) / this.camera.aspect) * 2 * 180) / Math.PI;
		this.camera.updateProjectionMatrix();

		//console.log(`fov ${this.camera.fov} ${camWidth} ${this.camera.aspect}`);



		if(data)this.data = data;



		if(!this.bob)this.addBob();
		//kv.x.bob.visible = false;
		if(!this.art)this.addArt();
		if(!this.people)this.addPeople();


		//if(artChanged){

		this.art.layout(this.data, artChanged);
		//}
		this.people.layout(this.data);

		this.bob.layout(this.data);


	}

	destroy() {
		if(this.bob)this.bob.destroy();
		if(this.art)this.art.destroy();
		if(this.people)this.people.destroy();
	}



}


export default Exhibit
