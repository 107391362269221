
class UserEdit {

  constructor(user) {
    this.init(user);
  }

  init(user) {
    this.user = user;
    this.createView();

    this.simpleMode = user.simple;
    this.voices = kv.voices;


  }

  createView() {
    this.view = document.createElement('div');
    this.view.id = "userSettingsContainer";
    const stuff = `
    <div id="welcomeRow" style="color:white;" class="row">
      <div style="height:60px;margin:auto;text-align:center;font-size: 50px; text-transform: lowercase;font-family: Retro Signature; color: white;">
        The Kiloverse
      </div>
    </div>

    <div class="row userRow d-none" id="userLoadingRow">
      <div class="loadingText">Processing Selfie...</div>
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="row userRow d-none" id="nameSocialRow">

    <div class="row">
      <div style="text-align:center;" id="userIconRow" class="col">
        <div style="margin:auto;"  id="userProfileIcon"></div>


        <button style="bottom:0px;" class="btn btn-primary" id="selfieButton">Take Selfie</button>
      </div>
      <div class="col">
        <label id="firstNameLabel" for="userName" class="form-label">First Name</label>
        <input class="form-control" id="userName" placeholder="first name">
      </div>
    </div>


    <div class="row d-none" id="userSocialRow">
      <div class="col">
        <label for="userInstagram" class="form-label">Instagram</label>
        <input class="form-control" id="userInstagram" placeholder="optional">
      </div>
      <div class="col">
        <label for="userTwitter" class="form-label">Twitter</label>
        <input class="form-control" id="userTwitter" placeholder="optional">
      </div>
    </div>

    </div><!--name social row?-->

    <div class="row userRow d-none" id="avatarStyleRow">
    <label for="human" class="form-label">Avatar style</label>

    <div class="row">

    <div id="catAvatar" class="avatarStyle" >
    cat
    </div>

    <div id="humanAvatar" class="avatarStyle">
    human
    </div>

    </div>


    </div>
    <div class="row userRow d-none" id="avatarRow">
    <label for="avatarPicker" class="form-label">Avatar</label>
    <div id="avatarPicker"></div>

    </div>
    <div class="row userRow d-none" id="selfieRow">
    <div>Give your camera permission and center head in the green square to record a video selfie.</div>
    <div id="selfieContainer" style="background-color:orange;width:250px;height:250px;"></div>

    <div class="row">


    <div class="col-12">
    <button class="mt-2 btn btn-primary round btn-danger w-100" id="selfieRecord">Record</button>
    </div>
    </div>

    <div class="row">
    <div class="col-12">
    <button class="mt-2 btn btn-primary round w-100" id="selfieCancel">Cancel</button>
    </div>
    </div>



    <div id="selfieStatus"></div>
    <div class="row">
    <div class="col">
    <button style="display:none;" class="btn btn-primary" id="selfieKeep">Yes</button>
    </div>
    <div  class="col">
    <button style="display:none;" class="btn btn-primary" id="selfieDontKeep">No</button>
    </div>

    </div>

    </div>
    <div class="row userRow d-none" id="kilowearRow">
    <label id="kilowearLabel" for="kilowearPicker" class="form-label">Kilowear</label>
    <div id="kilowearPicker"></div>
    </div>
    <div class="row userRow d-none" id="drinkRow">
    <div class="col">
    <label id="drinkLabel" for="drinkPicker" class="form-label">Drink</label>
    <div id="drinkPicker"></div>
    </div>
    </div>
    <div class="row userRow d-none" id="voiceRow" style="margin-bottom: 25px;"">
    <div class="col">
    <label for="voicePicker" class="form-label">Voice Tone:</label>
    <select id="voicePicker" class="form-select form-select-sm" aria-label=".form-select-sm example"></select>
    </div>
    <div id="pitchRow" class="col">
    <label for="userPitch" class="form-label">Pitch</label>
    <input type="range" class="form-range" min="0" max="100" id="userPitch" value="50">

    <button class="btn round" id="dafuck">TEST</button>

    <!--  <input type="range" class="form-range" min="0" max="100" id="userSpeed" value="50">-->
    </div>


    </div>

    </div>


        <div class="row" id="enterRow">

          <button id="enterButton" class="ml-3 mr-3 mt-1 btn btn-success round w-100">Enter</button>

        </div>


    <!--usersettingscontainer-->`

    this.view.innerHTML = stuff;

  }


  updateSelfieUI() {

    if(this.selfieFile){
      console.log('gums found selfie file');
    //  selfieUpload.style.display = 'none';
      selfieRecord.style.display = 'none';
      selfieStatus.style.display = 'block';
      selfieCancel.style.display = 'none';


    } else {
      console.log('gums no selfie file');
    //  selfieUpload.style.display = 'block';
      selfieRecord.style.display = 'block';
      selfieStatus.style.display = 'none';
      selfieCancel.style.display = 'block';
    }

  }

  makeBut(item) {

    var but = document.createElement('div');
    var url = 'https://cdn.kiloverse.io/'+item.id+'.png';
    but.className = 'itemButton';
    but.id = item.id;
    but.style = 'width:60px;height:60px;background-image:url('+url+');background-size:fit;';


    var del = document.createElement('div');
    del.className = "item-delete";
    del.id = but.id;
    del.onpointerup = function(){deleteFromItemLibrary(this.id);}
    del.style = "cursor:pointer;background-image:url('https://cdn.kiloverse.io/trash.png');background-size:contain;font-width:bolder;text-align:center;color:white;width:30px;height:30px;border-radius:15px;float:right;";
    //  if(window.admin || item.userId == kv.user.id)but.appendChild(del);

    return but;

  }



  loadUserSettings() {

    let user = this.user;
    this.state = 'everything';

    if(this.voices.length == 0){
      alert('not ready...');
      return;
    }

    voicePicker.innerHTML = '';
    var selectedVoice = 0;
    this.voices.forEach((voice, i) => {
      //ar-XA-Wavenet-A
      if(user && user.languageName == voice.name && user.languageGender == voice.ssmlGender)selectedVoice=i;
      var codename = voice.name.replace('en-US-','').replace('Wavenet-','') + ' ' + voice.ssmlGender;
      var nickname = '';
      //  debugger;
      nickname = codename;
      if(codename == 'A MALE')nickname = 'MALE, Sarcastic';
      if(codename == 'B MALE')nickname = 'MALE, Energetic';
      if(codename == 'D MALE')nickname = 'MALE, Official';
      if(codename == 'I MALE')nickname = 'MALE, Robotic';
      if(codename == 'J MALE')nickname = 'MALE, Confident';

      if(codename == 'C FEMALE')nickname = 'FEMALE, Sarcastic';
      if(codename == 'E FEMALE')nickname = 'FEMALE, Energetic';
      if(codename == 'F FEMALE')nickname = 'FEMALE, Official';
      if(codename == 'G FEMALE')nickname = 'FEMALE, Robotic';
      if(codename == 'H FEMALE')nickname = 'FEMALE, Confident';

      voicePicker.appendChild(new Option(nickname, i));
    });


    var drinks = kv.publicItems.filter(item => item.type == 'drink');
    var kilowear = kv.publicItems.filter(item => item.type == 'kilowear');
    var avatars = kv.publicItems.filter(item => item.type == 'avatar');

    if(kv.publicItems == undefined) {
      debugger;
    }

    avatars.sort(function(a, b){return b.created - a.created});

    const userCats = [{el:drinkPicker, items:drinks, active:user.drink},
      {el:kilowearPicker, items:kilowear, active:user.kilowear},
      {el:avatarPicker, items:avatars, active:user.avatar}];

      userCats.forEach((cat, i) => {
        cat.el.innerHTML = '';
        cat.items.forEach((item, j) => {
          var but = this.makeBut(item);
          but.addEventListener('pointerup', el=>{
            this.setItem(cat, item.id);
          });
          cat.el.append(but);
          if(cat.active)this.setItem(cat, cat.active.id);
        });
      });

      if(user){
        avatarPicker.value = user.avatar;
        drinkPicker.value = user.drink;
        kilowearPicker.value = user.kilowear;
        userName.value = user.name || '';
        userInstagram.value = user.instagram || '';
        userTwitter.value = user.twitter || '';
        voicePicker.value = selectedVoice;
        userPitch.value = ((user.pitch + 20.0)/40.0) * 100.0;
      }

      enterButton.addEventListener('pointerup', ()=>{

        this.save();
        kv.dismissViewController();

      });


      userProfileIcon.addEventListener('pointerup', function(){
        this.state = 'selfieRow';
        this.layout();
      });

      dafuck.addEventListener('pointerup', ()=>{
        //  alert('hi');
        this.voiceTest();
      });

      selfieButton.addEventListener('pointerup', function(){
        this.state = 'selfieRow';
        this.layout();
      }.bind(this));



      selfieRecord.addEventListener('pointerup', function(){
        this.recordSelfie();
        selfieRecord.style.display = 'none';
        selfieStatus.style.display = 'block';
        selfieKeep.style.display = 'none';
        selfieDontKeep.style.display = 'none';



        var counter = 1;
        var tt=setInterval(function(){
          if(counter == 10) {
            clearInterval(tt);


          } else {
            counter++;
            document.getElementById('selfieStatus').innerHTML= 'recording, ' + (10 - counter).toString() + ' seconds remaining...';


          }

        },1000);

      }.bind(this));




      this.state = ''

      //this.layout();

      kv.showHide(userSocialRow, !this.simpleMode);
      kv.showHide(welcomeRow, this.simpleMode);
      kv.showHide(userIconRow, !this.simpleMode);
      kv.showHide(pitchRow, !this.simpleMode);
      kv.showHide(enterRow, this.simpleMode);

      firstNameLabel.innerHTML = this.simpleMode ? 'Please enter your first name:' : 'First Name';
      kilowearLabel.innerHTML = this.simpleMode ? 'Select kilowear:' : 'Kilowear';
      drinkLabel.innerHTML = this.simpleMode ? 'Select drink:' : 'Drink';

    }



    setItem(cat, selectedItemId) {

      if(selectedItemId == 'selfie'){
        this.state = 'selfieRow';
        this.layout();
        return;
      }

      cat.items.forEach((item, i) => {
        var el = document.getElementById(item.id);
        if(!el){
          return;
        }
        el.classList.toggle('itemSelected', item.id == selectedItemId);
      });


      var av = false;


      // getSelectedItem(avatarPicker);
      var dr = this.getSelectedItem(drinkPicker);
      var kw = this.getSelectedItem(kilowearPicker);

      //  userProfileAvatar.style.backgroundImage = 'url(https://api.kiloverse.io/'+av+'.png)';
      //  userProfileKilowear.style.backgroundImage = 'url(https://api.kiloverse.io/'+kw+'.png)';
      //  userProfileDrink.style.backgroundImage = 'url(https://api.kiloverse.io/'+dr+'.png)';


      kv.makeComposite(this.user, userProfileIcon, {av:av, dr:dr, kw:kw});

    }



    setLoading(loading, message){
      //this.loading = loading;
      //dom.el['loadingmessage'+this.id].innerHTML = message;
      //this.layout();
    }


    dispose() {

    }

    update(user) {

    }


    playSelectedFile(event) {

      //debugger;
      var file = this.files[0]
      var type = file.type
      var fileURL = URL.createObjectURL(file)
      this.attachFile(fileURL);

    }



    setAvatarStyle(style) {

      if(style == 'human'){
        this.state = 'selfieRow';
        this.layout();
      }
      if(style == 'cat'){
        this.state = 'avatarRow';
        this.layout();
      }
    }

    layout() {

      if(!this.view.parentElement)return;
      if(this.view.parentElement  && !this.didLoadUser){
        this.loadUserSettings();
        this.didLoadUser = true;
        console.log(`${this.didLoadUser}`)
      }

      var steps = ['nameSocialRow', 'avatarStyleRow', 'avatarRow', 'selfieRow', 'voiceRow'];


      if(document.getElementById('modalSaveButton'))kv.showHide(modalSaveButton, this.state != 'loading');


      document.querySelectorAll('.userRow').forEach((item, i) => {

        var show = item.id == this.state;

        show = item.id!= 'userLoadingRow' &&  item.id != 'selfieRow' && item.id != 'avatarRow' && item.id != 'avatarStyleRow';
        if(this.state == 'selfieRow'){
          show = item.id == 'selfieRow';
        }


        if(this.state == 'loading') {
          show = item.id == 'userLoadingRow';
        }

        if(document.getElementById('modalNextButton'))modalNextButton.innerHTML =  (this.state == 'voiceRow') ?  'FINISH' : 'NEXT';
        if(document.getElementById('modalSaveButton'))modalSaveButton.style.display = (this.state=='selfieRow') ? 'none':'block';

        if(show)item.classList.remove("d-none");
        if(!show)item.classList.add("d-none");

      });

      if(this.state == 'selfieRow'){
        this.showSelfiePanel(this.user);
      }else{
        this.hideSelfiePanel();
      }


      if(this.state == 'nameSocialRow'){
        if(document.getElementById('modalBackButton')) document.getElementById('modalBackButton').classList.add('d-none');
      } else {
        if(document.getElementById('modalBackButton')) document.getElementById('modalBackButton').classList.remove('d-none');
      }
    }


    askToKeep() {

      if (confirm('Keep selfie?') == true) {

        if(!this.selfieFile){
          alert('Cannot find file');
          return;
        }


        this.state = 'loading';
        this.layout();

        var xhr = new XMLHttpRequest();

        xhr.open('POST', 'https://api.kiloverse.io/upload', true);
        xhr.onload = function () {

          if (xhr.status == 200) {
            let selfieId = JSON.parse(xhr.response).itemId;
            var avitem = JSON.parse(xhr.response);

            this.user.avatar = avitem;
            kv.newAvatarUpload = avitem;
            kv.launchUserSettings(this.user, false);//this is wrong
            this.state = '';

            this.layout();
            this.selfieFile = null;
            console.log('gums settings selfie file to null');
            this.updateSelfieUI();


          }
        }.bind(this);

        var formData = new FormData();
        var meta = {isSelfie:true, iOS:kv.iOS(), userId:this.user.id, type:'avatar', title:this.user.id};
        formData.append('metadata', JSON.stringify(meta));
        formData.append('form-files', this.selfieFile, 'selfie.data');
        xhr.send(formData);
      } else {
        this.selfieFile = null;
        this.updateSelfieUI();
      }

    }

    handleSelfie(file) {
      this.selfieFile = file;
      this.updateSelfieUI();
      this.askToKeep();
    }

    showSelfiePanel(user) {

      selfieContainer.innerHTML = '';
      this.initializeDeepAR();

      //var inputNode = document.getElementById('selfieUpload');
      //inputNode.addEventListener('change', this.playSelectedFile, false)

      selfieCancel.addEventListener('pointerup', function() {


        this.state = 'avatarRow';

        this.layout();

      });

    }

    hideSelfiePanel() {

    };


    getSelectedItem(el) {
      var s = '';
      [...el.children].forEach((item, i) => {
        if(item.classList.contains('itemSelected'))s=item.id;
      });
      return s;
    }



    save() {

      let user = this.user;
      if(!user){
        alert('missing user');
        return;
      }
      var drinks = kv.publicItems.filter(item => item.type == 'drink');
      var kilowear = kv.publicItems.filter(item => item.type == 'kilowear');
      var avatars = kv.publicItems.filter(item => item.type == 'avatar');

      var selectedDrinkId = this.getSelectedItem(drinkPicker);
      var selectedKilowearId = this.getSelectedItem(kilowearPicker);
      var dr = drinks.filter(d => d.id == selectedDrinkId)[0];
      var kw = kilowear.filter(d => d.id == selectedKilowearId)[0];

      if(!kw)kw = kilowear[0];
      if(!dr)dr = drinks[0];

      var voice = this.voices[voicePicker.value] || this.voices[0];

      if(!voice)voice = this.voices[0];


      //Math.pow(2, -1 + userSpeed.value/100.0 * 2.0)
      var params = {
        isBot:user.isBot,
        join:true,
        name:userName.value ,
        twitter:userTwitter.value, instagram:userInstagram.value,
        drink:dr || {},
        kilowear:kw || {},
        languageName:voice.name,
        languageGender:voice.ssmlGender, languageCode:voice.languageCodes[0],
        textId:'asdfadsf',
        text:userText.value,
        pitch: -20+(userPitch.value/100.0 * 40.0),
        speakingRate:1.0
      };

      //params.address = slug;
      if(user && user.id)params.id = user.id;

      console.log('saving id ' + params.id);

      if(kv.newAvatarUpload){
        params.avatar = kv.newAvatarUpload;
      }

      if(!userName.value){
        alert('please enter a display name');
        return false;
      }

      if(kv.kilo?.id)params.xid = kv.kilo.id;
      kv.updateUser(params);
      kv.newAvatarUpload = null;

      return true;

    }

    voiceTest() {

      var voice = this.voices[voicePicker.value] || this.voices[0];

      var u  = {};

      u.pitch = -20+(userPitch.value/100.0 * 40.0);
      u.languageName = voice.name;
      u.languageGender = voice.ssmlGender;
      u.languageCode = voice.languageCodes[0];
      u.txt = 'Check Check. 1. 2. 3.';

      kv.api('quickspeak','POST', u, function(r) {
        //debugger;
        kv.playAudio(r.path);
      });


    }

    recordSelfie() {


      var types = ["video/webm",
      "audio/webm",
      "video/webm\;codecs=vp8",
      "video/webm\;codecs=daala",
      "video/webm\;codecs=h264",
      "audio/webm\;codecs=opus",
      "video/mpeg"];

      for (var i in types) {
        console.log( "Is " + types[i] + " supported? " + (MediaRecorder.isTypeSupported(types[i]) ? "Maybe!" : "Nope :("));
      }


      console.log('milk and pull recordselfie called');

      var canvas = this.deepARCanvas;
      var ctx = canvas.getContext("2d");

      var video = document.querySelector("video");

      var videoStream = canvas.captureStream(30);

      var chromeoptions =  {mimeType: 'video/webm; codecs=vp9'};

      if(!MediaRecorder.isTypeSupported(chromeoptions.mimeType)){
        console.log('no vp9 support... ');
        chromeoptions = {};
      }


      this.mediaRecorder = new MediaRecorder(videoStream, chromeoptions);

      //alert(mediaRecordera.mimeType);


      var chunks = [];

      this.mediaRecorder.ondataavailable = function(e) {
        chunks.push(e.data);
      };

      this.mediaRecorder.onstop = function(e) {
        console.log('milk and pull onstop called');
        var blob = new Blob(chunks);//, { 'type' : 'video/webm' }
        chunks = [];
        blob.lastModifiedDate = new Date();
        blob.name = 'videoselfie.data';
        var videoURL = URL.createObjectURL(blob);

        //video.src = videoURL;
        this.handleSelfie(blob);
      }.bind(this);

      var vid = document.getElementById('videotexture');
      vid.play();


      this.mediaRecorder.start();
      var l = 10000;
      if(this.selfieLength){
        l = this.selfieLength*1000;
      }
      setTimeout(function (){
        this.mediaRecorder.stop();

      }.bind(this),  l);

    }


    // create canvas on which DeepAR will render

    initializeDeepAR() {

      var deepARCanvas = document.createElement('canvas');

      selfieContainer.appendChild(deepARCanvas);

      // Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
      // canvas.captureStream causes an error if getContext not called before. Chrome does not need the line below.
      var canvasContext = deepARCanvas.getContext('webgl');
      var mediaStream = deepARCanvas.captureStream(25);
      var videoTracks = mediaStream.getVideoTracks();
      this.deepARCanvas = deepARCanvas;
      this.startDeepAR(deepARCanvas);

    }


    // Handling all of our errors here by alerting them
    handleError(error) {
      console.log('handle error', error);
      if (error) {
        alert(error.message);
      }
    }

    startDeepAR(canvas) {

      let seg = require('./segmentation.zip');
      var deepAR = DeepAR({
        canvasWidth: 500,
        canvasHeight: 250,
        licenseKey: '62fbc3ce267f2f2a7a8ad6d81f6c341609d9eb104ed01b66c70e2b10680fcf32eb74565cd836d16e',
        libPath: 'deepar',
        segmentationInfoZip: seg,
        canvas: canvas,
        numberOfFaces: 1,
        onInitialize: function() {


          deepAR.startVideo(true);
          deepAR.setCanvasSize(250.0/1.777, 250.0);

          let fx = require('/selfieblue50.data');






          deepAR.switchEffect(0, 'slot', fx, function() {
            //record();
          });
        }
      });


      let mod = require('./models-68-extreme.bin');

      deepAR.downloadFaceTrackingModel(mod);

      this.deepAR = deepAR;
      // Because we have to use a canvas to render to and then stream to the
      // Vonage publisher, changing tabs has to pause the video streaming otherwise it will cause a crash
      // by pausing the 'window.requestAnimationFrame', more can be seen in the documentation:
      // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
      var visible = true;
      document.addEventListener("visibilitychange", function (event) {
        visible = !visible;
        // pause and resume are not required, but it will pause the calls to 'window.requestAnimationFrame'
        // and the entire rendering loop, which should improve general performance and battery life
        if (!visible) {
          deepAR.pause()
          deepAR.stopVideo();
        } else {
          deepAR.resume();
          deepAR.startVideo(true)
        }
      })
    }

    attachFile = function(url) {
      this.deepAR.stopVideo();
      var vid = document.getElementById('videotexture');
      vid.autoplay = true;
      vid.preload = 'metadata';


      vid.onloadedmetadata = function() {
        alert(vid.duration);
        this.selfieLength = vid.duration;
      }


      vid.src = url;

      //vid.src = require('/kilobotarawsquare.mp4');
      this.deepAR.setVideoElement(vid, true);

      vid.play();

    }



  }

  export default UserEdit;
