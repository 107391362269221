

class Message {

  constructor() {
    this.events = [];
  }

  on(name, callback) {
    if (!this.events[name]) this.events[name] = [];
    this.events[name].push(callback);
    //return message;
  }

  off(name, callback) {
    const listeners = this.events[name];
    if (listeners) {
      for (let i = 0; i < listeners.length; i++) {
        if (listeners[i] === callback) {
          listeners.splice(i, 1);
          break;
        }
      }
    }
  }

  trigger(name, data) {
    const listeners = this.events[name];
    if (listeners) {
      for (let i = 0; i < listeners.length; i++) {
        if (listeners[i]) listeners[i](data);
      }
    }
  }

}


const INSTANCE = new Message();
export default INSTANCE


 /*
message.on('app:begin', () => {
  console.log('begin...');
});

message.on('app:end', () => {
  console.log('end...');
});

message.trigger('app:begin');
message.trigger('app:end');
*/
