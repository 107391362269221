
/*
components boot up with an item. 
- call the server to write, dream, save... 
= with a message and a mode for when it is finished...
- change the mode 
*/

import { NoToneMapping } from 'three';
import {dom} from './Quickdom'
export const { div, el, button, img, textarea, input, label} = dom;

class KiloComponent {

  constructor(data) {

    if(!data)alert('missig data');

    this.modes = {
      loading:'loading',
      meta:'meta',
      edit:'edit',
      generate:'generate',
      view:'view'
    }

    this.frames = kv.publicItems.filter((i)=>{return i.type == 'frame'});
    this.elements = {};
    this.data = data;
    this.id = parseInt(Math.random() * 999999);
    this.createView();
    this.bindEvents();
    if(!this.data.mode)this.data.mode = this.modes.view;
    this.layout();
 
  }

  setMode(mode,message) {
    this.data.message = message;
    this.data.mode = mode;
    console.log('!!!! - calling kilosync because mode is being set to ' + mode);
    this.syncItem();
    this.layout();
  }

  bindEvents() {

    el['framecontainer'].addEventListener('click', (e)=>{
      this.assignFrame(e.target.src);
    });

    el['saleswitch'].addEventListener('change', this.updatePrice);
    this.view.addEventListener('click', e => {
      const el = e.target;
      if (el.classList.contains('btn')) {
        let command = [...el.classList].at(-1);
        this[command]();
      }
    });
  }

  createView() {
    const gifUrl = require('./mountainloadersquare.gif');
    this.view = dom.div(
      div(
        dom.img('.gifloader', {src:gifUrl, style: 'background:black;padding:5px;width:100px;border-radius:50%;'}),
        dom.div('Loading...', '.loadingmessage', {style: 'width:100%;padding:50px;text-align:center;color:white;'}),
        '.loader',
        {style: 'text-align:center;width:100%;position:absolute;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);'}
      ),
      div('', '.who', {style: 'background-color:red;color:white;font-size:12px;width:100%;font-style:italic;text-align:center;'}),
      div(
        '.w-100.promptmessage'
      ),
      div(
        img('.bigimage .image', {style: 'height:100%; position:absolute;'}),
        img('.frame', {style: 'height:100%; position:absolute; border: 0px solid red;'}),
        div('.sketchpad',  {style:'position:absolute;top:0px;width:100%;height:100%;'}),
      '.imagecontainer', {style:'position:relative;margin:auto;height:calc(100% - 150px);'}
      ),
      div(
        button('Generate Again','.btn.ms-2.w-100.round.btn-success.generate'),
        button('Edit/Retouch','.btn.ms-2.w-100.round.btn-success.edit'),
        button('save','.btn.ms-2.round.w-100.btn-success.proceed'),
      '.buttonrow.normalrow'
      ),
      div(
        '.framecontainer', {style:'width:100%;height:55px;margin-top:5px;'}
      ),
      button('Delete', '.btn.square.delete', {style:'background-color:red;'}),
      div(
        div('Title', '.small' , {style:'font-size:15px'}),
        textarea(this.data.name || this.data.prompt, '.form-control.nameprompt', {placeholder:'', value:'', style:'height:40px;width:100%'}),
        div(
          input('.form-check-input.exhibitswitch', {type:'checkbox', role:'switch', id:'exhibitswitch'}),
          label('Show in current exhibit','.exhibitlabel.form-check-label', {for:'exhibitswitch'}),
        '.mt-2.form-check.form-switch.form-switch-lg.form-check-reverse'),
        div(
          label('List for sale','.form-check-label', {for:'saleswitch'}),
          input('.form-check-input.saleswitch', {type:'checkbox', role:'switch', id:'saleswitch'}),
        '.d-none.mt-2.form-check.form-switch.form-switch-lg.form-check-reverse'),
          div(
            input('.price.form-control', {type:'text', style:'width:100px;float:right',id:'price'}),
            label('Price','.form-input-label', {for:'price', style:'float:right;margin-right:5px;margin-top:3px;'}),
            '.priceholder'
          ),
        button('Save', '.mt-3.btn.round.btn-success.w-100.saveItem', {style:'margin-top:11px;'}),
      '.metasection.col.name'
      ),
      div(
        div(this.data.name || 'untitled','.viewtitle'),
        //div('price','.viewprice'),
        div(this.data.xid || 'no current exhibit','.viewgallery'),
        '.viewrow',{style:'text-align:center;'}
      ),

      '.component', {id:this.id, style:'height:100%;position:relative;'}
    );

    this.elements.loading = [el['loader']];
    this.elements.view = [el['image'], el['frame'], el['viewrow']];
    this.elements.meta = [el['image'], el['metasection'],el['frame'], el['framecontainer'],el['delete']];
    this.elements.generate = [el['promptmessage'], el['normalrow'], el['image']];
    this.elements.edit = [el['editor'],el['promptmessage']];

  }

  show(el, show){
    if(show){
      el.classList.remove('d-none');
    }else{
      el.classList.add('d-none');
    }
  }


  layout() {
    //console.log(`MIAMI - layout is - showing elements  ${this.data.mode}`);
    const whattoshow = this.data.mode;
    if(!this.data.mode)debugger;
    for(const el of Object.values(this.elements).flat()) {

      if(!this.elements[whattoshow])debugger;
        this.show(el, this.elements[whattoshow].includes(el));
    };

   // el['loadingmessage'].innerHTML = this.loadingMessage;
    el['exhibitswitch'].checked = !this.data.xid || (this.data.xid && this.data.xid == kv.kilo.id);
    kv.showHide(el['priceholder'], this.data.price > 0.0);
    kv.showHide(bottomModalRightButton, this.data.mode == 'meta');

    const fc = el['framecontainer'];
    fc.innerHTML = '';
    for(const f of this.frames){
      const img = document.createElement('img');
      img.src = f.url;
      img.style = 'float:left;height:50px;width:50px;margin-right:10px;';
      fc.appendChild(img);
    }

    let txt = ``;

    if(this.isInExhibit() || !this.data.xid){
      txt = `Show in current exhibit ${kv.kilo.name}`;
    }else if(this.data.xname){
      txt = `Remove from ${this.data.xname} exhibit and show in ${kv.kilo.name}`;
    }else{
      `Show in current exhibit`;
    }

    el['exhibitlabel'].innerHTML = txt;

    if(this.data.edit && this.data.edit.url) {
      const url = this.data.edit.url;
      el['image'].src = url;
    } else if(this.data.url){
      el['image'].src = this.data.url;
    }

    el['promptmessage'].innerHTML = this.data.prompt;

    const furl = `https://api.kiloverse.io/frames/antique.png`;
    el['frame'].src = this.data.frameUrl;
    el['frame'].style.display = this.data.frameUrl ? 'block' : 'none';
    

    el['image'].style.padding = (this.data.mode == 'meta' || this.data.mode == 'view') ? '10%' : '0%';
    //el['imagecontainer'].style.width = (this.data.mode == 'meta' || this.data.mode == 'view') ? '75%' : '100%';

    const who = el['who'];
    who.innerHTML = '';
    if(this.data.edit && this.data.edit?.userId != kv.user.id){
      who.innerHTML = this.data.edit.userName + ' is editing...';
    }
    dom.el['loadingmessage'].innerHTML = this.data.edit?.message;
  }


  async syncItem() {
    if(!this.data.id){
      alert('no id for image cant sync...');
      return;
    }

    this.data.xid = kv.kilo.id;
    this.data.edit = {userId:kv.user.id, userName:kv.user.name, time:Date.now()}
    console.log('setting edit flag and syncing');
    let r = await kv.api2('saveitem', 'POST', this.data);
    Object.assign(this.data, r);
    this.layout();
  }

  edit() {
    this.setMode(this.modes.edit, 'edit');
  }

  async proceed() {
   this.setMode(this.modes.meta, 'proceeding');
  }

  async saveItem() {

    this.data.name = el['nameprompt'].value;
    this.data.price = el['price'].value;
    this.data.creatorId = kv.user.id;

    if(el['exhibitswitch'].checked) {
      this.data.xid = kv.kilo.id;
    } else {
      delete this.data.xid;
    }

    if(el['saleswitch'].checked){
      this.data.price = el['price'].value
    } else {
      this.data.price = 0;
    }

    let now = Date.now();

    this.data.saved = true;
    this.data.componentId = this.id;

    //this is really important - 
    // setmode always syncs carousel  with an editor state 
    // so this removes it the edit so carousel doesnt pop it up for other useres...
    delete this.data.edit;

    this.data.edit = {userId:kv.user.id, userName:kv.user.name, time:Date.now(), message: kv.user.username + ' is saving'};
    this.data.mode = 'view';

    const r = await kv.api2('saveitem', 'POST', this.data);

    
      const diff = Date.now() - now;
        setTimeout(()=>{


           kv.dismissViewController();
           

        },  Math.max(2000 - diff,0));
  }

  isInExhibit() {
    const m = (item)=>item.id == this.data.id;
    return kv.kilo.items.some(m);
  }

 
  async dispose() {

      //surrender editing capability if you are current editor...
      if(this.data.edit.userId == kv.user.id){
        this.data.edit = 'delete';
        await kv.api2('saveItem', 'POST', this.data);
      }
  }

}

export default KiloComponent;