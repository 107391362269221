class CommandNames {

  constructor(sweet) {
    this.ctx = sweet.ctx;
    this.render = this.create = this.write = this.make = this.play = this.add;
    //this.change = this.switch = this.replace;
    //this.stop = this.remove = this.delete;
  }

  add(args) {
    this.ctx.command = 'add';
  }

  delete(args) {
    this.ctx.command = 'delete';
  }

  edit(args) {
    this.ctx.command = 'edit';
  }

  replace(args) {
    this.ctx.command = 'replace';
  }

  endProg(args) {

  }

}

class Things {

  constructor(sweet) {
    this.ctx = sweet.ctx;
    this.screenplay = this.poem = this.writing = this.write = this.essay = this.letter = this.manuscript = this.text;
    this.sticker = this.giphy = this.gif;
    this.backdrop = this.background;
  }

  text(args) {
    this.ctx.type = 'text';
    //this.ctx.prompt = [...args].splice(0, args.length).join(' ');
    this.ctx.prompt = [...args].splice(1, args.length).join(' ');

  }

  background(arg, args, command) {

    this.ctx.type = 'background';


    const phrase =  args.splice(0, args.length).join(' ');


    if(phrase.includes('clouds')){
      //this.ctx.colors = [
      //{loc:0, color:'#1e4877'},
      //{loc:.5, color:'#4584b4'}];
      //this.ctx.strength = 1.0;
    }

    if(phrase.includes('stars')){
      //this.ctx.backgroundPromptUrl = 'https://api.kiloverse.io/purplesky512.png';
      //this.ctx.colors = [
      //{loc:0, color:'#000'},
      //{loc:.5, color:'#000066'}];
    //  this.ctx.strength = 1.0;
    }


    this.ctx.displayPrompt = phrase;
    this.ctx.prompt =   phrase.replace(arg, '');
    //.replace('clouds', 'blue sky').replace('stars', 'night sky');
    this.ctx.prompt += '';
    //[...args].splice(0, args.length).join(' ');
  }

  gif(arg, args, command){

    //the gifs prompt is everything after the word gif
    this.ctx.type = 'gif';
    this.ctx.prompt = args.splice(args.indexOf(arg)+1, args.length).join(' ');


  }

/*
 no colors for now...
  lookupColor(color) {
    var colorDiv = document.createElement('div');
    colorDiv.style.background = ''
    colorDiv.style.background = color;
    return !!colorDiv.style.background.length;

  }
*/
  emoji(arg, args, command){
    this.ctx.type = 'emoji';
    this.ctx.prompt = [...args].splice(0, args.length).join(' ');
    const results =  kv.emojis.filter((e)=>{
      if(e.tags.includes(this.ctx.prompt))return true;
      if(e.description.split(' ').includes(this.ctx.prompt))return true;
      return false;
    });
    if(results.length > 0)this.ctx.emoji = [Math.floor(Math.random()*results.length)];
  }

 /*images are the defaykt */
  default(args) {
    this.ctx.type = 'aiimage';
    this.ctx.prompt = [...args].splice(1, args.length).join(' ');
  }

}

class QuickParse {

  constructor(prompt) {
    this.init();
  }

  init() {
    this.ctx = {};
    this.commandNames = new CommandNames(this);
    this.things = new Things(this);
  }

  process(prompt) {
      this.prompt = prompt;

      return this.parse(prompt);
  }

  parse(txt) {

    txt = txt.toLowerCase().trim();
    let dirtywords = txt.split(' ');
    let word = dirtywords[0];
    let words = [];
    for(const w of dirtywords){
      let nw = w.trim();
      nw = nw.replace('\n');
      if(nw.length > 0)words.push(nw);
    }




    if (this.commandNames[word]) {
      const send = [].concat(words);//not sending the commands for now
      //console.log('run', currentCommand, 'with', send);
      //send is just the greedy args for now...
      this.commandNames[word](send);//.bind(parent);


      this.things.default(words, word);

      for(const arg of words){
        if(this.things[arg]){
          this.things[arg](words, word);
        }
      }

      return true;
    }
    return false;

  }

  parseold(txt) {

    txt = txt.toLowerCase().replace(/\s+/g, ' ');// + ' endProg ';

    let word = ''
    let currentCommand;
    let currentArgs = [];
    let lastWord = ''
    let lastSingleLetter;
    let greedyArgs = []
    let isFirstword = true;
    let wordcount = 0;
    for (let i = 0; i < txt.length; i++) {
      let char = txt[i]
      if (/\s/.test(char)) {


        word = word.trim()
        let captured = false;
        console.log('checking word ' + word);


        if (this.commandNames[word]) {

          wordcount++
          currentCommand = word


          if (currentCommand) {

            const send = [].concat(greedyArgs);//not sending the commands for now
            //console.log('run', currentCommand, 'with', send);
            //send is just the greedy args for now...
            if(wordcount==1) this.commandNames[currentCommand](send);//.bind(parent);
            this.things.default(greedyArgs, currentCommand);

            for(const arg of [...greedyArgs, currentCommand]){
              if(this.things[arg]){
                this.things[arg](arg, greedyArgs, currentCommand);
              }
            }
          }


          currentArgs = []





        } else if(word.length > 0){
          greedyArgs.push(word);

        }

        lastWord = word;

        wordcount++;

        word = ''

      } else {
        word += char
      }
    }
  }

}

export default QuickParse
