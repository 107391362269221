import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import canvasTxt from 'canvas-txt'
import { parseGIF, decompressFrames } from 'gifuct-js'
import QuickFrame from './QuickFrame';
import Message from  './Observer';

class ArtField extends THREE.Object3D {

  constructor() {

    super();
    this.isGroup = true;
    this.radius = 2500;
    this.delay = ms => new Promise(res => setTimeout(res, ms));
    this.toolbar = kv.toolbar;

  }

  shrink() {

  kv.showHide(kv.toolbar, false);
    let slot = this.enlargedSlot;
    if(!slot)return;
    const t = slot.userData.t;

    let xd =  this.xrad * Math.sin(t * Math.PI * 2);
    let yd =  this.yrad * Math.cos(t * Math.PI * 2);
    let zd = 0;

    let start = {x:slot.position.x, y:slot.position.y, z:slot.position.z};
    let end = {x:xd, y:yd, z:zd};

    this.animate(slot, start, end, 10, .3, ()=>{
        slot.userData.front = false;
    });

    slot.userData.placard.visible = false;
    kv.showHide(kv.toolbar, false);

  }

  probe() {


    const z = this.enlargedSlot.position.clone();

    //console.log(v + ' slot is at ' + this.localToWorld(z).z);

  }



  probetest() {

    let s = '['
    for(let i=0; i <= 20; i++){

      const v =  -1 + (i/20)*2.0;
      //console.log('setting z to ' + v);

      const t = new THREE.Vector3(0,0,v);
      const r = t.unproject(kv.camera);

      s += `{"x":"${v}","y":${r.z}},`;


    }
    s += ']';

    //console.log(s);

  }

  updatePlacard(data) {

    this.toolbar.innerHTML = '';

    const title =  data.name || data.prompt;

    const b =  `<button class="btn round btn-circle deleteitem"><i class="fa-solid fa-trash"></i></button>
    <button style="margin-left:5px;" class="btn round  btn-circle edititem"><i class="fa-solid fa-pencil"></i></button>
  <div style="flex-grow: 1"></div>;
    <div style="margin-left: 10px;padding:8px;float:right;text-transform:uppercase;
    min-width:100px;background:white;color:black;
    font-family:verdana;font-size:9px;">
      ${title}
    </div>`;


    this.toolbar.innerHTML = b;


    //this.toolbar.appendChild(placard);
  }


  enlarge(slot) {

    this.enlargedSlot = slot;
  //  slot.userData.placard.visible = true;

    kv.showHide(kv.toolbar, true);
    kv.toolbar.dataset.id = slot.userData.data.id;
    this.updatePlacard(slot.userData.data);
    const landscape  = (this.width > this.height);
    let per =  landscape ? .5 : .6;

    this.clock = new THREE.Clock();

    for(const s of this.children) {
      s.userData.front = (s == slot)
    }

    //const times = [];

    const carousel = kv.x.art;

    let wz = - (slot.userData.frame.scale.x) / per * .5;
    const xp = landscape ? -.44 : 0;
    const yp = landscape ? .1 : .25;
    let wx =  this.visibleWidth(wz) * xp * .5;
    let wy = this.visibleHeight(wz) * yp * .5;

    let loc = new THREE.Vector3(wx, wy, wz);

    const l2 = carousel.worldToLocal(loc);

    const pworld =  new THREE.Vector3(loc.x + 55, loc.y -55, loc.z);
    const pp = slot.worldToLocal(pworld);
    slot.userData.placard.position.set(pp.x, pp.y, pp.z);

  //  this.toolbar = kv.toolbar;
    this.toolbar.style.left = (.5 + xp * .5 - per * .5) * container.clientWidth + 'px';
    this.toolbar.style.top = (.5 - yp * .5) * container.clientHeight + (per * .5 * container.clientWidth) + 'px';
    this.toolbar.style.width = container.clientWidth * per + 'px';

    let start = {x:slot.position.x, y:slot.position.y, z:slot.position.z};
    let end = {x:l2.x, y:l2.y, z:l2.z};

    this.animate(slot, start, end, 10,  .3, (cb)=>{



    });

  }

  animate(slot, start, end, frames, duration, cb) {

    let values =[];
    let times = [];

    for(let i=0; i <= frames; i++) {
      const x = (i/frames);
      const y = (1.0 - Math.pow(1.0 - x, 3));
      values.push(start.x + (end.x-start.x) * y);
      values.push(start.y + (end.y-start.y) * y);
      values.push(start.z + (end.z-start.z) * y);
      times.push(x * duration);
    }

    const position = new THREE.VectorKeyframeTrack(".position", times, values);

    const tracks = [position];

    const length = -1;
    const clip = new THREE.AnimationClip("slowmove", length, tracks);
    const mixer = new THREE.AnimationMixer(slot);

    const action = mixer.clipAction(clip);
    action.clampWhenFinished = true;
    action.setLoop(THREE.LoopOnce);
    action.play()

    this.mixer = mixer;


    this.mixer.addEventListener('finished', function (e){
             if(cb)cb();
    });

  }


  makeNeonFrame(data) {
    this.coolFrame = new QuickFrame(data);
  }

  destroy() {

  }

  render() {


			if ( this.mixer && this.clock ) {
        const delta = this.clock.getDelta();
				this.mixer.update( delta );
			}

      const slots = this.children;

      for(const s of slots) {


        if(isNaN(kv.pan)){
          debugger;
        }



        s.userData.t += (.00005 - kv.pan * .0001);

        if(isNaN(s.userData.t)){
          //debugger;
        }


        if(s.userData.front)continue;


        const t = s.userData.t;
        s.position.x =  this.xrad * Math.sin(t * Math.PI * 2);
        s.position.y =  this.yrad * Math.cos(t * Math.PI * 2);

        let nextSlotIdx = (s.userData.idx+1)%slots.length;
        let prevSlotIdx = ((s.userData.idx - 1) < 0) ? (slots.length-1) : (s.userData.idx-1);
        const prevSlot = slots[prevSlotIdx];
        const nextSlot = slots[nextSlotIdx];


        const leftEdge = -270;//this.width * .5;
        const rightEdge = 270;//this.width * .5;

        if(s.position.x > leftEdge &&  s.userData.lastXPosition < leftEdge) {
          let newIdx = s.userData.imageIdx - 1;
          if(newIdx < 0)newIdx = (this.images.length -1);
          s.userData.imageIdx = newIdx;
          this.loadSlot(s, this.images[newIdx]);

          if(!nextSlot.userData.data){
            let newerIdx = newIdx - 1;
            if(newerIdx < 0)newerIdx = (this.images.length -1);
            this.loadSlot(nextSlot, this.images[newerIdx]);
          }

        }

        s.userData.lastXPosition = s.position.x;


      }

  }



  loadGif = function(gifUrl, cb) {


    const GIFLoader = (function( url, complete ) {

      const fileLoader = new THREE.FileLoader;

        fileLoader.responseType = 'arraybuffer';
        fileLoader.load( url, async function( data ) {
    		const gif = parseGIF(data);

          if(!gif){
            debugger;
          }

          var frames = decompressFrames(gif, true)
            const container = {
                downscale: false,	// Canvas needs to be power of 2, by default size is upscaled (false)
                width: gif.lsd.width,
                height: gif.lsd.height,
                frames: frames
            };
            complete( container );

        });

    });


    GIFLoader(gifUrl, async function( container ) {


  	      const ratio = container.width / container.height;
  	      const texture = new THREE.ComposedTexture;
  	      await texture.assign(container);

  	      const spritesheet = await texture.toSheet();
  	      const spriteTexture = new THREE.SpriteTexture( spritesheet );

          if(cb)cb(spriteTexture);

    });

  }

  makePlacardTexture(data) {

    if(!this.c)this.c = document.createElement('canvas');
    const c = this.c;
    c.width = 512;
    c.height = 256;
    const ctx = c.getContext('2d')
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, c.width, c.height);
    ctx.fillStyle = '#000000' //red color text
    canvasTxt.font = 'Helvetica';
    canvasTxt.color = 'black'
    canvasTxt.fontSize = 100;
    canvasTxt.vAlign = 'top'
    canvasTxt.align = 'center'
    canvasTxt.drawText(ctx, 'Ed', c.width*.10, c.width*.10, c.width - c.width*.20, c.height - c.width*.2)

    const url = c.toDataURL('image/png');

    return new THREE.TextureLoader().load(url, ()=>{
      //console.log(url);
    });
    //new THREE.CanvasTexture(c);

  }

  emitterPath(p) {

    const x  = this.xpath.xmin +  (this.xpath.xmax - this.xpath.xmin) * p;
    const y  = this.xpath.ymin +  (this.xpath.ymax - this.xpath.ymin) * p;
    const z  = this.xpath.zmin +  (this.xpath.zmax - this.xpath.zmin) * p;

  }


  visibleHeight(z) {

    // compensate for cameras not positioned at z=0
   const cameraOffset = kv.camera.position.z;
    if ( this.z < cameraOffset ) this.z -= cameraOffset;
    else this.z += cameraOffset;
    const vFOV = kv.camera.fov * Math.PI / 180;
    return 2 * Math.tan( vFOV / 2 ) * Math.abs( z );
  }

  visibleWidth(z) {
    const height = this.visibleHeight(z);
    return height * kv.camera.aspect;
    //debugger;
  }

  loadSlot(slot, data) {

    if(!slot || !slot.userData || !data){
      debugger;
    }

    let frames =  [
      'https://api.kiloverse.io/frames/poster.png',
      'https://api.kiloverse.io/frames/antique.png',
      'https://api.kiloverse.io/frames/thin.png'];

    let frameUrl = data.frameUrl || frames[Math.floor(frames.length * Math.random())];
    //console.log('loading slot...');

    slot.visible = true;
    slot.userData.data = data;

    //pass data to children for raycaster
    //slot.children[0].userData.data = slot.children[1].userData.data = data;

    if(data.url && !this.textures[data.url]){
      this.textures[data.url] =  new THREE.TextureLoader().load(data.url, ()=>{});
    }


    slot.userData.art.material.map = this.textures[data.url];
    slot.userData.art.material.needsUpdate = true;

    slot.userData.padding = 5;
    //if(data.padding){

      const sx = 100 * (1.0 - slot.userData.padding / 99);
      const sy = 100 * (1.0 - slot.userData.padding / 99);

      slot.userData.art.scale.set(sx,sy);
    //}
    //if(this.testFrameUrl)data.frameUrl = this.testFrameUrl;


    if(!frameUrl){

      slot.userData.frame.position.z = 0;

    } else {

      slot.userData.frame.position.z = -2;



      if(!this.textures[frameUrl]){

        const t = new THREE.TextureLoader().load(frameUrl, ()=>{});
        slot.userData.frame.material.map = t;
        slot.userData.frame.needsUpdate = true;
        this.textures[frameUrl] = t;

      } else {

        if(this.textures[frameUrl] != 'loading'){
          slot.userData.frame.material.map = this.textures[frameUrl];
          slot.userData.frame.material.needsUpdate = true;
        }

      }
    }

  }

  tilt(angle) {
    for(const g of this.children) {
      const c = g.userData.base;
      c.scale.z = angle;
    }
  }

  spot(x,y,z) {
    for(const g of this.children) {
      const c = g.userData.backlight;
      c.position.set(x,y,z);
    }
  }

  buildCarousel() {

    this.remove(...this.children);
    const slotCount = 20;

    const bumpTime = Math.random()*5000;
    for(var i = 0; i < slotCount; i++){

      var ip = new THREE.Group();
      this.add(ip);

      ip.userData.isFun = false;
      ip.userData.idx = i;
      ip.userData.t = i/slotCount + bumpTime;
      ip.visible = false;


      //const geometry = new THREE.BoxGeometry( 120, 40, 5 );
/*
      const geometry = new THREE.CylinderGeometry( 60, 60, 5, 100 );

      const material = new THREE.MeshLambertMaterial({ color: 'aqua',transparent: true, opacity: 1.0});
      const base = new THREE.Mesh( geometry, material );
      ip.userData.base = base;
      base.position.y = 20;
      base.position.z = 50;
      base.rotation.x = 1.2;
      base.scale.z = .5;

      ip.add( base );


      if(!this.glow)this.glow =  new THREE.TextureLoader().load('https://api.kiloverse.io/assets/tubeglow.png');

      const backlight = new THREE.Sprite();
      ip.userData.backlight = backlight;
      backlight.userData.isBacklight = true;
      backlight.geometry = new THREE.PlaneGeometry( 1, 1);
      const bm = {map:this.glow, color:'aqua', transparent:true};
      backlight.material = new THREE.SpriteMaterial(bm);

      backlight.scale.set(this.oscale  * 2.0, this.oscale * 2.0, 1.0);
      backlight.position.set(...base.position);
      ip.add(backlight);
      */

    //  const spotlight = new THREE.SpotLight( 0xffffff );
    //  ip.add(spotlight);
    //  ip.userData.spotlight = spotlight;





      const frame = new THREE.Sprite();
      frame.userData.isFun = false;
      frame.geometry = new THREE.PlaneGeometry( 1, 1);

      const fo = {color:'white', transparent:true};

      const framematerial = new THREE.SpriteMaterial(fo);
      frame.material = framematerial;
      frame.rotation.set(0.0, 0.0, 0.0);
      frame.scale.set(this.oscale * 1.1, this.oscale * 1.1, 1.0);
      frame.position.set(0.0, 0.0, 0.0);

      if(!this.frames)this.frames = [];

      ip.add(frame);
      ip.userData.frame = frame;

      frame.visible = true;

      const art = new THREE.Sprite();
      art.userData.isFun = false;
      art.geometry = new THREE.PlaneGeometry( 1, 1);

      const o = {transparent:false, side:THREE.DoubleSide};
      const artmaterial = new THREE.SpriteMaterial(o);

      ip.userData.art = art;

      art.material = artmaterial;
      art.rotation.set(0.0, 0.0, 0.0);
      art.scale.set(this.oscale * .99, this.oscale * .99, .99);
      art.position.set(0.0, 0.0, -1.0);
      ip.add(art);


      const imageIdx =  i % this.images.length;
      ip.userData.imageIdx = imageIdx;
      //const imageData = this.images[imageIdx];
      //this.loadSlot(ip, imageData);



      const placard = new THREE.Sprite();
      placard.geometry = new THREE.PlaneGeometry( 1, 1);
      placard.visible = false;
      ip.userData.placard = placard;
      //placard.userData.data = data;
      placard.userData.isFun = false;
      //placard.geometry = new THREE.PlaneGeometry( 1, 1 );
      const po = {map:this.makePlacardTexture({}), transparent:false};
      const placardMaterial = new THREE.SpriteMaterial(po);
      placard.material = placardMaterial;

      placard.scale.set(25, 20, 1);
      placard.center.set(1,1);
      placard.position.set(0,1,0);

      ip.add(placard);



    }

  }


  layout(data, artchanged) {

    if(!data)alert('data missing');  

    this.oscale = 100;

    this.width = this.visibleWidth(500);
    this.height = this.visibleHeight(500);
    this.position.x = 0;// -this.width * .5;
    this.xrad = this.width * .9;
    this.yrad = 450;
    this.position.y = this.height * .33;
    this.position.z = -500;
    this.rotation.x = Math.atan(this.position.y/500) + Math.PI * .53;



    this.data = data;

    if(!this.textures)this.textures = {};

    var i = 0;

    this.items = this.data.items || [];
    var funs = this.items.filter(t=>t.type == 'emoji' || t.type == 'gif');
    var gifs = this.items.filter(t=>t.type == 'gif');
    var texts = this.items.filter(t=>t.type == 'text');
    var images = this.items.filter(t=>t.type == 'aiimage');

    this.images = [...images, ...texts];

    var funCount = funs.length;
    if(funCount < 300) {
      var repeatfun  = Math.ceil(300 / funs.length);
      funCount = funs.length * repeatfun;
    }


    if(this.images.length == 0)return;


    this.radius = this.visibleWidth(1000) * .5;


    if(this.children.length == 0)this.buildCarousel();

    //if(artchanged)this.buildCarousel();


    //console.log(`carousel position: ${this.position.x}/${this.position.y}/${this.position.z}`);
    //console.log(`carousel rotation: ${this.rotation.x}/${this.rotation.y}/${this.rotation.z}`);
    //console.log(`rad: ${this.xrad}/${this.yrad}`);

    if(this.enlargedSlot) {

    }



}

oldGifStuff() {

  funCount = 0;

  for(var i = 0; i < funCount; i++){

    const data = funs[i%funs.length];

    var ip = new THREE.Sprite();
    //funSprites.push(ip);
    ip.userData.data = data;

    ip.userData.isFun = true;
    ip.userData.random = Math.random();

    this.add(ip);

    var funtexture;

    const o = {transparent:false, rotation: -1.0 + 2.0 * Math.random()};

    if(this.textures[data.id])o.map = this.textures[data.id];
    const ukwmaterial = new THREE.SpriteMaterial(o);
    ukwmaterial.transparent = true;
    ip.material = ukwmaterial;

    const a = ((i+.5)/funCount) * 2.0 * Math.PI;

    ip.position.y = (Math.random()-.5)*innerHeight*.25;
    ip.position.x = (this.radius +  Math.random() * 500) * Math.sin(a);
    ip.position.z = (this.radius + Math.random() * 500) * Math.cos(a);

    var c = ((2.0 * Math.PI * this.radius)/funCount) * 1.0;// * .5;//fix this with trig?
    ip.scale.set(this.oscale,this.oscale,1);

  }


  for(const gif of gifs){

    if(!this.textures[gif.id]){
      this.textures[gif.id] = 'loading';
      if(gif.url)this.loadGif(gif.url, (t)=>{

        this.textures[gif.id] = t;
        this.children.forEach((ip, i) => {

          if(ip.userData.data && gif.id && ip.userData.data.id == gif.id){
            ip.material.map = t;
            ip.material.needsUpdate = true;
          }

        });
      });

    }
  }


}




}

export default ArtField
