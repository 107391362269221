import {spec, dom} from './Quickdom'

const { div } = dom

class Chatlog {

  constructor() {
    this.init();
  }

  init() {
    this.createView();
  }

  createView() {
    this.view = document.createElement('div');

  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  update(messages) {
    while (this.view.firstChild) {
      this.view.removeChild(this.view.firstChild);
    }

    for(const msg of messages){

      const date = new Date(msg.time*1000);
      const dateStr = this.formatAMPM(date);


      this.view.append(
        dom.div(
          dom.div(msg.name, {style:'font-size:13px; color:white;'}),
          dom.div(dateStr, {style:'font-size:11px; color:white;display:inline;position:absolute;right:0px;top:0px;'}),
          dom.div(msg.message || ' ', {class:'messageText', style:'font-size:13px;'})
          , {class:'message', style:'border-bottom: solid #ccc 1px;display:block;position:relative;'})
        )
      }

      this.scrollDown();
    }


    scrollDown() {

      if(this.view.parentNode)this.view.parentNode.scrollTop =  this.view.parentNode.scrollHeight;

    }


  }

  export default Chatlog;
