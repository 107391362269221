import Exhibit from './Exhibit'

class Feed {

 constructor() {
   this.sceneElements = [];
   this.view = document.createElement('div');
   this.view.id = 'feedView';
   document.body.appendChild(this.view);
   this.exhibits = [];
   this.data = [];
   this.view.innerHTML = '';
   this.divs = [];
   this.videoSky = false;
    this.init();
 }

 addScene(elem, fn) {
   const ctx = document.createElement('canvas').getContext('2d');
   elem.insertBefore(ctx.canvas, elem.firstChild);
   ctx.canvas.style = 'border:solid white px;border-radius:10px;'
   this.sceneElements.push({elem, ctx, fn});
 }

 init() {

  const canvas = document.createElement('canvas');
  this.renderer = new THREE.WebGLRenderer({canvas, alpha: true});
  this.renderer.setScissorTest(true);

  this.sceneInitFunctionsByName = {
    'exhibit': (elem, data)=> {
      const {scene, camera} = makeScene(elem);
      const x = new Exhibit(data, elem, camera);
      this.exhibits.push(x);
      scene.add(x);

      return (time, rect) => {
        x.render();
        //console.log('render ' + data.name);
        this.renderer.render(scene, camera);
      };
    }
  };

  function makeScene(elem) {

    const scene = new THREE.Scene();
    const fov = 45;

    const aspect = 1.0;//elem.width/elem.height;  // the canvas default
    const near = 0.1;
    const far = 10000;


    var horizontalFov = 90;
    const wtf = (Math.atan(Math.tan(((horizontalFov / 2) * Math.PI) / 180) / aspect) * 2 * 180) / Math.PI;
    const camera = new THREE.PerspectiveCamera( wtf, aspect, 1, 100000 );
    //const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(0, 0, 0);

    scene.add(camera);

    return {scene, camera};
  }


}



 load(cb) {

  this.exhibits = [];
  this.sceneElements = [];

  window.history.pushState('', 'Kiloverse ', '/');

  this.data = [];
  this.view.innerHTML = '<div style="color:white;" id="trending"></div>';
  this.divs = [];

  //load all the exhibits...
  kv.api('all', 'GET', {}, function(r){

    for(const xd of r) {
      console.log(`${xd.name} LIU: ${xd.lastItemUpdate}`);

      this.data.push(xd);
    };

    //put live stuff with humans at the top
    this.data = this.data.sort((a,b)=>{

      //let fresher = stuff.sort((a,b)=>{return (a > b)?-1:1})

        //&& b.lastItemUpdate && (a.lastItemUpdate > b.lastItemUpdate)) || (a.lastItemUpdate && !b);
      let notlive = (!a.users ||  a.users?.length == 0) && (!b.users || b.users?.length == 0);
      const ha = (a.users ?? []).filter(u=>!u.isBot).length;
      const ba = (a.users?? []).filter(u=>u.isBot).length;
      const hb = (b.users?? []).filter(u=>!u.isBot).length;
      const bb = (b.users?? []).filter(u=>u.isBot).length;

      console.log(`${a.name} liu: ${a.lastItemUpdate}`);


    if(notlive){

      console.log(`comparing ${a.lastItemUpdate || 0} and ${b.lastItemUpdate || 0}`);
      return ((a.lastItemUpdate || 0) > (b.lastItemUpdate || 0)) ? -1 : 1;
      //return a >= b ? -1 : 1

    }else if(((ha > 0) && (ha >= hb))){
        return -1
    }else if(((ba + ha) > 0) && ((ba + ha) >= (bb + ba))) {
        return -1
    } else {
        return 0
    }

    });


    for(let i =0; i < this.data.length; i++){

        const kilodata = this.data[i];
        //let exf = this.exhibits.filter((x)=>x.data.id == kilodata.id);

        const card = document.createElement('div');
        card.className = 'cardboard';
        card.style.backgroundImage = `url(https://api.kiloverse.io/skies/${kilodata.skyFileName}.png)`;

        console.log('here is a sky ' + card.style.backgroundImage);

        card.dataset.xid = kilodata.id;

        this.divs.push(card);

        let ustring = '';
        if(!kilodata.users)kilodata.users = [];

        if(kilodata.users.length == 1){
          ustring = kilodata.users[0].name + ' is here'
        }else if(kilodata.users.length == 2){
          ustring = kilodata.users[0].name + ' and '+  kilodata.users[1].name + ' are here' ;
        }else if(kilodata.users.length == 3){
          ustring = kilodata.users[0].name + ', ' + kilodata.users[1].name + ', and ' + kilodata.users[2].name + ' are here';
        }else if(kilodata.users.length > 3){
          ustring = kilodata.users[0].name + ', ' + kilodata.users[1].name + ', and ' + (kilodata.users.length-2) + ' others are here.';
        }


        let live = (kilodata.users.length > 0) ? ('<span style="border-radius:3px;padding:0px 5px;background-color:red;color:white;">⦁LIVE</span>  ' + ustring) : '';
        card.innerHTML = `
          <div style="text-align:left;width:100%;color:#eee;font-size:14px;font-weight:400;" class="feedName">${kilodata.name}</div>
          <div style="text-align:left;width:100%;color:#eee;font-size:12px;font-weight:100;" class="ownerName">exhibit by ${kilodata.ownerName || ''}</div>
          <div style="font-size:12px;width: 100%;text-align:left;" class="feedLive">${live}</div>
          <div style="display:none;float:left;background-color:green;color:white;border-radius:5px;">\$${Math.floor(Math.random()*1000)}</div>`

          if(kilodata.lastItemUpdate){
            let liu = kv.ago(new Date(kilodata.lastItemUpdate));
            card.innerHTML += `<div style="font-size:11px;text-align:left;">last update ${liu}</div>`;
          }
        this.view.appendChild(card);



      }

      kv.scroll = 0;
      window.scrollTo(0,0);
      this.position();

      this.renderer.clear();

      cb();


  }.bind(this));

}



position() {


  //this.view.style = `width:${400 * this.divs.length};height:225px;position:relative;margin-top:133px;`

  this.view.style = `width:100%;max-width:1200px;
  margin:auto;
  text-align:center;margin-top:80px;
  padding-bottom:111px;`

  //let z = -6000;
  let i = 0;

  let w = 200;

  if(innerWidth < 400){

    w = Math.floor((innerWidth - 80)/2.0);

  }
  const a = 1/(1.0);

  let h = Math.floor(w/a);

  //alert(w);


  for(const el of this.divs) {
    //el.style.position = 'absolute';
    //el.style.float = 'left';
    el.style.display = 'inline-block';
    el.style.textAlign = 'center';
    el.style.color = 'white';
    el.style.width = `${w}px`;
    el.style.backgroundColor = 'black';
    //el.style.height = '100%';
    el.style.height = `${h}px`;


    //el.style.border = 'solid #222 1px';
    el.style.borderRadius = '10px';
    //el.style.marginRight = '25px';
    //el.style.margin = '25px';
    //el.style.marginBottom = '66px';
    el.style.margin = '66px 14px';
    //el.style.overflow = 'hidden';
    el.style.position = 'relative';
    //el.style.float = 'left';
      //if(!el.style.left){
        //el.style.left = ((400 + 30)*i)+'px';
      //}

      i++;
  }


}



  layout() {


    for(const x of this.exhibits){
      x.layout(null, true);
      //if(x.art)x.art.position.z = 2000;
    }
  }

  makeX(card, kilodata) {
    const sceneInitFunction = this.sceneInitFunctionsByName['exhibit'];
    const sceneRenderFunction = sceneInitFunction(card, kilodata);
    this.addScene(card, sceneRenderFunction);
    this.layout();

  }

  render(time) {
    time *= 0.001;

    for(const elem of this.divs){

      const rect = elem.getBoundingClientRect();
      const {left, right, top, bottom, width, height} = rect;
      const isOffscreen =  bottom < 0 ||top > window.innerHeight ||  right < 0 ||left > window.innerWidth;

      if (!isOffscreen) {
        //console.log('onscreen');
        const xid = elem.dataset.xid
        if(!this.exhibits.find((x)=>{return x.data.id == xid})){
          const data = this.data.find((data)=>data.id == xid);
          this.makeX(elem, data)
        }
      }
    }


    for (const {elem, fn, ctx} of this.sceneElements) {
      // get the viewport relative position opf this element
      const rect = elem.getBoundingClientRect();
      const {left, right, top, bottom, width, height} = rect;
      const rendererCanvas = this.renderer.domElement;

      const isOffscreen =
          bottom < 0 ||
          top > window.innerHeight ||
          right < 0 ||
          left > window.innerWidth;

      if (!isOffscreen) {



        // make sure the renderer's canvas is big enough
        if (rendererCanvas.width < width || rendererCanvas.height < height) {

          this.renderer.setSize(width, height, false);
        }

        // make sure the canvas for this area is the same size as the area
        if (ctx.canvas.width !== width || ctx.canvas.height !== height) {
          ctx.canvas.width = width;
          ctx.canvas.height = height;
        }

        this.renderer.setScissor(0, 0, width, height);
        this.renderer.setViewport(0, 0, width, height);

        fn(time, rect);

        //console.log(`${width}x${height}x${rendererCanvas.width}x${rendererCanvas.height}`);


        // copy the rendered scene to this element's canvas
        ctx.globalCompositeOperation = 'copy';
        ctx.drawImage(
            rendererCanvas,
            0, rendererCanvas.height - height, width, height,  // src rect
            0, 0, width, height);                              // dst rect
      }

    }

  }

}

export default Feed
