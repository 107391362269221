import UserEdit from './UserEdit'

class UserProfile {

  constructor(user) {
    this.init(user);
    this.thumbSize = 50;
  }

  init(user) {
    this.user = user;
    this.createView();
    this.layout();

  }

  setLoading(loading, message){

  }

  dispose() {

  }

  createView() {


    this.view = document.createElement('div');
    this.view.id = "userProfileContainer";

    let social  = this.user.twitter ? ('<i class="fa-brands fa-twitter"></i>' + this.user.twitter.replace('@','')) : ('');
    social += this.user.instagram  ? ('  <i class="fa-brands fa-instagram"></i>' + this.user.instagram.replace('@','')) : ('');

    const stuff = `<div class="row" id="profileUser" style="display:flex;">
          <div class="col-4">
            <div id="profileIcon"></div>

          </div>

          <div class="col-8" id="profileMeta">
            <div id="profileName">${this.user.name}</div>
            <div id="profileSocials" class="small">${social}</div>
            <div class="d-none" id="secret">
              <input id="secretText">
              <button class="btn square" id="secretSpeakButton" onclick="kv.secretSpeak(this);">sp</button>
            </div>
            <div class="d-none" id="profileBot"><button id="botButton" class="btn-primary">Make Bot</button></div>
            <div class="d-none" id="profileFollow"></div>
            <div id="followRow" class="row"><div style="float:left;width:110px;" id="followerCount">Followers ${this.user.followerCount ?? 0}</div>&nbsp&nbsp<div id="followingCount" style="float:left;width:110px;">Following ${this.user.followingCount ?? 0}</div></div>
            <div class="d-none" id="profileEditUser"><button style="background:transparent;border:solid blue 1px;width:100%;" id="editUserButton" class="btn square btn-primary square">Edit Profile</button></div>

          </div>

      </div>

      <ul style="margin-top:15px;" id="pillbox" class="nav nav-pills">
        <li class="nav-item">
          <a id="activity" class="nav-link active" >Activity</a>
        </li>
        <li class="nav-item">
          <a id="exhibits" class="nav-link" >Exhibits</a>
        </li>
        <li  class="nav-item">
          <a id="items" class="nav-link" >Items</a>
        </li>
      </ul>

      <div class="d-none" id="pleaseConnect"> Please connect  wallet to create, buy and sell in the kiloverse.</div>
      <div class="mt-4" id="profileStuff"></div>`

    this.view.innerHTML = stuff;

    this.thumbcontainer = document.createElement('div');
    //this.thumbcontainer.id = 'thumbcontainer';

  }


  follow() {

    kv.api(this.isFollowing ? 'unfollow':'follow', 'POST', {followId:this.user.id, followName:this.user.name, userId:kv.user.id}, (r)=>{
      kv.launchUserProfile(this.user);
    });

  }

  filenameFromPath(str) {
    return str.split('\\').pop().split('/').pop();
  }

  showFollowing() {
    profileStuff.innerHTML = '';
    kv.api('following','POST', {userId:this.user.id}, (r)=>{
      r.users.forEach((user, i) => {
        const pd = kv.addUser(user);
        profileStuff.appendChild(pd);
        pd.addEventListener('pointerup', ()=>{
          kv.launchUserProfile(user);
        });

      });
    });
  }


  showFollowers() {
    profileStuff.innerHTML = '';
    kv.api('followers','POST', {userId:this.user.id}, (r)=>{
      r.users.forEach((user, i) => {
        const pd = kv.addUser(user);
        profileStuff.appendChild(pd);
        pd.addEventListener('pointerup', ()=>{
          kv.launchUserProfile(user);
        });

      });
    });
  }

  showItems() {
    profileStuff.innerHTML = 'Loading...';
    kv.api('items', 'POST', {creatorId:this.user.id}, (items)=>{

      this.items = items;
      this.thumbcontainer.replaceChildren();
      profileStuff.replaceChildren(this.thumbcontainer);
      let html = ``;
      let nodes = [];
      for(const item of items) {
        let url = item.url;
      //  if(item.xname)alert(item.xname + ' ' + item.name);
        let fn = this.filenameFromPath(url);
        url = 'https://api.kiloverse.io/thumb/'+fn+'/' + this.thumbSize;
        html += `<div class="item" data-id="${item.id}" style="display:inline-block;width:50px;height:50px;"><img src="${url}"></div>`;
      }
      this.thumbcontainer.innerHTML = html;
    });
  }

  showExhibits() {
      profileStuff.innerHTML = 'Loading...';
      kv.api('exhibits','POST', {userId:this.user.id}, (r)=>{
        this.exs = r.exhibits;
        this.thumbcontainer.replaceChildren();
        profileStuff.replaceChildren(this.thumbcontainer);
        let html = ``;
        for(const ex of this.exs) {
          let url = ex.backgroundUrl;
          let fn = this.filenameFromPath(url);
          url = 'https://api.kiloverse.io/thumb/'+fn+'/' + this.thumbSize;
          html += `<div class="exhibit" data-id="${ex.id}" style="border:solid white 1px;width:30%;float:left;">`
          html += `<img src="${url}">`
          html += `<div>${ex.name}</div>`;
          html += `</div>`
        };
        //alert(html);
        this.thumbcontainer.innerHTML = html;
      });

  }

  showFeed(activity) {

    profileStuff.innerHTML = 'Loading';
    kv.api('activity', 'POST', {userId:this.user.id}, (r)=> {

    if(r.activity.length == 0){
      profileStuff.innerHTML = 'no recent activity';
      return;
    }


    this.thumbcontainer.replaceChildren();
    profileStuff.replaceChildren(this.thumbcontainer);

    let f = {xname:'', parray:[]};
    let feed = [];
    r.activity.reverse();
    this.items = [];

    for(const a of r.activity){
      this.items.push(a);
      if(a.xname != f.xname){

        f = {xid:a.xid, xname:a.xname || '', parray: [a], date:a.date};
        feed.push(f);
      } else {
        f.parray.push(a);
      }

    }

    let html = '';
    for(const f of feed) {
      let dtext = '';
      if(f.date){
        const ago = (Date.now() - f.date);
        const m = parseInt(ago/(60000));
        const h = parseInt(ago/(60000*60));
        const d = parseInt(ago/(60000*60 * 24));
        if(m < 60){
          dtext = `${m}m ago`;
        }else if(h < 24){
          dtext = `${h}h ago`;
        }else{
          dtext = `${d}d`;
        }
      }
      const ptext = (f.parray.length == 1) ? 'piece':'pieces';
      let xtext = ``;
      if(f.xname){
        xtext += `${f.xname}`;
      }
      html += `<div style="clear:both;" id=${f.xid}>`
      html += `<div class="activityDate">${dtext}</div>`;
      html += `<div class="activityText">Added ${f.parray.length} ${ptext} <span class="exhibit" data-id="${f.xid}"><u>${xtext}</u></span> exhibit</div>`;
      html += `<div class="activityPics">`;
      for(const pf of f.parray){
        html += `<img class="item" data-id="${pf.id}" style="border-radius:5px;margin-right:3px;" src="https://api.kiloverse.io/thumb/${pf.id}.png/50">`;
      }
      html += `</div></div>`
    }

    this.thumbcontainer.innerHTML = html;

  });


  }

  _bindEvents() {

    /*
    this.view.addEventListener('click', (e)=>{

      const el = e.target;
      el.classlist.contains...

    });
    */

    document.querySelectorAll('.nav-link').forEach(item => {
      item.addEventListener('click', event => {
        if(event.target.id == 'activity')this.showFeed();
        if(event.target.id == 'exhibits')this.showExhibits();
        if(event.target.id == 'items')this.showItems();
      //debugger;
      //handle click
      });
    });

    followRow.addEventListener('click', (e)=>{
      if(e.target.id == 'followerCount'){
        this.showFollowers();
      } else if(e.target.id == 'followingCount') {
        this.showFollowing();
      }
    });

    profileIcon.addEventListener('click', function(){
      kv.launchUserSettings(this.user);

    });
    editUserButton.addEventListener('click', ()=>{
      kv.launchUserSettings(this.user);
    });



      this.thumbcontainer.addEventListener('click', (e)=>{
        const itemId = e.target.closest('.item')?.dataset.id;
        const exhibitId = e.target.closest('.exhibit')?.dataset.id;

        if(exhibitId){
          //const exhibitData = this.exs.find((e)=>{return e.id == exhibitId});
          //alert(exhibitData.name);
          kv.dismissViewController();
          kv.loadKilo(exhibitId);

        }else if(itemId){
          const itemData = this.items.find((i)=>{return i.id == itemId});

          const imgEditor = kv.launchModal(itemData, true);
          //imgEditor.setViewMode();
        }



      });

  }

  layout() {

    if(!this.view.parentElement)return;

    if(this.view.parentElement && !this.bound){
      this._bindEvents();
      this.bound = true;
    }

    const showProfile = true;
    profileUser.style.display = (showProfile) ? 'flex' : 'none';
    profileName.innerHTML =  this.user.name || '';

    if(!this.user){
      alert('missing user!');
      return;
    }
    kv.makeComposite(this.user, profileIcon);

    botButton.userId = this.user.id;

    profileFollow.innerHTML = '';

    var fb = document.createElement('button');

    fb.className = 'd-none btn square btn-primary';
    fb.onpointerup = this.follow.bind(this);
    profileFollow.appendChild(fb);
    kv.showHide(profileFollow, true);

    pleaseConnect.style.display = kv.walletAddress ? 'none' : 'block';
    const isYou = this.user.id == kv.user.id;

    if((isYou) || kv.isEd()){
      profileEditUser.classList.remove('d-none');
    }else{
      profileEditUser.classList.add('d-none');
    }

    kv.showHide(secret, false);// kv.isEd()
    //kv.showHide(welcomeRow, false);// kv.isEd()

    if(isYou){

    }else if(kv.user.id) {


      kv.api('isfollowing', 'POST',  {userId:kv.user.id, followId:this.user.id}, (r)=>{


        fb.classList.remove('d-none');
        fb.innerHTML =  (r.following ? 'unfollow' : 'follow');
        this.isFollowing = r.following;
      });


    }


    this.showFeed();



  }

}

export default UserProfile;
