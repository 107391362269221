import ImageEditor from './ImageEditor'
import TextEditor from './TextEditor'
import ExhibitEditor from '/ExhibitEditor'
import GIFPicker from './GIFPicker'
import FrameEditor from './FrameEditor'
import KiloPrompter from './KiloPrompter'
import UserProfile from './UserProfile'
import UserEdit from './UserEdit'


class Controller {

  constructor() {
    this.components = [];
  }

  registerComponent(data) {

    var comp;

    if(data.type == 'exhibit')comp = new ExhibitEditor(data);
    if(data.type == 'aiimage')comp = new ImageEditor(data);
    if(data.type == 'background')comp = new ImageEditor(data);
    if(data.type == 'text')comp = new TextEditor(data);
    if(data.type == 'gif')comp = new GIFPicker(data);
    if(data.type == 'frame')comp = new FrameEditor(data);
    if(data.type == 'kiloprompt')comp = new KiloPrompter(data);
    if(data.type == 'user')comp = new UserProfile(data);
    if(data.type == 'useredit')comp = new UserEdit(data);

    if(!comp){
      alert('no component type registered for ' + JSON.stringify(data));
      return;
    }

    this.components.push(comp);

    if(!comp || !comp.view){
      debugger;
    }

    return comp;
  }

  


  unregisterComponent(comp) {
    const l = this.components.length;
    this.components = this.components.filter((c)=>{c!=comp});
    console.log('xxxxx unregisterComponent went from ' + l + ' to ' + this.components.length);
  }

  updateData(datas) {

    if(!datas){
      debugger;
      return;
    }

    for(const t of datas){

      for(const c of this.components){

        if(!c.data.id){
            console.log('component with missing data...')
            //debugger;
        }

        if(t.id == c.data.id && t.componentId != c.id){
          //console.log('kilosyncing data ' + t.id + ' of ' + c.randomId);
          //if(t.type == 'text'){
          console.log('kilosyncing:'+JSON.stringify(t));
            //console.log('kilosyncing: ' + t.text);
          //}
          c.data = t;
          c.layout();

        }
      }
    }
  }



/*
  began(e) {
      console.log('XXX began!');
      const t = e.detail.data;
      const c = e.detail.component;
      this.sendbegan = true;
      this.save(t,c, false, true);
      this.sendbegan = false;
  }

  background(e) {
    const c = e.detail.component;
    const t = {...e.detail.data};
    const bgUrl = kv.api('kilo', 'POST', {id:kv.kilo.id, backgroundUrl:t.url, backgroundPrompt:t.displayPrompt || t.prompt});
  }


  delete(e) {

    const c = e.detail.component;
    const t = e.detail.data;

    if(c)c.setLoading(true, 'Deleting');

    kv.api('deletedata', 'POST', {xid:kv.kilo.id, data:t});

    setTimeout(()=>{
      if(c){
        c.setLoading(false);
        c.dispose();
      }
    },  1000);

  }



  saveImage(e) {
    const t = e.detail.data;

    if(t.edits && t.edits.length > 0){
      t.url = t.edits[t.edits.length-1].url;
    }
    const c = e.detail.component;
    this.saveToExhibit(e);
    this.save(t,c);

  }





  makeGradient(w, h, colors) {


    var canvas = document.createElement( 'canvas' );
    canvas.width = w;
    canvas.height = h;
    console.log('canvas height ' + canvas.height);
    var context = canvas.getContext( '2d' );
    var gradient = context.createLinearGradient( 0, 0, 0, canvas.height );

    for(const stop of colors) {
      gradient.addColorStop(stop.s, stop.c);
    }

    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    return canvas;//.toDataURL('image/png');

  }


  async makeGraidentFile() {
    let g = [
      {s:0.0, c:'white'},
      {s:0.3, c:'white'},
      {s:0.35, c:'black'},
      {s:1.0, c:'black'}
    ];

    g = [
      {s:0.0, c:'gray'},
      {s:1.0, c:'black'}
    ];

    const canvas = this.makeGradient(512, 512, g);
    const file  = await this.canvasToFile(canvas);
    console.log('omg here is a file:' + file);

  }

  gifSelected(e){

    const c = e.detail.component;
    const t = e.detail.data;

    if(t.frameTarget){
      t.frameTarget.frameUrl = t.url;
      this.save(t.frameTarget,c, true, true);
    }else{
      this.save(t,c, true, true);
    }

  }

  saveEdit(e) {

    const c = e.detail.component;
    const t = e.detail.data;
    if(t.edits && t.edits.length > 0){
      t.url = t.edits[t.edits.length-1].url;
    }
    this.save(t,c, false);
  }

  save(data, c, close, nospinner)  {

    if(!kv.kilo)return;

    if(c)data.componentId = c.id;
    const now = Date.now();
    if(c && !nospinner){
      c.setLoading(true, 'Creating');
    }

    if(this.sendbegan) {
      data.editing = {userName:kv.user.name, time:Date.now()};
    } else {
      delete data.editing;
    }

    kv.api('updatexhibititem', 'POST', {xid:kv.kilo.id, userId:kv.user.id, data:data}, ()=>{
      const diff = Date.now() - now;
        setTimeout(()=>{
          if(c)c.setLoading(false);
          if(c && close)c.dispose();
        },  Math.max(2000 - diff,0));
    });

  }



*/

}

export default Controller
