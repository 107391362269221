import {spec, dom} from './Quickdom'
export const { div, el, button, img, textarea, input, label} = dom;

import KiloComponent from './KiloComponent'
import canvasTxt from 'canvas-txt'

import { schema } from "prosemirror-schema-basic";
import { Schema, DOMParser } from "prosemirror-model";
//import  {schema, base} from "prosemirror-schema-basic";
//import  {addListNodes} from "prosemirror-schema-list";

import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Step } from "prosemirror-transform";
import { collab, sendableSteps, getVersion, receiveTransaction } from "prosemirror-collab";
import { undo, redo, history } from "prosemirror-history";
import { keymap } from "prosemirror-keymap";
import { baseKeymap, chainCommands, newlineInCode, createParagraphNear, splitBlock, liftEmptyBlock} from "prosemirror-commands";

import { getFirestore, collection, getDocs, doc, onSnapshot, runTransaction, documentId, query, orderBy} from 'firebase/firestore';
import { getDatabase, ref } from 'firebase/database';

import FirebaseEditor from './FirebaseEditor';

class TextEditor extends KiloComponent {
  
  constructor(data) {
    super(data);
    this.modes.edit = 'edit';
    
  }
  
  createView() {
    super.createView();
    
    const v = div(
      textarea(
        this.data.text, '.textarea'+this.id, {width:'100%', height:'100%', style: 'display:none;font-family:Courier; width:100%; height:100%; border: 0px solid red'}
        ),
        div('.editorHolder', {style: 'width:100%;overflow-y: auto; position:absolute; top:0px; background-color:white;color:black;height:300px;border:solid red 1px;'}),
        div('.buttonrow' + this.id,
        button('Save', '.btn.btn-success.round.w-100.saveText'),
        button('<i class="fa-solid fa-trash delete"></i>', '.btn.btn-danger.w-100.round.delete')
        
        )
        ,{style:'height:300px;'});
        this.view.appendChild(v);
        this.elements.edit = [el['editorHolder'], el['buttonrow'+this.id]];
        //el['textarea'+this.id],
        
        
        this.isSending = false;
        
      }
      
      makeEditor() {
        
        
        let br = schema.nodes.hard_break;
        
       //const p = document.createElement('p');
       // p.innerHTML = this.data.text;
       //let doc =  DOMParser.fromSchema(schema).parse(p);
        

        let brcmd = chainCommands(newlineInCode, createParagraphNear, (state, dispatch) => {
          let {$from, $to} = state.selection
          if ( !$from.parent.isBlock ) return false
          if ( $from.parent.type != schema.nodes.paragraph ) return false
          if ( dispatch ) {
            dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView())
          }
          return true
        }, liftEmptyBlock, splitBlock);
        
        // Parse a string (the contents of the textarea) into a HTML element.
        // Only recognizes blank lines as <p> separators, and line breaks as <br>.
        let  domFromText = (text) =>  {
          const dom = document.createElement("div");
          text.split(/(?:\r\n?|\n){2,}/).forEach(block => {
            let p = dom.appendChild(document.createElement("p"));
            if (block) {
              block.split(/(?:\r\n?|\n)/).forEach(line => {
                if (line) {
                  if (p.hasChildNodes()) p.appendChild(document.createElement('br'));
                  p.appendChild(document.createTextNode(line));
                }
              });
            }
            dom.appendChild(p);
          });
          return dom;
        };
        
        let fromText = (text) => {
          const dom = domFromText(text);
          return DOMParser.fromSchema(schema).parse(dom, { preserveWhitespace: "full" });
        }
        
        const sc = {
          schema: schema,
          doc:fromText(this.data.text),
          plugins: [
            keymap({ "Mod-z": undo, "Mod-y": redo, "Enter": brcmd}),
            collab({version:0})
          ]
        }
        
        
        
        const es =  EditorState.create(sc);

  
        this.stateConfig;
        this.state = es;

        
        
        new FirebaseEditor({
          firebaseRef: ref(window.rtdb, this.data.id),//'/collabs/' + this.data.id
          stateConfig:sc,
          view({ sc, updateCollab }) {
            let view = new EditorView(el['editorHolder'], {
              state: es,
              dispatchTransaction(transaction) {
                let newState = view.state.apply(transaction)
                view.updateState(newState)
                updateCollab(transaction, newState)
              },
            })
            return view
          },
        });
        
        
      }
      
      

      bindEvents() {
        
        
        super.bindEvents();
        
        el['textarea'+this.id].addEventListener("input", this.debounce(() => {
          
          this.data.text = el['textarea'+this.id].value;
          this.syncItem()
          
        }, 500));
        
        el['textarea'+this.id].addEventListener("focus", () => {
          this.syncItem()
          
          //this.view.dispatchEvent(new CustomEvent('began', { bubbles: true, detail: { data:this.data, component:this,  text: el['textarea'+this.id].value }}))
        });
        
        
      }
      
      debounce(callback, wait) {
        let timeout;
        return (...args) => {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => callback.apply(context, args), wait);
        };
      }
      
      layout() {
        super.layout();
        
        if(this.view.parentElement && !this.madeEditor && this.data.text){
          
          this.makeEditor();
          this.madeEditor = true;
        }
        //el['textarea'+this.id].value = this.data.text;
        
      }
      
      
      async renderText() {
        
        const c = document.createElement('canvas');
        c.width = 512 * .777;
        c.height = 512;
        const ctx = c.getContext('2d')
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, c.width, c.height);
        ctx.fillStyle = '#000000' //red color text
        canvasTxt.font = 'Courier'
        canvasTxt.color = 'black'
        canvasTxt.fontSize = 12
        canvasTxt.vAlign = 'top'
        canvasTxt.align = 'left'
        //  const txt =  this.data.text = el['textarea'+this.id].value;
        canvasTxt.drawText(ctx, this.data.text, c.width*.10, c.width*.10, c.width - c.width*.20, c.height - c.width*.2);
        
        return c;
        
        
      }
      
      async saveText() {
        
        const c = await this.renderText();
        const image = await this.canvasToFile(c);
        
        await kv.submitFile(image, {type:'text', dataId:this.data.id});
        this.data.url = 'https://api.kiloverse.io/'+this.data.id+'.png';
        
        await super.saveItem(this.modes.view, kv.user.name + ' is saving...');
        
      }
      
      canvasToFile(canvas) {
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            var file = new File([blob], "text.png");
            resolve(file)
          });
        });
      }
      
      async generate() {
        await this.write('generate', kv.user.name + ' is writing a ' + this.data.prompt);
        this.setMode(this.modes.generate)
      }
      
      async write(mode, message) {
        
        if(!mode || !message)alert(' no mode / message ');
        
        const text = el['textarea'+this.id].value;
        const vars = {prompt:this.data.prompt, text:text};
        
        vars.item = this.data;
        this.data.edit = {message:message, userId:kv.user.id, userName:kv.user.name, time:Date.now()};
        this.data.mode = mode;
        this.data.xid = kv.kilo.id; 
        
        const r = await kv.api2('write', 'POST', vars);
        this.data.text = r.text;
        this.data.creatorId = kv.user.id;
        const c = await this.renderText();
        this.data.url = c.toDataURL();
        this.canvas = c;
        return; 
        
      }
      
    }
    
    export default TextEditor;
    