
class FrameEditor {

  constructor(data) {

    this.isNew = !data.name;
    this.data = data || {};

    this.createView();

    this.view.addEventListener('click', e => {

      const el = e.target
      if (el.classList.contains('btn') || el.classList.contains('delete')) {
        e.target.dispatchEvent(
          new CustomEvent([...el.classList].at(-1),
            { bubbles: true,  detail:{data:this.data, component:this}}));
      }

    });

    this.layout();

  //  if(!this.data.name)this.randomize();

  }

/*
  randomize() {

    kv.api('write', 'POST', {}, (cb)=>{
      exhibitNameInput.value = cb.text;
    });

  }
  */

  setLoading() {

  }

  layout() {

  }

  createView() {

    this.view = document.createElement('div');
    this.view.id = 'frameEditor';

    const stuff = `
    <div style="text-align:center;"><h3>Frame Store</h3></div>
      <div class="p-2">
      <div> Describe frame: </div>

      <div class="form-label"></div>
      <input class="form-control" id="framePromptInput" value="${this.data.framePrompt || 'blue and white neon'}">
      <button class="btn btn-primary btn-green-moon round mt-5 w-100 generateframe">${this.isNew?'Generate':'Save'}</button>
      </div>`

    this.view.innerHTML = stuff;

  }

  getParams() {
    //ebugger;
      return {
        prompt:framePromptInput.value + ' frame',
        url:this.data.url,
        maskId:'frame'
      }
  }

  update(data) {

    //debugger;

  }

}

export default FrameEditor;
