class XEditor {

  constructor(exhibit) {

    this.view = document.createElement('div');
    this.view.id = 'xid';
    this.exhibit = exhibit;
    //this.view.insertAdjacentHTML('beforeend', '<li>third</li>');
    //this.view = `<div id="xbg"></div>`;

  }

  test() {
    alert('hi');
  }

  loadBGs() {
    //kv.api('/skies', 'POST', {}, (r)=>{
      let h = ''
    //  r.map((s, i)=>{
        //const sides = ['ft', 'bk', 'up', 'dn', 'rt', 'lf'];
        for(let i =0; i < 45; i++){
          const url = `https://api.kiloverse.io/sky/${i}/ft`;
          h += `<div data-idx="${i}" style="height:100px;width:177px;float:left;background-size:cover;background-image:url(${url});"></div>`
        }
      //});
      //debugger;
      this.view.insertAdjacentHTML('beforeend',h);

      this.view.addEventListener('click', (e)=>{

        //alert(e.target.dataset.idx);
        const idx = e.target.dataset.idx;
          kv.skybox.load(idx);
          debugger;
        kv.api('exhibit', 'POST', {backgroundId:idx, id:this.exhibit.id}, ()=>{




        });

      });

    //});
  }


}

export default XEditor
