import { throttle } from 'throttle-debounce'
import { renderGrid } from '@giphy/js-components'
import { GiphyFetch } from '@giphy/js-fetch-api'

class GIFPicker {

  constructor(data) {

    this.data = data || {};
    this.createView();
    this.gf = new GiphyFetch('JABhb9FcMIifrOk9apk8PQ7Kgj9mS5RZ');

  }





  render() {

      console.log('fuck me render');

      // here is the @giphy/js-components import
      return renderGrid(
          {
              width: this.view.offsetWidth,
              fetchGifs: (offset) => {
                  // but be sure to pass offset to paginate correctly
                  return this.gf.search(kv.gifTxt, { offset:offset, sort: 'relevant', lang: 'es', limit: 30, type: 'stickers' })
              },
              columns: (this.view.offsetWidth < 500) ? 3 : 4,
              gutter: 6,
              noLink: true,
              onGifClick: (gif) => {
                kv.gifGrid?.remove();
                kv.gifClick(gif, kv.giftxt);

              }

          }, this.view);
  }

  makeGrid(txt)  {


      kv.gifTxt = txt;



      const resizeRender = throttle(500, this.render);


      //throttle(500, render);



      window.addEventListener('resize', resizeRender, false);
      const remove = this.render();
      return {
          remove: () => {
              remove();
              window.removeEventListener('resize', resizeRender, false)
          },
      }
  }

  setLoading() {

  }


  createView() {

    var giph = document.createElement('div');
    giph.id = 'giphy';
    giph.style = 'width:100%;overflow:auto;';
    this.view = giph;


    kv.gifGrid = this.makeGrid(this.data.prompt || 'stars');

    kv.gifClick = function(gif, prompt) {

      this.data.url = gif.images.fixed_height.url;

      //const d=  {command:'add', prompt:prompt, wait:false, type:'gif', url:gif.images.fixed_height.url};
      this.view.dispatchEvent(
        new CustomEvent('gifselected', { bubbles: true,
          detail:{data:this.data, component:this}}));
    }.bind(this);

  }

  dispose() {
    this.view.dispatchEvent(
      new CustomEvent('dispose', { bubbles: true,
        detail:{data:this.data, component:this}}));
  }



}

export default GIFPicker;
