
import * as THREE from 'three';
import canvasTxt from 'canvas-txt'

class People extends THREE.Object3D {

  constructor(data, container, camera) {
    super();
    this.data = data;
    this.container = container;
    this.camera = camera;
    this.isGroup = true;
    this.type = 'Group';
    //kv.kilojunk = [];//2d junk... not sure how to remove it yet
  }

  makeTextSprite(text, idName, cls, id) {

    var width = 200;
    var height = 30;
    const element = document.createElement('div');
    element.id = id;
    element.style.height = height+'px';
    element.style.opacity = 1.0;
    element.style.display = 'block';
    element.style.boxSizing = 'border-box';
    var spr =  new CSS2DObject(element);

     element.innerHTML = text;
     element.className = 'label'
     element.style.color = 'white';
     element.style.opacity = '1';
     element.style.fontSize = '10px';
     element.style.padding = '10px';
     element.style.pointerEvents = 'auto';
     element.style.textAlign = 'center';
     element.className = cls;


     return spr;


  }


  simpleSpeak(userId, path, duration) {

    var x = 0;
    var l = parseInt(duration * 3);
    //console.log('fucking duration ' + d.duration);
    var intervalID = setInterval(function () {

      this.emit(userId);
      if (++x >= l) {
        window.clearInterval(intervalID);
      }

    }.bind(this), 333);

    kv.playAudio(path);

  }

  //kludge because visibility on people group doesnt hide the 2d children decendents

  show2DStuff(show) {

    for(const c of this.children){
      c.getObjectByName('nametag').visible = show;
    }

    kv.update2d();
  }

  destroy() {

    this.clearOldPeople();
    for(const j of kv.kilojunk){
      kv.sceneOrtho.remove(j);
    }
    kv.update2d();
  }

  visibleHeight() {
    this.z = Math.abs(this.position.z);
    // compensate for cameras not positioned at z=0
    const cameraOffset = this.camera.position.z;
    if ( this.z < cameraOffset ) this.z -= cameraOffset;
    else this.z += cameraOffset;
    const vFOV = this.camera.fov * Math.PI / 180;
    return 2 * Math.tan( vFOV / 2 ) * Math.abs( this.z );
  }

  visibleWidth() {
    const height = this.visibleHeight();
    return height * this.camera.aspect;
  }


  animate(slot, start, end, frames, duration, cb) {

    let values =[];
    let times = [];

    for(let i=0; i <= frames; i++) {
      const x = (i/frames);
      const y = (1.0 - Math.pow(1.0 - x, 3));
      values.push(start.x + (end.x-start.x) * y);
      values.push(start.y + (end.y-start.y) * y);
      values.push(start.z + (end.z-start.z) * y);
      times.push(x * duration);
    }

    const position = new THREE.VectorKeyframeTrack(".position", times, values);

    const tracks = [position];

    const length = -1;
    const clip = new THREE.AnimationClip("slowmove", length, tracks);
    const mixer = new THREE.AnimationMixer(slot);

    const action = mixer.clipAction(clip);
    action.clampWhenFinished = true;
    action.setLoop(THREE.LoopOnce);
    action.play()

    this.mixer = mixer;


    this.mixer.addEventListener('finished', function (e){
             if(cb)cb();
    });

  }




  clearOldPeople() {
    this.children.forEach((pers, i) => {
        if(!this.data.users.find(u => u.id == pers.name)){
                //console.log('removed ' + pers.name + 'this is good');
          pers.clear();
          pers.userData.nametag.removeFromParent();
          pers.removeFromParent();

        }
    });

    kv.update2d();

  }


  layout(data) {

        this.data = data;


        this.position.set(0,0,-400);

        //alert(`${this.data.name} has ${this.data.users.length} `)

          if(!kv.publicItems){
            alert('public items not ready');

          }



          if(!this.data){
            console.log('wtf addremovepeople w no kilo...');
            return;//sanity check
          }

          const now = new Date()
          const epoch = now.getTime();
          const minutes = 20;

          var users = this.data.users;

          var size = 360;

          this.clearOldPeople();

          if(users.length > 11)alert('too many people' + this.data.users.length);

          users.forEach((user, i) => {

              kv.publicItems.forEach((pi, i) => {
                if(!user.kilowear && pi.type == 'kilowear')user.kilowear = pi;
                if(!user.drink && pi.type == 'drink')user.drink = pi;
              });

              if(!user.avatar) {
                user.avatar = {};
              }

              var person = this.getObjectByName(user.id);

              if(!person) {

                console.log('adding ' + user.id + 'this is good');
                const  ukw = 'https://api.kiloverse.io/assets/personshadowe.png'
                //'https://storage.googleapis.com/kiloverse/trans.png';
                const umap = new THREE.TextureLoader().load(ukw);
                const ukwmaterial = new THREE.SpriteMaterial({map: umap, transparent:true, color:'black'});

                person = new THREE.Sprite(ukwmaterial);
                person.name = user.id;
                this.add(person);
                //alert('added a person');
                //console.log('ridgewood this add person now has ' + this.children.length + 'people');
                person.center.set(1,0);
                person.scale.set(size, size);

              }

              function makeNametag(txt) {
                //alert(txt);
                //if(!kv.scratchcanvas){
                  //kv.scratchcanvas = new OffscreenCanvas(100,40)
                  //kv.scratchcanvas = document.createElement('canvas');
              //  }
                const c =  document.createElement('canvas');//kv.scratchcanvas;
                const ctx = c.getContext('2d');
                //ctx.font = "48px serif";
                //ctx.strokeText("Hello world", 10, 50);

                c.width =100;
                c.height = 50;

                //ctx.fillStyle = '#000000';
                //ctx.fillRect(0, 0, c.width, c.height);
                //ctx.fillStyle = 'red';
                //ctx.fillRect(0, 0, c.width, c.height);
                ctx.fillStyle = '#FFF' //red color text
                canvasTxt.font = 'Helvetica'
                canvasTxt.color = 'FFF'
                canvasTxt.fontSize = 10;
                canvasTxt.vAlign = 'middle'
                canvasTxt.align = 'center'
                canvasTxt.drawText(ctx, txt, c.width*.10, c.width*.10, c.width - c.width*.20, c.height - c.width*.2)


                const spr = new THREE.Sprite();
                spr.center.set(.5,.5);
                spr.position.set(-0.5, 0.2);
                spr.name = 'nametag';
                spr.material.map = new THREE.CanvasTexture(c);

                //ctx.clearRect(0, 0, c.width, c.height);

                //const scale.set(.5,.5);
                spr.scale.set(.5,.25);
                person.add(spr);
                return spr;

              }
/*
              function addNametag(spr){

                if(!user.name)return;


                spr.name = 'nametag';

                spr.element.className = 'label somenametag';
                spr.element.style.width = '360px';
                spr.element.style.height = '360px';
                spr.element.style.paddingRight = '20px';
                spr.element.style.textTransform  = 'uppercase';
                spr.element.style.pointerEvents = 'none';

                kv.sceneOrtho.add(spr);
                kv.kilojunk.push(spr);

                spr.rotation2D = 0;
                window.spr = spr;

                window.nametag = spr;
                window.three = THREE;

                return spr;

              }

*/

              function addHelmetBG() {
                const v = new THREE.Sprite();
                v.center.set( 1.0, 0.0 );
                v.position.set(0.0, 0.0);
                v.name = 'helmetbg';
                const scale = size;
                person.add(v);
                return v;
              }

              function addVisor() {
                const v = new THREE.Sprite();
                v.center.set( 1.0, 0.0 );
                v.position.set(0.0, 0.0);
                v.name = 'visor';
                const scale = size;
                person.add(v);
                return v;
              }

              function addKw() {

                const kw = new THREE.Sprite();
                kw.center.set( 1.0, 0.0 );
                kw.position.set(0.0, 0.0);
                kw.name = 'kilowear';
                const scale = size;
                person.add(kw);
                return kw;

              }


              function addBubble(bubbleSprite) {


                bubbleSprite.name = 'bubble';
                bubbleSprite.position.x = 0;
                bubbleSprite.position.y = 0;
                bubbleSprite.element.className = 'label ccbubble';
                bubbleSprite.element.style.maxWidth = '175px';
                bubbleSprite.element.style.weight = 'lighter';
                bubbleSprite.element.style.height = 'auto';
                bubbleSprite.position.x = -1.0;
                bubbleSprite.position.y = 1.0;
                bubbleSprite.scale.set(0.005, 0.005);
                bubbleSprite.name = 'bubble';
                kv.sceneOrtho.add(bubbleSprite);
                kv.kilojunk.push(bubbleSprite);

              //  debugger;



                return bubbleSprite;
              }


              function isHvc (el) {
                  return el.canPlayType('video/mp4; codecs="hvc1"').length > 0;
              }

              function addAv() {




                      const videoHead = new THREE.Sprite();
                      videoHead.center.set(0.5, 0.5);



                      var scale = user.avatar.scale || 1.0;
                      if(user.isBot)scale = .66;


                      //alert('y' + user.avatar.y);

                      var y = user.avatar.y || 0;
                      videoHead.scale.set(scale, scale);
                      videoHead.position.set(-.5, .5 + y);


                      window.cat = videoHead;
                      person.add(videoHead);
                      videoHead.name = 'avatar';
                      return videoHead;

              }

              function setKwTexture(url) {
                if(person.userData.kwTexture ==  url)return;
                person.userData.kwTexture = url;
                const map = new THREE.TextureLoader().load(url);
                const kwmaterial = new THREE.SpriteMaterial( { map: map, color: 0xffffff } );
                person.getObjectByName('kilowear').material = kwmaterial;

              }

              function setHelmetBGTexture(url) {
                if(person.userData.helmetbgTexture ==  url)return;
                person.userData.helmetbgTexture = url;
                const map = new THREE.TextureLoader().load(url);
                const helmetbgmaterial = new THREE.SpriteMaterial( { map: map, color: 0xffffff } );
                person.getObjectByName('helmetbg').material = helmetbgmaterial;
              }

              function setVisorTexture(url) {
                if(person.userData.visorTexture ==  url)return;
                person.userData.visorTexture = url;
                const map = new THREE.TextureLoader().load(url);

                const visorMaterial = new THREE.SpriteMaterial( { map: map, color: 0xFFFFFF } );
                //reflection experiment
                //const alphamap = new THREE.TextureLoader().load(mask);
              //  visorMaterial.alphaMap = alphamap;
                person.getObjectByName('visor').material = visorMaterial;
              }


              function setAvatarTexture(url) {
                if(person.userData.avatarTexture == url)return;
                person.userData.avatarTexture = url;

                var vid = 'video-'+user.id;
                var videoTag = document.getElementById(vid);
                if(!videoTag){

                  videoTag = document.createElement('video');
                  videoTag.id = vid;
                  videoTag.setAttribute('crossOrigin', 'anonymous');
                  videoTag.style.display = 'none';
                  videoTag.autoplay = true;
                  videoTag.loop = true;
                  videoTag.muted = true;// "muted";
                  videoTag.playsinline = true;

                  userVideos.appendChild(videoTag);

                }

                //<video id="user-" autoplay loop muted crossOrigin="anonymous" autoplay="true" muted="muted" playsinline style="display:none"></video>-->


                var avatarTexture;

                if(kv.iOS()){

                  //alert(url);
                  avatarTexture = new THREE.TextureLoader().load(url);
                  //const kwmaterial = new THREE.SpriteMaterial( { map: map, color: 0xffffff } );

                } else {


                    videoTag.onloadeddata = function() {
                      videoTag.currentTime = Math.random() * 5.0;
                      videoTag.play();
                    };
                    videoTag.src = url;
                    avatarTexture = new THREE.VideoTexture(videoTag);
                    avatarTexture.format = THREE.RGBAFormat;


                }

                const videoMaterial =  new THREE.SpriteMaterial( {map: avatarTexture});
                person.getObjectByName('avatar').material = videoMaterial;


              }

              function addDrink() {
                const drinksprite = new THREE.Sprite();
                drinksprite.name = 'drink';

                drinksprite.center.set( 0.5, -1 * (0.0));//from bottom left...
                drinksprite.position.set(-0.2, 0.0);
                person.add(drinksprite);
                return drinksprite;
              }

              function setNameTag() {

                if(user.name == person.userData.nametagName)return;

                if(person.userData.nametag)person.userData.nametag.removeFromParent();
                let nametag = makeNametag(user.name.toUpperCase());
                person.userData.nametag = nametag;
                person.userData.nametagName = user.name;

              }

              function setDrinkTexture(url) {
                if(person.userData.drinkTexture == url)return;
              //  console.log('drink texture changed!');
                person.userData.drinkTexture = url;
              //  console.log('/ texture ' + url);
                const tex = new THREE.TextureLoader().load(url, ()=>{
                      //const third = person.scale.y * .5;
                      const xscale = 0.25;// person.scale.y/(tex.image.height * 2.0);



                      person.getObjectByName('drink').scale.set(xscale, xscale);
                    //  console.log('drink should now be ' + xscale * tex.image.height);

                });
                const drinkmaterial = new THREE.SpriteMaterial({ map: tex, color: 0xffffff });

                person.getObjectByName('drink').material = drinkmaterial;
              }

  /*
              function addHelmet() {
                const helmet = new THREE.Sprite();
                helmet.name = 'helmet';
                helmet.center.set( 1.0, 0.0 );
                helmet.position.set(0.0, 0.0);
                person.add(helmet);
                return helmet;
              }

              function setHelmetTexture(url) {
                if(person.userData.helmetTexture == url)return;
                person.userData.helmetTexture = url;

                const hmap = new THREE.TextureLoader().load(url);
                const helmetmaterial = new THREE.SpriteMaterial( { map: hmap, color: 0xffffff } );
                person.getObjectByName('helmet').material = helmetmaterial;
              }

  */



              if(false){

              }else{



                var v = person.getObjectByName('helmetbg') || addHelmetBG();
                var hbg;

                if(user.kilowear && user.kilowear.name){
                  if(user.kilowear.name=='spacex')hbg = require('./spacexback.png');
                  if(user.kilowear.name=='startrek')hbg = require('./startrekback.png');
                  if(user.kilowear.name=='matt')hbg = require('./mattback.png');
                  if(user.kilowear.name=='apollo')hbg = require('./apolloback.png');
                  if(hbg)setHelmetBGTexture(hbg);
                }

              }

              if(!user.avatar || !user.avatar.id){

              var visor = person.getObjectByName('visor') || addVisor();
              var visor;

              if(user.kilowear && user.kilowear.name){
                if(user.kilowear.name=='spacex')visor = require('./spacexvisor.png');
                if(user.kilowear.name=='startrek')visor = require('./startrekvisor.png');
                if(user.kilowear.name=='matt')visor = require('./mattvisor.png');
                if(user.kilowear.name=='apollo')visor = require('./apollovisor.png');
                if(visor)setVisorTexture(visor);
              }


              } else {

                  var avatar = person.getObjectByName('avatar') || addAv();

                  var avId = user.avatar.id;
                  if(user.isBot)avId = 'y38V27018fBPc5gjn09c';
                  //put it back here
                  var av = 'https://cdn.kiloverse.io/' + avId + '.webm';

                  if(isHvc(document.getElementById('videotexture'))){
                    av = 'https://cdn.kiloverse.io/'+ avId +'.mp4';
                    if(user.isBot)av = 'https://cdn.kiloverse.io/'+avId+'.mp4';
                  }

                  if(kv.iOS()){
                    av = 'https://cdn.kiloverse.io/' + avId + '.png';
                  }

                  var png = 'https://cdn.kiloverse.io/' + avId + '.png';

                  //alert(kv.iOS()?'ios' : 'failed ios check');

                  setAvatarTexture(av);

              }

              if(!user.kilowear){

              }else{

                var kw = person.getObjectByName('kilowear') || addKw();

              }

              if(!user.drink){

              } else {
                  var drink = person.getObjectByName('drink') || addDrink();
                  setDrinkTexture();
              }


              setNameTag();
              



              var bubbleSprite = this.makeTextSprite(user.cc, 'cc'+user.id);

              var bubble = person.userData.bubble || addBubble(bubbleSprite);//.bind(this);
              person.userData.bubble = bubble;

              const fresh = user.lastMessageTime && ((epoch - user.lastMessageTime) < 10000);
              const speaking = user.cc && user.cc.length > 0;

              bubble.visible = person.userData.isSpeaking;


              const newSpeech = (user.ccPath != person.userData.lastSpeechPath);

              //console.log(user.name + 'speech:' + user.cc + ' duration:' + user.ccLength + ' prev:' + user.lastSpeechPath);

              if(fresh && speaking && newSpeech) {//check for speaking ?

                console.log(user.ccPath);

                person.userData.lastSpeechPath = user.ccPath;

                //debugger;
                this.simpleSpeak(user.id, user.ccPath, user.ccLength);

                if(kv.user.id == user.initiatorId && user.cc.includes('create')){
                  //alert(JSON.stringify(user.cc));
                  kv.makeForBot(user.cc);
                }

                bubble.element.innerHTML = user.cc;
                person.userData.isSpeaking = true;

                person.userData.name = user.name;
              //  person.userData.bubble = bubble;
                //kv.bubble = bubble;


                bubble.visible = true;
                //kv.bubble = bubble;

                //alert('yo');
                kv.update2d();

                /*

                this.animate(bubble, 
                  {...bubble.position},
                  {x:bubble.position.x, y:0, z:bubble.position.z},
                  150,5, ()=>{

                 });

                 */


                setTimeout((r)=>{

                  


                  person.userData.isSpeaking = false;
                  //bubble.visible = false;
                  kv.update2d();



                }, user.ccLength*1000 + 1500);

              }


              if(user.name){


                // s * .2 = 175
                let spr = person.userData.nametag;
                spr.position.y = .25;

                //spr.element.style.paddingTop =  '175px';
                //nametag.position.y =

                if(user.kilowear) {
                  if(user.kilowear.name == 'spacex')spr.position.y =  .2;// * 180/175;
                  if(user.kilowear.name == 'startrek')spr.position.y =  .37;

                }
                //if(user.kilowear.name == 'matt')spr.position.y *= 167/175;// '167px';

                //nametag.element.innerHTML = user.name;//+'<br>'+user.id.slice(0,3)+'';

              }



              if(user.drink)setDrinkTexture('https://cdn.kiloverse.io/'+user.drink.id+'.png');


              if(user.kilowear){


                var kwu = 'https://cdn.kiloverse.io/'+user.kilowear.id+'.png';

                //  console.log(`honesty ${user.kilowear.name} || ${kwu}`);
                //alert(user.kilowear.name);
                if(user.kilowear.name == 'spacex'){
                  kwu = require('./spacex.png');
                }

                //const test = require('./kilosuitblue.png');

                setKwTexture(kwu);

              }else{
                console.log(`honesty kilwear missing...`);
              }

              //helmet.visible = user.avatar &&  user.avatar.isSelfie;

          });

          userVideos.childNodes.forEach((v, i) => {
            var uid = v.id.replace('video-','');
            if(this.data.users.filter((u)=>{u.id == uid}).length == 0){
              //console.log('need to remove video-'+uid);
            }
          });

          this.positionPeople();
  }


  render() {

    let bubbles = false;
    for(const p of this.children){
      if(p.userData.bubble.visible){

        p.userData.bubble.position.y += .1;
        bubbles = true;
      
      }
    }

    if(bubbles)  kv.update2d();

  }

  positionPeople() {

    //var scale = .666;
    var mobileYBump = 0;

    const w = this.visibleWidth()
    const h = this.visibleHeight()

    if(this.container.offsetHeight>this.container.offsetWidth) {
      mobileYBump = (60 / this.container.offsetHeight) * h;
    }

    const per =  .9 / this.children.length;


    let screenpx = per * this.container.offsetWidth;


    screenpx = Math.min(screenpx, 175);

    let worldpx = (screenpx/this.container.offsetWidth) * w;

    let scale = worldpx/360;


    this.scale.set(scale,scale);

    const moveRight = w * .5;
    const moveDown = h * -.5;

    this.position.set(moveRight, moveDown, -400);

    var size = 360;
    this.children.forEach((pers, i) => {

      pers.position.set(-size*.66 * i, 0);//(users.length-i-1)

      const bubble = pers.userData.bubble;
      const nametag = pers.userData.nametag;

      const v2 = new THREE.Vector3(pers.position.x, pers.position.y, pers.position.z);
      this.localToWorld(v2);
      v2.project(kv.camera);

      let avsize = 360 * scale;

      if(bubble){

        bubble.updateMatrix();
        bubble.updateWorldMatrix(true, true);
        bubble.updateMatrixWorld(true);

        bubble.position.x = v2.x * this.container.offsetWidth * .5 - screenpx * .5 - bubble.element.offsetWidth * .66;
        bubble.position.y = v2.y * this.container.offsetHeight * .5 + screenpx * .85;

        kv.update2d();

      }

      if(nametag){

        //nametag.position.x = v2.x * this.container.offsetWidth * .5 - screenpx * .5;
        //nametag.position.y = v2.y * this.container.offsetHeight * .5 + screenpx * .25;



      }

    });

    kv.update2d();

  }

  emit(userId){

      const ringsize = 50.0;
      const map = new THREE.TextureLoader().load( 'https://storage.googleapis.com/kiloverse/ring.png' );
      const material = new THREE.SpriteMaterial( { map: map } );
      const sprite = new THREE.Sprite( material );
      sprite.scale.set(.01,.01);
      sprite.position.set(-.5,.5);
      sprite.alpha = .5;
      gsap.to(sprite.scale, 4, {x:1,y:1, delay:0, duration:4.0,  ease: "power2.out"});
      gsap.to(sprite, 4, {alpha:0, delay:0, duration:4.0,  ease: "power2.out"});


      gsap.to(material, 1, { opacity: .0 })
      const user = this.getObjectByName(userId);
      if(user)user.add(sprite);


      setTimeout(function(){

        if(this.getObjectByName(userId)){
          this.getObjectByName(userId).remove(sprite);
        }
          //this.getObjectByName(userId).remove(sprite);
      }.bind(this),4000);

  };


  mouthPosition(user) {


    var rect = videoPlayer.getBoundingClientRect();
    var x = user.mouth.x/1280.0 * this.container.offsetWidth;
    var h = this.container.offsetWidth * (9.0/16.0);
    var b = (1.0 - user.mouth.y/720.0) * h
    var y = rect.bottom - b;

      if((videoPlayer.clientWidth/videoPlayer.clientHeight) < (16.0/9.0)){
        var h = rect.bottom-rect.top;
        var w = h * (16.0/9.0);
        var r =  (1.0 - user.mouth.x/1280.0) * w;
        x = this.container.offsetWidth - r;
        y = rect.top + h * user.mouth.y/720.0;
      }

      return {x:x, y:y};
  }


}

export default People
