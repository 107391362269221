

export const dom = new Proxy({ el: {}, els: {} }, {

  get: (o, key) =>
  o[key] != null ?
  o[key] : o[key] = (...args) => {
    const el = document.createElement(key);
    args.forEach(arg => {
      if (typeof arg === 'string') {
        if (arg.startsWith('.')) {
          const classes = arg.split('.');
          classes.forEach(cls => {
            if (cls.length > 0) {
              el.classList.add(cls.trim())
              if (!dom.els[cls]) {
                dom.els[cls] = [el]
              } else {
                dom.els[cls].push(el)
              }
              dom.el[cls] = el
            }
          })
        } else {
          const span = document.createElement('span');
          el.innerHTML = arg;
          el.appendChild(span);
        }
      } else if (typeof arg === 'object') {
        if (arg.tagName != null) {
          el.appendChild(arg);
        } else {
          for (let i in arg) {
            el.setAttribute(i, arg[i]);
          }
        }
      }
    });
    return el;
  },set(o, key, v) {
    o[key] = v;
  }
});




//}
