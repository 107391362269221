import {dom} from './Quickdom'
export const { div, el, button, img, textarea, input, label} = dom;

import KiloComponent from './KiloComponent'
import Sketchpad from 'responsive-sketchpad'


class ImageEditor extends KiloComponent{

  constructor(data) {

    super(data);
    this.modes.keep = 'keep';
  }

  bindEvents() {
    super.bindEvents();
  }

  async delete(){

    let result = confirm('Delete item?');

    let d = {itemId:this.data.itemId};

    if(this.data.xid)d.xid = this.data.xid;

    if(result){
      await kv.api2('deleteitem', 'POST', d);
    }

    kv.dismissViewController();

  }

  updatePrice() {
    //using this cant get dom for some reason... because of switch scope ?
    const sw = document.getElementById('saleswitch');
    const el = document.getElementById('price');
    el.style.visibility = sw.checked ? 'visible':'hidden';
  }

  assignFrame(url) {
    this.data.frameUrl = url;
    this.layout();
  }

  createView() {

    super.createView();

    //const gifUrl = require('./mountainloadersquare.gif');
    const v =  div(

      div(
        div(
          div(
            div('Paint over area to edit.', '.small', {style:'font-size:15px'}),
          '.col-6'
          ),
          button('Clear', '.col-2.btn.clearmask'+'.clearMask', {style:'height:25px;background-color:red;padding:0px;'}),
          div(
            div('brush size', '.brushsizelabel', {style:'font-size:13px'}),
            input('', '.rangeslider.form-range.brushsize', {type:'range', value:100}),
          '.editslidercol.col-4'
          ),
        '.row.mask'),
        div(
          div(
            textarea('', '.form-control.editprompt', {placeholder:'describe edit', value:'', style:'height:40px;width:100%'}),
          '.editpromptcol.col-sm-8'
          ),
          div(
            div('prompt strength', '.promptstrengthlabel', {style:'font-size:13px'}),
            input('', '.rangeslider.form-range.strength', {type:'range', value:50}),
          '.editslidercol.col-sm-4'
          ),
          '.row'),
        div(

            button('Generate Edit','.btn.btn-success.round.generateEdit'),

        '.editorbuttonrow'),
      '.editor'
      ),

      div(
        div(
          div('Keep this edit?', '.col', {style:'font-size:18px;color:white;text-align:center;'})
        ), '.row',
        button('No','.btn.round.btn-success.col.dontKeepEdit'),
        button('Yes','.btn.round.btn-success.col.keepEdit'),
        button('Retry Edit','.btn.btn-success.round.col.generateEdit'),
      '.row.buttonrow.keeprow')

    );

    this.view.appendChild(v);


      this.elements.edit = [el['who'],el['editor'], el['image'], el['sketchpad']];
      this.elements.keep = [el['who'],el['keeprow'], el['image']];



    this.pad = new Sketchpad(el['sketchpad'], {
      line: {
        color: '#000000',
        size: 110
      }
    });

    this.pad.canvas.style.opacity = .7;

    this.pad.canvas.addEventListener('pointerover', (e)=>{
      e.target.style.opacity = .7;
    });
    this.pad.canvas.addEventListener('pointerdown', (e)=>{
      e.target.style.opacity = .7;
    });

    el['brushsize'].addEventListener('change', function(e){
       console.log(e.target.value);
       const size =   10 + (e.target.value / 100.0) * 100;
       this.pad.setLineWidth(size);
    }.bind(this));

    this.lastStrokeCount = 0;

  }

  clearMask() {
      this.pad.clear();
  }


  /*
  update(data) {
    this.data = {...data};
    this.layout();
  }
*/

  canvasToFile(canvas) {
    return new Promise((resolve, reject) => {
       canvas.toBlob((blob) => {

         var file = new File([blob], "mask.png");
         resolve(file)
       });
    });

  }




  async createMaskFile() {

    const w = this.pad.canvas.width;
    const h = this.pad.canvas.height;

    const newcanvas = document.createElement('canvas');
    newcanvas.width = w;
    newcanvas.height = h;

    const newctx = newcanvas.getContext("2d");
    newctx.fillStyle = 'white'
    newctx.fillRect(0, 0, w, h);

    newctx.drawImage(this.pad.canvas, 0, 0, w, h);
    const file  = await this.canvasToFile(newcanvas);

    //console.log('omg here is a file:' + file);
    return file;
  }

  layout() {

    super.layout();

    this.pad.resize(el['image'].clientWidth, el['image'].clientHeight);
    this.pad.setLineWidth(30);


  }


  keepEdit() {
    this.clearMask();

    if(this.data.edit)delete this.data.edit
    this.setMode(this.modes.meta, 'keep');
  }

  dontKeepEdit(e) {
    if(this.data.edit)delete this.data.edit;
    this.data.url = this.data.oldurl;
    this.setMode(this.modes.edit, 'dontkeep');
  }

  async generate() {
    if(this.data.url)delete this.data.url;
    const r = await this.dream(false, 'generate', kv.user.name + ' is generating ' + this.data.prompt);
    //this.data.url = r.url;
  }

  async generateEdit() {
    this.data.oldurl = this.data.url;
    const r = await this.dream(true, 'keep', kv.user.name + ' is editing ' +  this.data.editprompt);
   //this.data.url = r.url;
  }

  async dream(edit, mode, message) {

    let params = {prompt:this.data.prompt, displayPrompt:this.data.displayPrompt || this.data.prompt};

    if(edit){

      var strength = el['strength'].value/100.0;
      var dirty = false;
      const strokeCount = this.pad._strokes.length
      var localMaskFile;
      if(strokeCount != this.lastStrokeCount || strength != this.lastStrength){
        this.lastStrokeCount = strokeCount;
        dirty = true;
      }
      this.lastStrength = strength;
      if(dirty){
        localMaskFile = await this.createMaskFile()
        this.maskId = null
      }

      if(this.pad._strokes.length == 0)localMaskFile = null;

      console.log('return params with mask file ' + localMaskFile);
      params = {
        prompt:el['editprompt'].value,
        strength:strength,
        maskId:this.maskId,
        maskFile:localMaskFile
      };

      this.pad.canvas.style.opacity = 0.0;

    }

    params.id = kv.makeId(5);
    params.type = this.data.type;
    if(this.data.url && this.data.mode == this.modes.edit || this.data.mode == this.modes.keep)params.url = this.data.url;

    if(!params.maskId && params.maskFile) {
      console.log('submitting mask file ');
      params.maskId = await kv.submitFile(params.maskFile, {type:'mask', strength: params.strength || .5});
      this.maskId = params.maskId;
    }


    this.data.edit = {message:message, userId:kv.user.id, userName:kv.user.name, time:Date.now()}
    this.data.xid = kv.kilo.id;
    this.data.mode = mode;

    params.item = this.data;
    const r = await kv.api2('dream', 'POST', params);

    return r;
 
  }

}

export default ImageEditor;
