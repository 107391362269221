import * as THREE from 'three';
//import { CSS3DRenderer, CSS3DObject, CSS3DSprite} from 'three/examples/jsm/renderers/CSS3DRenderer.js'
import { CSS2DRenderer, CSS2DObject} from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import * as Tone from 'tone'

import Chatlog from './Chatlog'
import Enlargement from './Enlargement'
import QuickParse from './QuickParse'
import Controller from './Controller'

import Exhibit from './Exhibit'

import People from './People'
import UserProfile from './UserProfile'
import UserEdit from './UserEdit'

import Feed from './Feed'
import XEditor from './XEditor'

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, onSnapshot, query, where, runTransaction, FieldValue } from 'firebase/firestore';
import { getDatabase, ref } from 'firebase/database';



const firebaseConfig = {
  apiKey: "AIzaSyCgHN3Ybub-HR153teCEqm3vRaB77n2SOg",
  authDomain: "kiloverse.firebaseapp.com",
  projectId: "kiloverse",
  storageBucket: "kiloverse.appspot.com",
  messagingSenderId: "338402832232",
  appId: "1:338402832232:web:8d4b25b5d6bf2ce1f6f0dd",
  measurementId: "G-55EQLDT3LR"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
window.db = getFirestore(app);
window.rtdb = getDatabase(app);
window.app = app;
// Initialize Realtime Database and get a reference to the service


//window.doc = doc;
//window.onSnapshot = onSnapshot;
//window.runTransaction = runTransaction;


function bakeCookie(name, value, days) {

  if(!value.id){
    alert('failed to set cookie');
    return;
  }

  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  }

  localStorage.user =  JSON.stringify(value);
}

function clearCookie(name) {
  localStorage.clear(name);
}


function readCookie(name) {
  if(localStorage.user)return JSON.parse(localStorage.user);
  if(!localStorage.user)return null;
}


kv.addressFromIndex = function(i) {
  var code = i.substring(0,5);
  return code;
}

var isfullscreen  = false;

function fullscreen() {

  const normal = !document.fullscreenElement &&    // alternative standard method
          !document.mozFullScreenElement && !document.webkitFullscreenElement;


  if (normal) {  // current working methods
            isfullscreen = true;
            kv.layout();
           if (document.documentElement.requestFullscreen) {
             document.documentElement.requestFullscreen();
           } else if (document.documentElement.mozRequestFullScreen) {
             document.documentElement.mozRequestFullScreen();
           } else if (document.documentElement.webkitRequestFullscreen) {
             document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
           }

         } else {
           isfullscreen = false;
           kv.layout();

            if (document.cancelFullScreen) {
               document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
               document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            }
         }

}


kv.isSafari = function() {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari;
}

kv.musicPlaylist = [];

kv.autoLaunch = function() {

  let editTime = 0;
  let editItem;
  let kill = [];
  //todo kill old edits...
  for(const item of kv.kilo.items){
    console.log('autolauncher checking ' + item.prompt + ' ' + ' edit time ' + item.edit?.time);

    if(item.edit){
      const ago = (Date.now() - item.edit.time);
      
      const old = ago > (1000 * 60 * 10); 
      if(old)kill.push()
      if(item.edit.time > editTime && !old){
        editTime = item.edit.time;
        console.log(`autolaunch found valid piece created ${ago}`)
        editItem = item;
      }
    }

    
  }

  if(kill.length > 0)console.log(`autolaunch found %{kill.length} items that are too old`)

  if(editItem){

    console.log('AUTOLAUNCHER HAS DECIDED TO SHOW ' + editItem.prompt);
    const alreadyShowing = editItem.id == kv.modal?.data.id;
    if(alreadyShowing){
      console.log(`autolauncher found item being edited but already showing...`);
    }
    if(!alreadyShowing)kv.launchModal(editItem)
    kv.modal.data = editItem
    kv.modal.layout();
    //console.log(`**** auto launch  kilosync received for ${editItem.id} from ${editItem.edit.userId} (${editItem.edit.userName}) mode ${editItem.mode}`);
  }

  //if(kv.modal && !editItem){
    //kv.dismissViewController();
  //}

}


kv.loadKilo = function(slugOrFullXid) {


  kv.showFeed = false;

  kv.destroyEnlargeComponent();


  window.history.pushState('', 'Kiloverse ', '/'+kv.addressFromIndex(slugOrFullXid));
  kv.sceneOrtho.visible = true;

  /*warning - xid */ 
  kv.api('kilo/'+slugOrFullXid, 'GET', {}, r => {

      kv.kilo = r;

      kv.kilo.users = kv.kilo.users || [];
 
      if(kv.x){
        kv.x.visible = false;
        kv.x.destroy();
        kv.x.parent.remove(kv.x);
        kv.x = null;
      }

      kv.x = new Exhibit(kv.kilo, container, kv.camera);

      kv.x.visible = false;
      kv.scene.add(kv.x);

      //kv.x.layout(kv.kilo, true);


      if(kv.unsubscribeExhibit)kv.unsubscribeExhibit();
      if(kv.unsubscribeItems)kv.unsubscribeItems();


      const q = query(collection(db, "items"), where("xid", "==", kv.kilo.id));


      kv.unsubscribeItems = onSnapshot(q, (querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push({id:doc.id, ...doc.data()});
          });
          
        
          let c = {a:0, m:0, r:0};
          querySnapshot.docChanges().forEach((change) => {
   
            if (change.type === "added") {
                c.a++;
                //console.log("New city: ", change.doc.data());
            }
            if (change.type === "modified") {
              c.m++;
                //console.log("Modified city: ", change.doc.data());
            }
            if (change.type === "removed") {
              c.r++;
                //console.log("Removed city: ", change.doc.data());
            }
          });

          kv.kilo.items = items;
          if(kv.x)kv.x.layout(kv.kilo, true);

          kv.autoLaunch();

      });


      //console.log('listening for changes on ' + kilo.id);
      kv.unsubscribeExhibit = onSnapshot(doc(db, "exhibits", kv.kilo.id), (doc) => {

        if(!doc.data()){
          debugger;
        }

        //if((Date.now() - kv.lastAtomSync) < 1000)alert('slow down');
        kv.lastSync = Date.now();


    
        const items = kv.kilo.items || [];
        kv.kilo = {id:kv.kilo.id,  ...doc.data(), items:items};
        kv.kilo.users = kv.kilo.users || [];
    

        if(!kv.chatlog){
          kv.chatlog = new Chatlog();
        }

        kv.chatlog.update(kv.kilo.chatlog || []);

        var enlargeData;

        //console.log('snapshot heard..' + (artChanged ? 'datas changed' : 'datas same'));

        if(!kv.lastEnlargeTime)kv.lastEnlargeTime = 0;


  

   
        if(kv.x)kv.x.layout(kv.kilo);

        if(kv.enlargement)kv.enlargement.position();
        exhibitTitle.innerHTML = kv.kilo.name;
        resize();
      });


      resize();

 

      //place the user in the room ...
      if(!kv.headless && kv.user.id)kv.updateUser({id:kv.user.id, xid:kv.kilo.id || 0, join:true});

  });

}


kv.update2d = function() {
  kv.renderer2D.render(kv.sceneOrtho, kv.cameraOrtho);
}

function distance(p, point) {
 return Math.sqrt(Math.pow(point.x - p.x, 2) + Math.pow(point.y - p.y, 2))
}

function raycast(event, mouseX, mouseY) {

    var rc = new THREE.Raycaster();

    var mouse = new THREE.Vector2(); // create once
    mouse.x = mouseX;//( event.clientX / renderer.domElement.clientWidth ) * 2 - 1;
    mouse.y = mouseY;// - ( event.clientY / renderer.domElement.clientHeight ) * 2 + 1;

   rc.setFromCamera(mouse, kv.camera);

   var intersectedPeople = rc.intersectObjects(kv.x.people.children, false);

   if ((intersectedPeople.length > 0) && kv.x &&  kv.x.visible) {

        var intersectedPerson = intersectedPeople[0].object;
        var selectedPeople = kv.kilo.users.filter(u => u.id == intersectedPerson.name);
        if(selectedPeople.length > 0){
            if(selectedPeople[0].isBot){


              const bd = {...selectedPeople[0]};
              let ctx =  kv.getChatContext();
              Object.assign(bd, ctx);

              kv.api('idea', 'POST', {botData:bd, xid:kv.kilo.id, humanId:kv.user.id, situation:kv.kilo.name}, (r)=>{

                  kv.ideaQueue = r;
              });

            } else {
              kv.launchUserProfile(selectedPeople[0]);
            }
            return true;
        }

   }



   if(kv.x && kv.x.art && kv.x.visible){

     const art = [];
     for(const p of kv.x.art.children){
       if(p.userData.art)art.push(p.userData.art);
     }
     var intersectedArt = raycaster3D.intersectObjects(art, false);
  //   const isGlow =  (intersectedArt.length == 1 && intersectedArt[0].object.userData.isBacklight);

    console.log('intersected art children' + intersectedArt.length);

     if(intersectedArt.length > 0){


       const slot = intersectedArt[0].object.parent;

       //var data = intersectedArt[0].object.parent.userData.data;
       if(slot.userData.front){
            kv.x.art.shrink(slot);
       }else{
            kv.x.art.enlarge(slot);
       }


       return true;

   }else if(kv.enlargement){

      kv.destroyEnlargeComponent();//probably too harsh?

   }

  }


}

var vertices, raycaster3D, mouse = { x : 0, y : 0 };



var loadStuff = function() {

    startKiloverse();
    loadTouchStuff();

    kv.loadPublicItems(function(r){
      kv.publicItems = r;

      if(!kv.user) {//|| !user.avatar || !user.drink || !user.name
        kv.user =  {};//TODO SET DEFAULTS...?
        kv.launchUserSettings(kv.user, true);
      }


        var p = window.location.href;
        var end = p.substring(p.lastIndexOf('/') + 1);
        if(end.includes('headless')){
          slug = end.replace('headless','');
          kv.headless = true;
          kv.loadKilo(kv.indexFromAddress(slug));
        }else if(end.length >=5) {
          kv.loadKilo(end);
        } else {


          kv.launchFeed();

        }

    });

}

kv.launchFeed = function() {

  kv.feed.load(()=>{
      kv.showFeed = true;
      resize();
      kv.feed.layout();
  });

}


kv.saveAndRenderKilo = function() {
  kv.api('kilo', 'POST', kv.kilo, r => {
    kv.layout();
  });
}

async function getAccount() {

  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  const account = accounts[0];
  if(account.includes('8dd')){

    kv.launchMasterSettings();

  }
}

var myOffcanvas = document.getElementById('offcanvas')
myOffcanvas.addEventListener('show.bs.offcanvas', function () {
 kv.isOffcanvas = true;
 kv.makeComposite(kv.user, accountIcon);
});

myOffcanvas.addEventListener('hidden.bs.offcanvas', function () {
  setTimeout(function(){
    kv.isOffcanvas = false;
  }, 200)
});

kv.closePromo = function() {
  bootstrap.Offcanvas.getInstance(offcanvas).hide()
}


/* hide and show stuff... */


kv.layout = function() {

  kv.showHide(document.getElementById('videobgtexture'), !kv.showFeed);

  if(kv.showFeed)kv.showHide(kv.toolbar, false);
  const showSideChat = ((innerWidth > kv.width) && !kv.showFeed);
  sideChat.style.display = showSideChat ? 'block' : 'none';
  sideChat.style.width = (innerWidth - kv.width) + 'px';


  speakarea.style.float = showSideChat ? 'right' : 'left';

  if(showSideChat && kv.chatlog){


    if(kv.chatlog.view && kv.chatlog.view.parentNode != sideChat){
      sideChat.appendChild(kv.chatlog.view);
      kv.chatlog.scrollDown();

      

    }
  }

  if(showSideChat){

    videoModalFooter.style.width = sideChat.style.width;
    videoModalFooter.style.right = '0px';

    bottomModal.style.float = 'left';
    bottomModal.style.marginLeft = '25px';

  }else{


    bottomModal.style.margin = 'auto';
    delete bottomModal.style.marginLeft;// = '25px';

    videoModalFooter.style.width = '100%';
    delete videoModalFooter.style.right;
  }

  kv.showHide(chatroomButton, !showSideChat);


  exhibitTitle.style.width = container.offsetWidth+'px';
  exhibitTitle.style.position = 'absolute';
  exhibitTitle.style.left = '0px';
  exhibitTitle.style.top = (container.offsetWidth > 500) ? 0:0 + 'px';


  exhibitTitle.style.pointerEvents = 'none';
  exhibitTitle.style.width = container.offsetWidth+'px';

  const noob = !kv.user?.name;



  if(kv.showFeed || noob){
    //container.style.backgroundColor = '#111';
    container.style.backgroundImage = 'none';
  }

  kv.showHide(videoModalHeader, true && !noob);

  if(kv.x){
      kv.x.visible = !kv.showFeed;
      if(kv.x.visible && kv.kilo)document.getElementById('exhibitTitle').innerHTML = kv.kilo.name;
  }

  //console.log('*** clearing depth ***');

  //this is necessary or the backgrounds dont go away...

  kv.renderer.clearDepth();

  kv.sceneOrtho.visible = !kv.showFeed;


  kv.showHide(settingsButton, !kv.showFeed)
  kv.showHide(exhibitTitle, !kv.showFeed);
  kv.showHide(videoModalFooter, !kv.showFeed, 'd-flex');
  kv.showHide(kilobutton, !kv.showFeed);
  kv.showHide(videoModalHeader, true && !noob);
  kv.showHide(feedFooter, kv.showFeed && !noob);
  kv.showHide(kiloHeading, kv.showFeed && !noob);
  kv.showHide(kv.feed.view, kv.showFeed && !noob);

  updateMuteButton();
  var title;

  //userText.style.maxWidth = kv.width - (192) + 'px';
  accountName.innerHTML = kv.user.name || '';

  //kv.showHide(videoModalFooter, true, 'd-flex');

  //videoModalFooter.style.display = 'block';//stop the weird jumping...
  const ht = document.getElementById('headingTitle');
  if(true) { //kv.kilo.tokenId == 0

    title = 'The Kiloverse';


  }

  headingTitle.innerHTML = title;
  if(kv.height > kv.width){

    ht.style.maxHeight = '30px';
  }


  formText.style.display = 'inline-flex';


kv.wallet.client.getActiveAccount().then(function(a){


  kv.activeAccount = a;
  if(a){

    kv.wallet.getPKH().then(function(r){
      if(r){
          kv.walletAddress = r;

          const last4 = r.slice(-5);
          const first4 = r.slice(0, 5);
          walletButton.innerHTML = (first4 + '.....' + last4);

          tk.tz.getBalance(kv.walletAddress).then((b)=>{
             var bal = Math.round((b/1000000)*100)/100.0;
             myBalance.innerHTML = bal;
          })

          kv.myPlots = [];

          var params =  {walletAddress:kv.walletAddress};
          if(kv.user.id)params.id = kv.user.id;
          kv.api('user', 'POST', params, (r)=>{


            kv.user = r.user;
            bakeCookie('user', kv.user, 1000);

          });

      }
    });
  } else {

    walletButton.innerHTML = 'Connect Wallet';

  }

});


}


kv.createLinks = function(str) {
  let matches = []
  let words = str.split(/\s/)
  let result = []
  for (let i = 0; i < words.length; i++) {
    let word = words[i]
    let len = word.length
    let idx = word.indexOf('.')
    if (idx != -1 && idx != len - 1) {
      result.push(word);
      //result.push(`<a href="${word}" target="blank">${word}</a>`)
    } else {
      //result.push(word)
    }
  }
  return result[0];

  //return result.join(' ')
}

kv.makeId = function(length) {

  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

kv.getChatContext = ()=> {

  let ctx = {};

  let peopleString = '';

  let stops = [];

  let humanCount = 0;

  for(const u of kv.kilo.users){
    peopleString += `${u.name},`
    // u.isBot ? `AI cat named ${u.name},` : ` human named ${u.name},`;
    stops.push(u.name+':');
    if(!u.isBot)humanCount++;
  }

  ctx.alone = humanCount <= 1;


  let chat = ``;

  let elapsed = 0;

  const log = kv.kilo.chatlog || [];
  for (var i = log.length - 1; i >= 0; i--) {
    const line = kv.kilo.chatlog[i];
    if((Date.now()-line.time) > 30000)break;
    if(i == (log.length - 1))elapsed = (Date.now() - line.time);
    let c = `${line.name}: ${line.message}\n`;
    chat = c + chat;
    if(chat.length > 150)break;
  }


  ctx.elapsed = elapsed;
  ctx.stops = stops;
  ctx.people = peopleString;
  ctx.prior = chat;

  return ctx;

}

kv.makeForBot = function(t) {

  if(!t.includes('create'))t = 'Create ' + t;
  var sd = new QuickParse();
  if(sd.process(t))kv.runCommand(sd.ctx);
}

kv.sayit = function() {

  var t = userText.value;
  console.log('userText value is '  + userText.value);

  if(t.includes('\n')){
    console.log('cleaned linebreak...');
    t = t.replace('\n','');
  }

  if(t.length == 0)return;

  userText.value = '';
  if(t == undefined){
    alert('undefined prompt');
    debugger;
  }

  kv.user.text = t;//should this be after the length check?

  kv.lastPrompts.push(t);

  localStorage.lastPrompts = JSON.stringify(kv.lastPrompts);
  kv.promptCount = 0;
  kv.user.xid = kv.kilo.id;

  let p = '';
    if(kv.prompterQueue) {

      p = kv.prompterQueue.p;
      kv.prompterQueue = null;
    }



  var sd = new QuickParse();
  let isCommand = sd.process(p + t);
  if(isCommand)kv.runCommand(sd.ctx);


  var link = kv.createLinks(t);

  if(link && link.includes('youtu')){


    kv.api('add', 'POST', {userId:kv.user.id, url:link, xid:kv.kilo.id}, (r)=>{
    //  kv.addToKilo(r);
    });

    kv.user.text = kv.user.text.replace(link, '');

    if(kv.user.text.trim().length === 0){
      return;
    }

  }

  if(kv.iOS()){
    kv.user.mode = 'none';
    kv.layout();
  }
  var userData = {...kv.user};

  var bots = kv.kilo.users.filter((u)=>{return u.isBot});

  userData.botData = null;

  let ctx = kv.getChatContext();

  let isBoring = (ctx.prior > 20 && t.length < 10 && !t.includes('?'));

  if(isBoring){
    console.log('NOT ENGAGING AI because boring comment...');
  }

  for (const bot of bots) {

    ctx.prior += `\n${kv.user.name}:${t}\n`;
    //debugger;

    if(!isBoring && !isCommand && (ctx.alone || ctx.prior.toLowerCase().includes(bot.name.toLowerCase()))){
        userData.botData = bot;
        Object.assign(userData.botData, ctx);
    }
  }

  if(kv.describeQueue) {

  }

  const yeses = ['yes','ok','sure', 'allright', 'yep', 'k'];

  if(kv.ideaQueue && yeses.includes(t.toLowerCase())) {

  //  alert('accepting id queue with human id ' + kv.ideaQueue.humanId);

    kv.api('acceptidea', 'POST', kv.ideaQueue, (r)=>{

        var sd = new QuickParse();
        if(sd.process(r.prompt))kv.runCommand(sd.ctx);

    });
    kv.ideaQueue = null;
    return;

  }


//  userData.isCommand = isCommand

  kv.api('speak','POST', userData, function(r) {
    userText.focus();
  });

}

  if(localStorage.lastPrompts){

    try {
        kv.lastPrompts = JSON.parse(localStorage.lastPrompts);

    }catch(e){
      alert(e);
    }
  }


  if(!Array.isArray(kv.lastPrompts)){
    kv.lastPrompts = [];
  }

  kv.promptCount = 0;


document.addEventListener('bigimageclick', ()=>{
  //debugger;
  //kv.x.art.shrink();
  //kv.destroyEnlargeComponent();

});
/*
kv.launchModalComponent = function(t) {



  kv.lastEnlargeTime = Date.now();

  //return;

  if(!t || !t.type) {
  //  debugger;
    console.log('XXXX TYPE MISSING ' + t.type + ' t:' + JSON.stringify(t));
    return;
  }

  if(kv.enlargement){
    kv.destroyEnlargeComponent();
  }

  const component = kv.ed.registerComponent(t);
  kv.enlargement = new Enlargement(component);



  console.log('enlarging ' + t.id);

  kv.enlargement.data = t;
  kv.sceneOrtho.add(kv.enlargement.view);
  kv.enlargement.position();
  kv.update2d();

  return component;

}
*/

kv.runKiloprompter = (command) => {

    var bot = kv.kilo.users.find((u)=>{return u.isBot});

    let d = {xid:kv.kilo.id, humanId:kv.user.id, situation:kv.kilo.name, what:command};
    if(bot)d.botData = bot;

    kv.dismissViewController();
    kv.api('kiloprompter', 'POST', d, (r)=>{

      if(!r.botData){
        userText.innerHTML = r.p;

      }else{
        kv.prompterQueue = r;
      }

    });
}

kv.secretSpeak = function(btn) {

  kv.kilo.users.forEach((u, i) => {
    if(u.id == btn.userId){

      u.text = secretText.value;
      kv.api('speak','POST', u, function(r) {

      });
    }
  });

}

userText.ondrop = function(e) {
     e.preventDefault();
     alert('let the beat drooooop');

 }


kv.particles = {};



var ducker;
var stopDuck;

var duck = function(time) {
    var duckUntil = new Date().getTime() + time;
    if(duckUntil > (stopDuck || 0)){


      if(ducker)clearTimeout(ducker);

      videoPlayer.volume = 0.2222;
      stopDuck = duckUntil;
      console.log('ducked voume to 0');

      ducker = setTimeout(function(){
        videoPlayer.volume = 1.0;

      }, time);
    }
}


kv.updateExhibit = function(data) {

    kv.loadKilo(data.id);

    kv.dismissViewController();

}

kv.updateUser = function(userParams) {

  //if(kv.headless)return;

  kv.api('user','POST', userParams, function(r) {
    //if(r.sessionId)kv.kilo.sessionId = r.sessionId;
    if(!kv.user || (kv.user.id == userParams.id)) {
      kv.user = r.user;
      bakeCookie('user', kv.user, 1000);
    }

    kv.layout();
  });

}

function panner(v) {



  kv.pan = (v *  kv.width * .05);

}

function zoomer(v) {

  kv.zoom = v;
  if(kv.x){
    kv.x.zoomArt(v);
  }

}


kv.pulse = function(triggerData) {

    const mult = 50;//data.emoji ? 1.0 : 100;

    const ps = kv.x.art.children.filter((c)=>c.userData.isFun);
    var test = 0;
    var instCount = Object.values(kv.samplers).length;
    var tid = (triggerData.id % instCount);// / kv.instruments.length;
    var idx = 0;


    for(const p of ps){

      if(kv.audioTracks[Math.floor(p.userData.random*kv.audioTracks.length)].id ==  triggerTrack.id){

          gsap.to(p?.material,  {opacity:0, delay:.7, duration:.05,  ease: "power2.in", onComplete: function(){
              p?.scale.set(1*mult,1*mult,1*mult);
          }});
          gsap.to(p?.material, {opacity:1, delay:.9, duration:.05});
          test++;

      }

      idx++;

    }

}

kv.iOS = function() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
};





function loadTouchStuff() {

  kv.isTouch = 'ontouchstart' in document.documentElement;

  if(!kv.isTouch){

    document.getElementById('kilobody').addEventListener("mouseover", function() {
       //videoModalHeader.style.visible = 'visible';
       upperLeft.style.visible = 'visible';
       upperRight.style.visible = 'visible';
       //videoModalFooter.style.visible = 'visible';

    });

    document.getElementById('kilobody').addEventListener("mouseleave", function() {
      //hover
       //videoModalHeader.style.visible = 'hidden';
       upperLeft.style.visible = 'hidden';
       upperRight.style.visible = 'hidden';

       //videoModalFooter.style.visible = 'hidden';

     });

  }

}



var pointerDownTime;



kv.showStars = false;



document.addEventListener('touchmove', function(e){

    if(!kv.showFeed &&
      e.target.closest('#chatroom') == null &&
      e.target.closest('.modal') == null &&
      e.target.closest('.offcanvas') == null &&
      e.target.tagName != 'TEXTAREA' &&
      e.target.tagName != 'INPUT')e.preventDefault();
    //if(!kv.enlargement && (!e.target.tagName != 'TEXTAREA'))
}, { passive: false });


kv.shouldBlock = function(event) {
  let title = (event.target.id == "exhibitTitle");
  let canvas =  event.target.tagName == 'CANVAS';
  let ta = event.target.tagName == 'TEXTAREA';
  let but = event.target.closest('BUTTON'); // (1)
  let modal = event.target.closest('.modal');
  let i = event.target.closest('I'); // (1)
  let enlargement = event.target.closest('.enlargement');
  let offcanvas = event.target.closest('.offcanvas');
  if(offcanvas)return true;
  if(enlargement)return true;
  if(ta)return true;
  if(but)return true;
  if(modal)return true;
  if(i)return true;
  if(title)return true;
  if(event.target.tagName == 'HTML')return true;
  return false;
}

addEventListener('pointerdown', e => {

  //console.log('pointerdown...');
  if(kv.shouldBlock(e))return;



  const mouseX = (event.clientX / kv.width) * 2 - 1;
  const mouseY = - (event.clientY / kv.height) * 2 + 1;
  raycaster3D.setFromCamera(new THREE.Vector2(mouseX, mouseY), kv.camera);

  if(kv.sequencer)return;

  pointerDownTime = Date.now();

  kv.startX = e.clientX / kv.width;
  kv.startY = e.clientY / kv.height;

  //console.log('pointer down at ' + kv.startX);

  //dont pan carousel if art is touched...
  //if(kv.x.art){
  //  let intersectedArt = raycaster3D.intersectObjects(kv.x.art.children, true);
  //  if(intersectedArt.length > 0)return;
//  }
  //const isGlow =  (intersectedArt.length == 1 && intersectedArt[0].object.userData.isBacklight);



  kv.mouseDown = true;;


}, { passive: false });

addEventListener('pointercancel', e=> {
  //console.log('pointercancel!');
    kv.mouseDrag = false;
    kv.mouseDown = false;

});

window.addEventListener("contextmenu", function(e) {
  //alert('context for ')
  e.preventDefault();

})


addEventListener('pointerup', e => {


  //console.log('pointerup');
  if(kv.shouldBlock(e)){
    return;
  }

  if(e.target.classList.contains('bigimage')){
    kv.destroyEnlargeComponent();
  }


  kv.mouseDrag = false;
  kv.mouseDown = false;


  const mouseX = (event.clientX / kv.width) * 2 - 1;
  const mouseY = - (event.clientY / kv.height) * 2 + 1;
  raycaster3D.setFromCamera(new THREE.Vector2(mouseX, mouseY), kv.camera);

  if(kv.sequencer)return true;

  const d = Date.now();
  var dragtime = (d - pointerDownTime);
  if(dragtime > 500){
    //console.log('returning cause not a click');
    return;
  }

  if(e.target.id == 'artImage')return;

  if(kv.showFeed){
    if(e.target.closest('.cardboard')){
      const xid = e.target.closest('.cardboard').dataset.xid;

      kv.loadKilo(xid);
    }

    return;
  }


  const hit = raycast(e, mouseX, mouseY);


}, { passive: false });

kv.debounce = function(callback, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}


addEventListener('pointermove', e => {

  kv.mouseX = e.clientX / kv.width;
  kv.mouseY = e.clientY / kv.height;



  if(kv.shouldBlock(e))return;
  //console.log('pointermove');

  if(kv.sequencer)return;
  //kv.raycastSequencerMove(startY-mouseY);

  //draging on buttons shouldnt movoe the stars
  if(e.target.classList.contains('btn'))return;

  if(kv.mouseDown){
    kv.mouseDrag = true;
  }
//  e.preventDefault();

},{ passive: false });



function startKiloverse() {

    init();
    animate();

}


function easeOut(x) {
  return 1 - Math.pow(1 - x, 3);
}


function parseYoutube(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}



function isVideo(object){
  return (object.userData.item.type == 'video' || object.userData.item.type == 'screenshare');
}

kv.share = function() {


  if (navigator.canShare) {
  navigator.share({
    title: 'Lee Martin',
    text: 'Netmaker. Playing the Internet in your favorite band for nearly two decades.',
    url: 'https://leemartin.dev'
  })
  .then(() => console.log('Share was successful.'))
  .catch((error) => console.log('Sharing failed', error));
  }

}


kv.autoMode = true;


kv.getStickerImage = function(sticker) {

  const spark = require('./'+sticker+'.png');
  return spark;

}

kv.blankImage = require('./1px.png');
kv.spacing = 250;


kv.test = function() {

  for(const e of document.body.children){
    console.log('iw:' + e.offsetWidth);

  }
}


function init() {

  kv.kilojunk = [];


  window.THREE = THREE;
  THREE.Cache.enabled = true;

  window.CSS2DObject = CSS2DObject;


  const tb = document.createElement('div');
  tb.className = 'toolbar d-none';
  tb.style = 'display:inline-flex;z-index:999;position:absolute;background:transparent;';
  tb.id = 'xxx';
  document.body.appendChild(tb);
  kv.toolbar = tb;




  kv.camera = new THREE.PerspectiveCamera( );//wtf, aspect, 1, 100000

  kv.cameraOrtho = new THREE.OrthographicCamera();

   kv.sceneOrtho = new THREE.Scene();

   kv.renderer = new THREE.WebGLRenderer();//{ alpha: true,antialias: false}
   kv.renderer.autoClear = false;
   kv.renderer.domElement.id = 'canvasgl';

   //renderer.setClearColor( 0xffffff ); // white background - replace ffffff with any hex color

       kv.renderer2D = new CSS2DRenderer();
   		kv.renderer2D.domElement.style.position = 'absolute';
   		kv.renderer2D.domElement.style.top = '0px';
       kv.renderer2D.domElement.style.pointerEvents = 'none';
       //make chat bubbles float in front of editor lol
       kv.renderer2D.domElement.style.zIndex = 999999;
   		document.body.appendChild(kv.renderer2D.domElement);


  kv.feed = new Feed();

  //resize();


  if(!kv.listener){
    kv.listener = new THREE.AudioListener();
    kv.camera.add(kv.listener);
  }


  if(!kv.audioLoader)kv.audioLoader = new THREE.AudioLoader();

  THREE.AudioContext.getContext().onstatechange = () => {
    kv.audiolocked = THREE.AudioContext.getContext().state == 'suspended';
    console.log('state changed to '+ THREE.AudioContext.getContext().state);
    updateMuteButton();
  };


  kv.scene = new THREE.Scene();
  //kv.scene.background = new THREE.Color(0x000000);

  kv.initGif();

  window.scene = kv.scene;


  kv.tone = Tone;
  window.Tone = Tone;


  kv.ed = new Controller();



  raycaster3D = new THREE.Raycaster();
  raycaster3D.params.Points.threshold = 0.0;

  kv.cameraOrtho.position.z = 30;



  kv.camera.position.x = 0;
  kv.camera.position.y = 0;//1100;
  kv.camera.position.z = 0;//-1000;

  kv.adjustCameras();



  const container = document.getElementById( 'container' );

  while (container.firstChild) {
    container.removeChild(container.firstChild);
  }

  container.appendChild(kv.renderer.domElement);
  container.appendChild(kilobutton);

  //resize();
  window.addEventListener('resize', resize );

}



function hideKeyboard () {
    document.activeElement.blur();
    Array.prototype.forEach.call(document.querySelectorAll('input, textarea'), function(it) {
        it.blur();
    });
}

kv.adjustCameras = function() {

  kv.width = innerWidth;
  kv.height = innerHeight;
  //this prohibits landscape mode... very hard call ...
  let maxAspectRatio = 1.0;//1.25
  if(innerWidth > (innerHeight * maxAspectRatio)){
    kv.width =  Math.min(innerWidth-300, innerHeight*maxAspectRatio);
  }

  if(innerHeight > innerWidth){
    kv.height = innerHeight - 66;
  }



  if(kv.showFeed){

    kv.width = 300;
    kv.height = 300;

    kv.feed.position();
    kv.feed.layout();
  }


  kv.camera.near = 1;
  kv.camera.far = 1000;
  kv.camera.aspect = kv.width / kv.height;
  var horizontalFov = 90;
  kv.camera.fov = (Math.atan(Math.tan(((horizontalFov / 2) * Math.PI) / 180) / kv.camera.aspect) * 2 * 180) / Math.PI;

  if(!kv.frustum) {
    kv.frustum = new THREE.Frustum();
  }

  kv.camera.updateProjectionMatrix();
  kv.renderer.setSize( kv.width, kv.height );
  kv.renderer2D.setSize( kv.width, kv.height );

  kv.renderer.setPixelRatio( window.devicePixelRatio );


  kv.cameraOrtho.left = - kv.width / 2;
  kv.cameraOrtho.right = kv.width / 2;
  kv.cameraOrtho.top = kv.height / 2;
  kv.cameraOrtho.bottom = - kv.height / 2;
  kv.cameraOrtho.near = -1000;
  kv.cameraOrtho.far = 1000;
  kv.cameraOrtho.updateProjectionMatrix();

  if(kv.enlargement)kv.enlargement.position();



}

function resize() {

  kv.adjustCameras();
  kv.layout();

  if(kv.x)kv.x.layout();

}


function animate() {

  //kv.tick();
  requestAnimationFrame( animate );

  if (kv.mouseDrag){//kiloTouch.down

    if(!kv.mouseX){
      console.log('mouse is down with no mousex value wtf');
      return;
    }
    var ydiff = (kv.mouseY - kv.startY);
    var deltay = Math.max(Math.min(ydiff, 1.0),-1.0)* -1;

    var xdiff = (kv.mouseX - kv.startX);
    var deltax = Math.max(Math.min(xdiff, 1.0),-1.0)* -1;


    if(isNaN(deltax)){
      debugger;
    }

    if(deltay)zoomer(deltay)
    panner(deltax);
    //console.log('xdiff' + xdiff);
  }else{
    kv.pan = 0;

  }

  render();

}

kv.clock = new THREE.Clock;

kv.c = 0;

function render() {
  kv.c++;
  const time = Date.now() * 0.003;

  if(!kv.showFeed)THREE.ComposedTexture?.update(kv.clock.getDelta() );





  if(kv.x && kv.x.visible){
      var d =  1.0 / ((300.0/kv.width) / (kv.pw * .5));// * .5;
      kv.z = d;
      if(kv.x.bob)kv.x.bob.render();
      if(kv.x.art)kv.x.art.render();
      if(kv.x.people)kv.x.people.render();
  }

  const rotationSpeed = .5;


  if(!kv.showFeed){
    kv.renderer.render(kv.scene, kv.camera);
  //  kv.renderer2D.render(kv.sceneOrtho, kv.cameraOrtho);
  }

  if(kv.showFeed){
    for(const x of kv.feed.exhibits) {
      //if(x.visible && x.art)x.art.rotation.y -= ((-0.005 * rotationSpeed))*.1;
      if(x.visible && x.bob)x.bob.render();
    }

    kv.feed.render(time);
  }



}


function updateMuteButton () {
  const isMuted = THREE.AudioContext.getContext().state == 'suspended';
  svgMuteOn.style.display = !isMuted ? 'none' : 'block';
  svgMuteOff.style.display = isMuted ? 'none' : 'block';
}


kv.toggleMute = function () {

  const isMuted = THREE.AudioContext.getContext().state == 'suspended';

  if(isMuted){
    THREE.AudioContext.getContext().resume();
    //Tone.start();
  }else{
    THREE.AudioContext.getContext().suspend();
  }

}


kv.playAudio = function(file, music) {

  const sound = new THREE.Audio(kv.listener );
  sound.addEventListener("error", function(e) {
               alert("playback error: " + e);
  });

  if(music){
    if(kv.musicSound && kv.musicSound.source)kv.musicSound.stop();
    kv.musicSound = sound;
  }

  window.THREE = THREE;


  kv.audiolocked = THREE.AudioContext.getContext().state == 'suspended';
  kv.latestAudioFile = file;
  kv.audioLoader.load(file, function( buffer ) {

    if(kv.musicSound && kv.musicSound.source)kv.musicSound.stop();//grasping at straws...
        sound.setBuffer( buffer );
        sound.setLoop( false );
        sound.setVolume( 0.5 );
        if(file == kv.latestAudioFile) {

            sound.play();
            if(sound.context.state == 'suspended'){
              //show audio muted
              alert('Please unmute audio to hear audio.');
            }

            //Tone.start()
        }

      }, null, function(error){

    });

}


addEventListener("dragover", (event) => {
  event.preventDefault();
});

async function doStuffWithFile(file) {
  const itemId = await kv.submitFile(file, {type:'image'});
  const t = {id:itemId, type:'aiimage', url:'https://api.kiloverse.io/' + itemId + '_square.png'};
  const comp = kv.launchModal(t);
  comp.setPaintMode();//this gives the uploader opporunity to retouch etc...
  //kv.launchModalComponent(t);
}

addEventListener("drop",  (ev) => {
  event.preventDefault();
  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    [...ev.dataTransfer.items].forEach(async (item, i) => {
      // If dropped items aren't files, reject them
      if (item.kind === 'file') {
        const file = item.getAsFile();
        doStuffWithFile(file);
      }
    });
  }
});

document.getElementById("imageUploadButton").onchange = function(ev) {
      doStuffWithFile(ev.target.files[0]);
};



kv.isEd = function(){
  return kv.walletAddress == 'tz1fj3ymeFEVJNEvPKm8BTHxXzXjjfJTkgzu' || kv.walletAddress == 'tz2XB8Nm9oKHPmsHypamqR25Y1RyAkiZMfPc';
}

const v  = '[{"languageCodes":["en-US"],"name":"en-US-Wavenet-A","ssmlGender":"MALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-B","ssmlGender":"MALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-C","ssmlGender":"FEMALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-D","ssmlGender":"MALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-E","ssmlGender":"FEMALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-F","ssmlGender":"FEMALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-G","ssmlGender":"FEMALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-H","ssmlGender":"FEMALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-I","ssmlGender":"MALE","naturalSampleRateHertz":24000},{"languageCodes":["en-US"],"name":"en-US-Wavenet-J","ssmlGender":"MALE","naturalSampleRateHertz":24000}]';

kv.voices = JSON.parse(v);


/*refactor asap */
document.addEventListener('DOMContentLoaded', () => {

  loadStuff();


  if(!kv.headless)kv.user = readCookie('user');

  walletButton.addEventListener('click', async function() {

      const activeAccount = await kv.wallet.client.getActiveAccount();

      if(activeAccount){
        kv.wallet.clearActiveAccount().then(function(){
          alert('logging out');

          delete kv.user.walletAddress;

          kv.layout();
        });
        return;
      }


    kv.connectWallet().then(function(){



      kv.wallet.getPKH().then(function(a){

        kv.layout();
        if(!kv.user && kv.user.id){
          alert('could not connect wallet, please refresh');
        }
        kv.updateUser({id:kv.user.id, walletAddress:a});

      });
    });

  });

  createExhibitButton.addEventListener('click', async function(){
    kv.launchXEditor();
  });

  kv.toolbar.addEventListener('click', function(e){


    const d = e.target.closest('.toolbar');

    const b = e.target.closest('button');
    let command =  b.classList[b.classList.length-1];

    if(command == 'deleteitem'){

      if(window.confirm('Remove this piece from exhibit?')){
        kv.x.art.shrink();
        kv.showHide(kv.toolbar, false);
        kv.api2('saveitem','POST', {id:d.dataset.id, xid:'delete'});
      }

    }

    if(command == 'edititem'){

        kv.launchModal(kv.x.art.enlargedSlot.userData.data);

    }

  });


  chatroomButton.addEventListener('click', function(){
    kv.showRightPane(kv.chatlog.view);
    kv.chatlog.scrollDown();
  });

  document.querySelectorAll('.avatarStyle').forEach((item, i) => {
    item.addEventListener('click', el => {
      if(el.target.id == 'humanAvatar'){
        wstep = 'selfieRow';
        wizardStep();
      }
      if(el.target.id == 'catAvatar'){
        wstep = 'avatarRow';
        wizardStep();
      }
    });
  });
  homeButton.addEventListener('click', function(){

    if(kv.enlargement){
      kv.destroyEnlargeComponent();//probably too harsh?
    }

    //debugger;

    if(kv.unsubscribeExhibit)kv.unsubscribeExhibit();
    if(kv.unsubscribeItems)kv.unsubscribeItems();


    if(kv.x){
      kv.x.visible = false;
      kv.x.destroy();
      kv.x.parent.remove(kv.x);
      kv.x = null;
    }


    kv.layout();

    


    kv.launchFeed();



  });

  addEventListener('dispose', (e)=>{
    const c = e.detail.component;
    if(c == kv.rightPaneComponent)kv.destroyRightPaneComponent();
  });


  document.addEventListener('focusout', function(e) {
    if(kv.iOS())kv.sayit();
  });

  userText.addEventListener("keydown", function(event) {

  if (event.key === "Enter") {
    hideKeyboard();
    event.preventDefault();
    kv.sayit();
    return;
    // Trigger the button element with a click

  }

//&& e.key == 'ArrowUp'
//!e.shiftKey && e.key == 'ArrowUp'
var go = false;
  if(!event.shiftKey && event.key == 'ArrowUp'){
    kv.promptCount++;
    go = true;
  }else if(!event.shiftKey && event.key == 'ArrowDown'){
    kv.promptCount--;
    go = true;
  }

if(go){
  if(kv.promptCount > kv.lastPrompts.length){
    kv.promptCount = kv.lastPrompts.length;
  }else if(kv.promptCount < 0){
    kv.promptCount = 0;
  }else{
    userText.value =  kv.lastPrompts[kv.lastPrompts.length - kv.promptCount];
  }
}

  // If the user presses the "Enter" key on the keyboard

});

  speakButton.addEventListener('click', function(){

    kv.sayit();

  });

  muteButton.addEventListener('click', function(){
    kv.toggleMute();
  });

  settingsButton.addEventListener('click', function(e){


    kv.launchXEditor(kv.kilo);
    return;
    kv.wallet.client.getActiveAccount().then(function(a){
      if(a){
        kv.wallet.getPKH().then(function(r){
          if(r){
              kv.walletAddress = r;
              const last4 = r.slice(-5);
              const first4 = r.slice(0, 5);
              walletButton.innerHTML = (first4 + '.....' + last4);


              if(kv.isEd())kv.launchPlotSettings(kv.kilo.id);
              //showConnectButton(false);
          }else{
            console.log('failed sanity check....  active account but no address....')
            //showConnectButton(true);
          }
        });
      } else {
        //showConnectButton(true);
      }

    });
  });
});



kv.kiloButtonPressed = ()=> {
  kv.launchModal({type:'kiloprompt'});
}

kv.launchXEditor = function(data) {
  if(!data)data = {};
  data.type = 'exhibit';
  kv.launchModal(data);
}

kv.launchUserSettings = function(user, simple) {

  if(!user){
    alert('missing user');
    return;
  }

  user.type = 'useredit';
  user.simple = simple;

  kv.userEdit = kv.launchModal(user);

  //userEdit;

  let savecallback;
  if(!simple)savecallback = ()=> {
    userEdit.saveUser();
    kv.dismissViewController();
  }

  //kv.presentViewController(userEdit, 'back', ()=>{kv.popViewController}, 'save', savecallback);

}

kv.popViewController = ()=> {
  kv.presentViewController( kv.presentingComponent);
}

kv.launchUserProfile = function(user) {

  if(!user){
    alert('missing user');
    debugger;
  }

  //const userProfile = new UserProfile(user);
  user.type = 'user';
  kv.launchModal(user);

}

kv.launchModal = function(t, back) {
  

  if(!t.type){
    debugger;
    return;
  }


  const component = kv.ed.registerComponent(t);


  kv.presentViewController(component, back ? 'back':'x', null, 'save', ()=>{
    component.save();
    if(kv.x.art)kv.x.art.shrink();
    kv.dismissViewController();
  });

  return component;

}


  kv.runCommand = function(ctx) {

    const command = ctx.command;

    if(command == 'edit'){
      if(!ctx.target){
        kv.launchXEditor(kv.kilo);
        return;
      }
    }

    if(command == 'add'){

      //debugger;
      const t = {id:kv.makeId(20), ...ctx};
      const c = kv.launchModal(t);
      c.generate();


    }

  }

  kv.destroyEnlargeComponent = function() {
  if(!kv.enlargement)return;
  kv.enlargement.dispose();
  if(kv.enlargement){
    kv.sceneOrtho.remove(kv.enlargement);
  }

  kv.ed.unregisterComponent(kv.enlargement.component);
  kv.update2d();

}



  kv.destroyRightPaneComponent = function() {
    if(kv.rightOC)kv.rightOC.hide();
    if(kv.rightPaneComponent)kv.ed.unregisterComponent(kv.rightPaneComponent);
  }


kv.dismissViewController = function() {
  if(kv.modal.dispose)kv.modal.dispose();
  kv.ed.unregisterComponent(kv.modal);
  if(kv.bottomOC)kv.bottomOC.hide();
}


kv.presentViewController = function(component, leftText='x', leftHandler, rightText, rightHandler) {


  if(leftText)bottomModalLeftButton.innerHTML = leftText;
  if(rightText)bottomModalRightButton.innerHTML = rightText;

  if(leftText == 'back'){
    leftHandler=()=>{kv.popViewController()}
  }
  if(leftText=='x'){
    bottomModalLeftButton.innerHTML = '';
    bottomModalLeftButton.classList.add('btn-close', 'btn-close-white');
    leftHandler=()=>{kv.dismissViewController()}
  }else{
    bottomModalLeftButton.classList.remove('btn-close', 'btn-close-white');
  }

  if(rightText=='x'){
    rightHandler=()=>{kv.dismissViewController()}
    bottomModalRightButton.innerHTML = '';
    bottomModalRightButton.classList.add('btn-close', 'btn-close-white');
  }else{
    bottomModalRightButton.classList.remove('btn-close', 'btn-close-white');
  }

  kv.showHide(bottomModalRightButton, rightHandler);
  kv.showHide(bottomModalLeftButton, leftHandler);
  kv.bottomModalRightClick = rightHandler;
  kv.bottomModalLeftClick = leftHandler;

  if(kv.bottomOC)kv.bottomOC.hide();
  if(kv.modal)kv.presentingComponent = kv.modal;
  kv.modal = component;

  let myOffcanvas = document.getElementById('bottomModal');
  myOffcanvas.style.color = 'white';

  myOffcanvas.style.height =  window.innerHeight;
  
  
  //innerHeight - 10 + 'px';

  //myOffcanvas.style.paddingBottom = '300px';
  //background-color:#000;color:white;


  kv.bottomOC = bootstrap.Offcanvas.getOrCreateInstance(myOffcanvas, {});
  bottomModalBody.innerHTML = '';
  bottomModalBody.appendChild(component.view);

  myOffcanvas.addEventListener('shown.bs.offcanvas', function () {
    component.layout();
    kv.bottomOC._focustrap.deactivate();
  });

  kv.bottomOC.show();

}


kv.showRightPane = function(view, component) {

  if(kv.rightOC)kv.rightOC.hide();
  kv.rightPaneComponent = component;

  var myOffcanvas = document.getElementById('right');
  kv.rightOC = bootstrap.Offcanvas.getOrCreateInstance(myOffcanvas);
  chatlog.innerHTML = '';
  chatlog.appendChild(view);

  //exhibitCreatorBody

  myOffcanvas.addEventListener('shown.bs.offcanvas', function () {
    kv.chatlog.update(kv.kilo.chatlog);
      if(component)component.layout();
  });

  kv.rightOC.show();

}

kv.ago = function(date) {
  const ago = (Date.now() - date);
  const m = parseInt(ago/(60000));
  const h = parseInt(ago/(60000*60));
  const d = parseInt(ago/(60000*60 * 24));
  let dtext = ''
  if(m < 60){
    dtext = `${m}m ago`;
  }else if(h < 24){
    dtext = `${h}h ago`;
  }else{
    dtext = `${d}d`;
  }
  return dtext;
}
