import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import * as fx from 'glfx-es6'

class BobRoss extends THREE.Object3D {

  constructor(data, container, camera) {

    super();
    this.isGroup = true;
    this.start_time = Date.now();
    this.mouseX = 0;
    this.mouseY = 0;
    this.data = data;
    this.camera = camera;
    this.z = -1000;
    this.position.z = this.z;
    this.container = container;


    this.createSprites();


  }

  stringToColour(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
  }

  layout(data) {

      let w = Math.max(this.container.offsetWidth, this.container.offsetHeight);


  //  if(this.container.offsetWidth >= this.container.offsetHeight) {
      //this.video.style.width = `${w}px`;
  //  }else{
    //  this.video.style.width = this.video.style.height = `${innerHeight}px`;
  //  }

    //console.log(`set video to ${this.video.style.width} x ${this.video.style.height}`);
    if(data)this.data = data;

    if(data.skyFileName && data.backgroundUrl){
      this.skyUrl = `https://api.kiloverse.io/skies/${data.skyFileName}`;

      if(kv.showFeed){
        this.skyUrl = `https://api.kiloverse.io/assets/${data.skyFileName}.png`;
      }

      this.maskUrl = this.data.backgroundUrl.replace('.png', '_mask.png');
    }

    this.updateTextures();

    this.width = this.visibleWidth(1000);
    this.height = this.visibleHeight(1000);

    this.farWidth = this.visibleWidth(900);
    this.farHeight = this.visibleHeight(900);

    this.w = parseInt(this.container.offsetWidth);
    this.h = parseInt(this.container.offsetHeight);

    this.aspect = this.w / this.h;



    if(this.glowSprite){
      const s = this.width * 2.0;//(this.aspect > 1.0) ? this.height : this.width;
      this.glowSprite.scale.set(s, s);
      this.glowSprite.position.set(0, this.visibleHeight(1000) * this.groundPosition,  1);
      this.glowSprite.visible = (this.data.color && this.data.color.glow);
    }

    if(this.data.backgroundUrl) {
      const s = this.width;//(this.aspect > 1.0) ? this.height : this.width;
      this.foregroundSprite.scale.set(s, s * 1.0);
    }

    /*

    I thought

    */

    if(this.foregroundSprite) {

      this.positionForegroundSprite();
      this.video.style.height = 100 * (-this.groundPosition / .5) + '%';
      // `${this.foregroundSprite.offsetHeight}px`

    }

    if(this.shadowSprite) {
      this.shadowSprite.position.set(0, this.visibleHeight(1000) * this.groundPosition,  1);
      this.shadowSprite.visible = false;
    }


    if(this.skySprite){

      let a = this.video.videoWidth / this.video.videoHeight; // returns the intrinsic height of the video
      this.skySprite.position.set(0,this.visibleHeight(1000) * .5, 1);

      if(!a){
        a = 16.0/9.0;
      }

      this.skySprite.scale.set(a * this.height, this.height);

    }

    this.setColors();

    if(this.data.color){
        if(this.data.color.name){
          console.log('found color ' + this.data.color.name);
          if(this.userData.color != this.data.color){
            this.userData.color = this.data.color;
            //this.tintImage(this.data.color.name, this.data.color.intensity || .4);

            //this.glowSprite.visible = this.data.color.glow ? 'true' : 'false';
            if(this.data.color.glow)this.glowSprite.material.color.set(this.data.color.glow);

          }
        }
    }

  }

  // 2 brc
  // 3 claw
  // 1 hot tod
  // 1 whiskey

  positionForegroundSprite () {

    let ar = this.container.clientWidth / this.container.clientHeight;
    let maxFloorY = -.5;//-.40; // if ar = .6
    let minFloorY = -.5;//-.45; // if ar = 1
    let minAspectRatio = .6;
    let maxAspectRatio = 1.0;
    ar = Math.min(Math.max(ar, minAspectRatio), maxAspectRatio);
    let per = (ar - minAspectRatio) / (maxAspectRatio - minAspectRatio);
    let groundPosition = 0;//maxFloorY - per * (maxFloorY - minFloorY);

    //if(ar < 1)
    groundPosition = -.4;

    if(ar == 1)groundPosition = -.5;

    this.foregroundSprite.position.set(0, this.visibleHeight(1000) * groundPosition,  2);
    this.blackBar.position.set(0, this.visibleHeight(1000) * -.5, 1);
    this.blackBar.scale.y = this.visibleHeight(1000) * .1;
    this.blackBar.scale.x = this.visibleWidth(1000);

    this.glowSprite.position.set(this.foregroundSprite.position.x, this.visibleHeight(1000) * groundPosition, this.foregroundSprite.position.z);

    this.groundPosition = groundPosition;

  }


  hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
  }

  standardizeColor(str){
    var ctx = document.createElement("canvas").getContext("2d");
    ctx.fillStyle = str;
    return this.hexToRgb(ctx.fillStyle);
  }



  tintImage(string, intensity) {

    let c = this.standardizeColor(string);
    let str = intensity || 1.0;

    let r = (c.r / (c.r + c.g + c.b));
    let g = (c.g / (c.r + c.g + c.b));
    let b = (c.b / (c.r + c.g + c.b));

    this.r = [[0,0], [.33, .33 +  (r-.33)*str], [1,1]];
    this.g = [[0,0], [.33, .33 + (g-.33)*str], [1,1]];
    this.b = [[0,0], [.33, .33 + (b-.33)*str], [1,1]];

    let url = this.data.backgroundUrl;
    let element = new Image();
    element.onload = function () {

      if(!kv.fxcanvas)kv.fxcanvas = fx.canvas();
      this.canvas = kv.fxcanvas;
      let texture = kv.fxcanvas.texture(element);
      kv.fxcanvas.draw(texture);
      let red = this.r || [[0,0], [1,1]];
      let green = this.g || [[0,0], [1,1]];
      let blue = this.b ||  [[0,0], [1,1]];

      kv.fxcanvas.curves(red, green, blue);
      kv.fxcanvas.update();
      //this.tinted = canvas.toDataURL("image/png");

      this.tintUrl = kv.fxcanvas.toDataURL("png");
      texture.destroy();

      this.updateTextures();

    }.bind(this);
    element.crossOrigin = "anonymous";
    element.src = url;


  }

  setColors() {
    let c;
    let s = this.data.skyFileName;

    if(s){
      if(s.includes('clouds.mp4'))c = {name:'skyblue', intensity:.5, glow:'#1192FF'};
      if(s.includes('bluered.mp4'))c = {name:'#831d40', intensity:.33, glow:'#831d40'};
      if(s.includes('bluestorm.mp4'))c = {name:'blue', intensity:.33, glow:'blue'};
      if(s.includes('blue.mp4'))c = {name:'blue', intensity:.33, glow:'#5780ae'};
      if(s.includes('greenstorm.mp4'))c = {name:'#ad9123', intensity:.33};
    }

    this.data.color = c;
  }


  visibleHeight(z) {
    // compensate for cameras not positioned at z=0
    const cameraOffset = this.camera.position.z;
    if ( this.z < cameraOffset ) this.z -= cameraOffset;
    else this.z += cameraOffset;
    const vFOV = this.camera.fov * Math.PI / 180;
    return 2 * Math.tan( vFOV / 2 ) * Math.abs( z );
  }

  visibleWidth(z) {
    const height = this.visibleHeight(z);
    return height * this.camera.aspect;
    //debugger;
  }

  destroy() {
    this.container.style.background = 'none';
  }

  updateTextures() {

    let url =  this.tintUrl || this.data.backgroundUrl;


    if(this.skyUrl && this.skyUrl != this.skySprite.userData.skyUrl){
      this.skySprite.userData.skyUrl = this.skyUrl;

        if(this.skyUrl.includes('.png')){
          const tl = new THREE.TextureLoader().load(this.skyUrl, ()=>{ });
          const o = {map:tl,  transparent:false};
          const vmaterial = new THREE.SpriteMaterial(o);
          this.skySprite.material = vmaterial;
        } else {
          //dont ever do this again lol
          //const videotexture = new THREE.VideoTexture(this.video);
          //videotexture.format = THREE.RGBAFormat;
          //this.skySprite.material = new THREE.SpriteMaterial({map:videotexture});
          this.video.src = this.skyUrl;
          this.video.addEventListener( "loadedmetadata", function (e) {

            this.videoWidth = e.target.videoWidth;
            this.videoHeight = e.target.videoHeight;
            //var width = this.videoWidth,
            //height = this.videoHeight;
          }.bind(this), false );

        }
    }


    let updateMask;
    if(this.foregroundSprite.userData.url != url){

      const tl = new THREE.TextureLoader().load(url, ()=>{ });
      const o = {map:tl,  transparent:true};
      const ukwmaterial = new THREE.SpriteMaterial(o);
    //ukwmaterial.sizeAttenuation = false;
      this.foregroundSprite.material = ukwmaterial;
      this.foregroundSprite.userData.url = url;
      this.foregroundSprite.material.needsUpdate = true;
      updateMask = true;
    }

    if((updateMask && this.maskUrl) || (this.maskUrl && this.maskUrl != this.foregroundSprite.userData.maskUrl)){
      this.foregroundSprite.userData.maskUrl = this.maskUrl;
      const maskMap = new THREE.TextureLoader().load(this.maskUrl, ()=>{ });
      this.foregroundSprite.material.alphaMap = maskMap;
      this.foregroundSprite.material.needsUpdate = true;

      const blurMap = new THREE.TextureLoader().load(this.maskUrl.replace('_mask','_blur'), ()=>{ });
      this.shadowSprite.material.alphaMap = blurMap;
      this.shadowSprite.material.color.set('#111');
      this.shadowSprite.position.z = this.foregroundSprite.position.z-1;
      this.shadowSprite.scale.x = this.foregroundSprite.scale.x;
      this.shadowSprite.scale.y = this.foregroundSprite.scale.y;

    }


    if(this.maskUrl){
    const blurMap = new THREE.TextureLoader().load(this.maskUrl.replace('_mask','_blur'), ()=>{ });
    this.shadowSprite.material.alphaMap = blurMap;
    this.shadowSprite.material.color.set('#444');
    this.shadowSprite.position.z = this.foregroundSprite.position.z-1;
    this.shadowSprite.scale.x = this.foregroundSprite.scale.x;
    this.shadowSprite.scale.y = this.foregroundSprite.scale.y;
    }




  }


  createSprites() {


    if(!this.skySprite){

      this.skySprite = new THREE.Sprite();
      this.skySprite.visible = false;//cpu fix
      this.skySprite.center.set(.5,1.0);
      this.add(this.skySprite);

        this.video = document.getElementById('videobgtexture');

        this.video.onloadeddata = function() {
            //debugger;
            this.video.playbackRate = 1.0;
            this.video.play();
        }.bind(this);

    }


    if(!this.glowSprite) {

        this.glowSprite = new THREE.Sprite();
        this.glowSprite.center.set(.5, .5);
        const tl = new THREE.TextureLoader().load('https://api.kiloverse.io/assets/glow.png', ()=>{ });
        const o = {map:tl,  transparent:true};
        const vmaterial = new THREE.SpriteMaterial(o);
        vmaterial.opacity = .222;
        this.glowSprite.material = vmaterial;
        this.glowSprite.alphaMap =  new THREE.TextureLoader().load('https://api.kiloverse.io/assets/halfmask.png', ()=>{ });
        this.add(this.glowSprite);
        this.glowSprite.visible = false;

    }

      if(!this.foregroundSprite){

        this.foregroundSprite = new THREE.Sprite();
        this.foregroundSprite.center.set(.5, 0);
        this.add(this.foregroundSprite);
      }

      if(!this.blackBar) {
        this.blackBar = new THREE.Sprite();
        this.blackBar.center.set(.5, 0);
        let url = require('./1pxwhite.png');
        const tl = new THREE.TextureLoader().load(url, ()=>{ });
        const o = {map:tl,  transparent:false}//, color:'#CCCCCC'};
        const ukwmaterial = new THREE.SpriteMaterial(o);
        ukwmaterial.color.set('#000');

      //ukwmaterial.sizeAttenuation = false;
        this.blackBar.material = ukwmaterial;

        this.add(this.blackBar);
      }

      if(!this.shadowSprite){

        this.shadowSprite = new THREE.Sprite();
        this.shadowSprite.center.set(.5, 0);
        this.add(this.shadowSprite);

      }




  }


  render() {

  }


}

export default BobRoss
