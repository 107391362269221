import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import canvasTxt from 'canvas-txt'

class Art extends THREE.Object3D {

  constructor() {
    super();
    this.isGroup = true;
    this.radius = 2500;
  }

  destroy() {

  }

  render() {



  }

  loadGif = function(gifUrl, cb) {

    const GIFLoader = (function( url, complete ) {

      const fileLoader = new THREE.FileLoader;

        fileLoader.responseType = 'arraybuffer';
        fileLoader.load( url, async function( data ) {
    		const gif = new GIF( data );
            const container = {
                downscale: false,	// Canvas needs to be power of 2, by default size is upscaled (false)
                width: gif.raw.lsd.width,
                height: gif.raw.lsd.height,
                frames: gif.decompressFrames( true )
            };
            complete( container );

        });


    });


    GIFLoader(gifUrl, async function( container ) {

  	const ratio = container.width / container.height;
  	const texture = new THREE.ComposedTexture;
  	await texture.assign( container );

  	const spritesheet = await texture.toSheet();
  	const spriteTexture = new THREE.SpriteTexture( spritesheet );
      if(cb)cb(spriteTexture);

    });

  }

  makePlacardTexture(data) {

    if(!this.c)this.c = document.createElement('canvas');
    const c = this.c;
    c.width = 512;
    c.height = 256;
    const ctx = c.getContext('2d')
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, c.width, c.height);
    ctx.fillStyle = '#000000' //red color text
    canvasTxt.font = 'Helvetica';
    canvasTxt.color = 'black'
    canvasTxt.fontSize = 100;
    canvasTxt.vAlign = 'top'
    canvasTxt.align = 'center'
    canvasTxt.drawText(ctx, 'Ed', c.width*.10, c.width*.10, c.width - c.width*.20, c.height - c.width*.2)

    const url = c.toDataURL('image/png');


    return new THREE.TextureLoader().load(url, ()=>{
      //console.log(url);
    });
    //new THREE.CanvasTexture(c);

  }

  emitterPath(p) {

    const x  = this.xpath.xmin +  (this.xpath.xmax - this.xpath.xmin) * p;
    const y  = this.xpath.ymin +  (this.xpath.ymax - this.xpath.ymin) * p;
    const z  = this.xpath.zmin +  (this.xpath.zmax - this.xpath.zmin) * p;



  }


  visibleHeight(z=0) {

    // compensate for cameras not positioned at z=0
   const cameraOffset = kv.camera.position.z;
    if ( this.z < cameraOffset ) this.z -= cameraOffset;
    else this.z += cameraOffset;
    const vFOV = kv.camera.fov * Math.PI / 180;
    return 2 * Math.tan( vFOV / 2 ) * Math.abs( z );
  }

  visibleWidth(z=0) {
    const height = this.visibleHeight(z);
    return height * kv.camera.aspect;
    //debugger;
  }


  layout(data) {

    if(kv.equals(data.items, this.data.items)){
      //console.log('dupe art');
      return;
    }

		this.data = data;
    if(!this.textures)this.textures = {};

    var i = 0;

    this.pieces = this.data.items || [];


    var funs = this.pieces.filter(t=>t.type == 'emoji' || t.type == 'gif');
    var gifs = this.pieces.filter(t=>t.type == 'gif');
    var texts = this.pieces.filter(t=>t.type == 'text');
    var images = this.pieces.filter(t=>t.type == 'aiimage');

    var images = [...images, ...texts];

    var funCount = funs.length;
    if(funCount < 300) {
      var repeatfun  = Math.ceil(300 / funs.length);
      funCount = funs.length * repeatfun;
    }

    var imageCount = images.length;
    if(imageCount < 50) {
      var repeatImage  = Math.ceil(50 / images.length);
      imageCount = images.length * repeatImage;
    }

    imageCount = 20;

    this.remove(...this.children);

/*
    const light = new THREE.PointLight( 0xff0000, 1, 100 );
    light.position.set( 500, 0, 0 );
    kv.scene.add( light );
*/


  this.radius = this.visibleWidth(1000) * .5;//this.visibleHeight(1000) * .5;
  this.position.x = this.visibleWidth(1000) * .5 - 100;// + this.radius;
  this.position.y = 0.0;this.visibleHeight(1000) * .125;
  this.rotation.x = Math.PI / 8 ;

    //imageCount = 10
    for(var i = 0; i < imageCount; i++){

      const data = images[i%images.length];

      var ip = new THREE.Group();

    //  ip.geometry = new THREE.PlaneGeometry( 1, 1 );
    //  ip.material = new THREE.MeshBasicMaterial({color:'pink',side: THREE.DoubleSide})

      this.add(ip);

      ip.userData.data = data;
      ip.userData.isFun = false;

      const url = ip.userData.data.url;

      const turl = url;

      if(url && this.thumbSize){
        let fn = this.filenameFromPath(url);
        turl = 'https://api.kiloverse.io/thumb/'+fn+'/' + this.thumbSize;
      }

      if(url && !this.textures[turl]){
        this.textures[turl] =  new THREE.TextureLoader().load(turl, ()=>{});
      }

      const a = (i/imageCount) * 2.0 * Math.PI;
      var r =  this.radius;
      ip.position.y = 0;
      ip.position.x = r * Math.sin(a);
      ip.position.z = r * Math.cos(a);
      ip.rotation.y = a + Math.PI;
      var c = (2 * Math.PI * r)/imageCount * .3;//fix this with trig?


      const frame = new THREE.Sprite();
      frame.userData.data = data;
      frame.userData.isFun = false;
      frame.geometry = new THREE.PlaneGeometry( 1, 1);
      const fo = {color:'white', transparent:false, side:THREE.DoubleSide};
      //const framematerial = new THREE.MeshBasicMaterial(fo);
      const framematerial = new THREE.SpriteMaterial(fo);
      frame.material = framematerial;
      frame.rotation.set(0.0, 0.0, 0.0);
      frame.scale.set(c, c, 1.0);
      frame.position.set(0.0, 0.0, -.001);
      ip.add(frame);


      const art = new THREE.Sprite();
      art.userData.data = data;
      art.userData.isFun = false;
      art.geometry = new THREE.PlaneGeometry( 1, 1);
      const o = {map:this.textures[turl], transparent:false, side:THREE.DoubleSide};
      //const artmaterial = new THREE.MeshBasicMaterial(o);
      const artmaterial = new THREE.SpriteMaterial(o);
      art.material = artmaterial;
      art.rotation.set(0.0, 0.0, 0.0);
      art.scale.set(c, c, 1.0);
      art.position.set(0.0, 0.0, 0.0);
      ip.add(art);

/*
      const shadowgeometry = new THREE.PlaneGeometry( 1, 1 );
      const shadowmaterial = new THREE.ShadowMaterial({color:'red'});
      shadowmaterial.opacity = 1.0;
      const shad = new THREE.Mesh( shadowgeometry, shadowmaterial );
      shad.scale.set(c+20, c+20, 1.0);
      shad.position.set(0.0, 0.0, -5.0);
      shad.receiveShadow = true;
      ip.add(shad);

*/

      this.pw = c;

/*
      const placard = new THREE.Mesh();
      placard.userData.data = data;
      placard.userData.isFun = false;
      placard.geometry = new THREE.PlaneGeometry( 1, 1 );
      const po = {map:this.makePlacardTexture(data), transparent:false, side:THREE.DoubleSide};
      const placardMaterial = new THREE.MeshBasicMaterial(po);
      placard.material = placardMaterial;

      placard.scale.set(c*.25, c*.125);
      //placard.center.set(.5,.5);
      placard.position.set(c*.4, -c * .6, 1.0);

      ip.add(placard);
*/


    }

    for(var i = 0; i < funCount; i++){

      const data = funs[i%funs.length];

      var ip = new THREE.Sprite();
      //funSprites.push(ip);
      ip.userData.data = data;

      ip.userData.isFun = true;
      ip.userData.random = Math.random();

      this.add(ip);

      var funtexture;

      const o = {transparent:false, rotation: -1.0 + 2.0 * Math.random()};

      if(this.textures[data.id])o.map = this.textures[data.id];
      const ukwmaterial = new THREE.SpriteMaterial(o);
      ukwmaterial.transparent = true;
      ip.material = ukwmaterial;

      const a = ((i+.5)/funCount) * 2.0 * Math.PI;

      ip.position.y = (Math.random()-.5)*innerHeight*.25;
      ip.position.x = (r +  Math.random() * 500) * Math.sin(a);
      ip.position.z = (r + Math.random() * 500) * Math.cos(a);

      var c = ((2.0 * Math.PI * r)/funCount) * 1.0;// * .5;//fix this with trig?
      ip.scale.set(c,c,1);


    }


    for(const gif of gifs){

      if(!this.textures[gif.id]){

        if(gif.url)this.loadGif(gif.url, (t)=>{

          this.textures[gif.id] = t;
          this.children.forEach((ip, i) => {

            if(gif.id && ip.userData.data.id == gif.id){
              ip.material.map = t;
              ip.material.needsUpdate = true;
            }

          });

        });

      }
    }



}




}

export default Art
