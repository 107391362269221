class Enlargement {

  constructor(component) {
    this.init(component);
  }

  init(component) {

    if(!component){
      alert('no component!!!!');
      debugger;
    }

    this.component = component;
    this.view = new THREE.Group();

    this.component.view.classList.add('enlargement');
    const obj = new CSS2DObject(this.component.view);//
    this.object = obj;
    obj.userData.track = this.track;
    obj.element.style.backgroundColor = 'transparent';
    this.view.add(this.object);
    this.position();
    this.component.view.addEventListener('dispose', this.dispose.bind(this));


    //this.component.view.classList.add('gradient-border');

  }



  position(object) {

    this.frame = {tlX:50/innerWidth,trX:.55,tlY:.15,blY:.75};

    if(innerHeight > innerWidth) {
      this.frame =  {tlX:30/innerWidth,trX:1.0 - 30/innerWidth,tlY:100.0/innerHeight,blY:(1.0 - (200/innerHeight))};
    }

    var object = this.object;
    var item = object.userData.item;
    var artAspect = object.userData.aspectRatio;
    var frame = this.frame;

    var x = ((frame.trX + frame.tlX)*.5 - .5);// * innerWidth * .5;
    var y = ((frame.blY + frame.tlY)*.5 - .5) * -1.0;// * innerHeight * .5;
    var frameAspect = ((frame.trX - frame.tlX)*innerWidth)/((frame.blY-frame.tlY)*innerHeight);
    var maxWidth = (frame.trX - frame.tlX) * innerWidth;
    var maxHeight = (frame.blY - frame.tlY) * innerHeight;

    object.userData.positionPer = {x:x, y:y};

    if(!artAspect){
      object.userData.sizePx = {w:maxWidth, h:maxHeight};
    } else if(artAspect > frameAspect){
      object.userData.sizePx = {w:maxWidth, h:maxWidth/artAspect};
    } else {
      object.userData.sizePx = {w:maxHeight*artAspect, h:maxHeight};
    }

      object.userData.sizePx.w = Math.max(object.userData.sizePx.h - 100, 320);

      //var bumpDownPercent = (object.userData.sizePx.h * .5 + 35.0) / innerHeight;
      //var bumpLeftEdge = (object.userData.sizePx.w * .5) / innerWidth;
      var position = new THREE.Vector3(object.userData.positionPer.x * innerWidth, (object.userData.positionPer.y) * innerHeight + 15, 0);

      this.object.position.set(...position);
      this.object.element.style.width = object.userData.sizePx.w + 'px';
      this.object.element.style.height = object.userData.sizePx.h + 'px';
      this.object.element.style.pointerEvents = 'auto';

      console.log('resizing to ' + object.userData.sizePx.w + ' x ' + object.userData.sizePx.h)
    //  if(this.imageViewer)this.imageViewer.updateLayout();
      kv.update2d();
  }

  dispose() {
    this.view.remove(...this.view.children);
    if (this.view.parent) {
        this.view.parent.remove(this.view)
        kv.update2d();
    }
  }

}

export default Enlargement
