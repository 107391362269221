
class KiloPrompter {

  constructor(data) {
    this.render();
    this._bindEvents();

    this.layout();
  }

  _bindEvents() {
    this.view.addEventListener('click', e => {
      const el = e.target;
      if (el.classList.contains('btn')) {
        let command = [...el.classList].at(-1);
        kv.runKiloprompter(command);


      }

    });
  }


  layout() {

  }


  update(t) {

  }

  render() {

    if(!this.view){
      this.view = document.createElement('div');
  
    }else{
      this.view.innerHTML = ''
    }
    this.view.id = 'kiloPrompter';

    /*
    painting, photography, poem, letter, love letter, original artwork, original writing, invite friend, make it like a homescreen to do stuff….. (coming soon: sculpture , jewelry, drink, kilowear, music
    */

    let shit = [
      'photo',
      'painting',
      'sculpture',
      'poem',
      'loveletter',
      'original art',
      'original writing'
    ];

    let html = ``;

    for(const s of shit ){
      html += `<div style="background-color:#555;position:relative;height:100px;width:100px;color:white;float:left;margin-right:11px;margin-bottom:11px;" class="btn ${s}">
        <div style="width:100%;position:absolute;bottom:0px;left:0px;text-align:center;font-size:13px;" id="label">
          ${s}
        </div
      ></div>`
    }
    


    this.view.innerHTML = html;


  }

}

export default KiloPrompter;
