
class ExhibitEditor {

  constructor(data) {
    this.isNew = !data.name;
    this.data = data || {};
    this.skies = [];
    this.loadingmessage = '';
    this.render();
    this._bindEvents();
    this.layout();
    this.randomize();
  }

  _bindEvents() {
    this.view.addEventListener('click', e => {
      const el = e.target;
      if (el.classList.contains('btn') || el.classList.contains('delete')) {
        let command = [...el.classList].at(-1);
        this[command]();
      }
      if(el.classList.contains('sky')){
          this.index = el.dataset.index;
          this.setSky(el.dataset.index);
      }
    });
  }

  random(a) {
    return a[Math.floor(Math.random()*a.length)];
  }

  setSky(idx) {
     const gs =  document.querySelectorAll('.sky');
     for(const g of gs){
       if(g.dataset.index == idx) {
         g.classList.add('selected');
       }else{
         g.classList.remove('selected');
       }
     }
  }

  dispose() {

  }

  async randomize() {

    const cb = await kv.api2('imagine', 'GET', {});

      if(!this.data.name){
        this.data.skyFileName = kv.fileNameFromUrl(cb.sky.url);
        this.data.name = cb.name;
        this.data.backgroundPrompt = cb.location;
      }

      this.skies = cb.skies;

      if(this.data.skyFileName){

        for(let i =0; i < this.skies.length; i++){
          if(this.skies[i].url.includes(this.data.skyFileName)){
            this.index = i;
          }
        }


      }


      this.render();


  }



  layout() {

  }


  update(t) {


  }

  setLoading(loading, loadingText) {
    if(loadingText)this.loadingmessage = loadingText;
    this.isLoading = loading;
    this.render();
  }

  render() {

    if(!this.view){
      this.view = document.createElement('div');
    }else{
      this.view.innerHTML = ''
    }
    this.view.id = 'exhibitEditor';

    const gifUrl = require('./mountainloadersquare.gif');

    let loader = `
    <div style="background-color:black;position:absolute;height:100%;width:100%;">
    <div style="text-align:center;width:100%;height:100%;position:absolute;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
      <img src=${gifUrl} style="background:black;padding:5px;width:100px;border-radius:50%;">
      <div class="loadingmessage" style="width:100%;padding:50px;text-align:center;color:white;">${this.loadingmessage}</div>
    </div>
    </div>
    `;

    let skies = '';
    for(let i = 0; i < this.skies.length; i++) {
      let selected = (i == this.index) ? 'selected':'';
      //const s = this.skies[i];
      //const sfn = kv.fileNameFromUrl(s.url);
      const spng = this.skies[i].url + '.png';
      skies += `<div data-index="${i}" class="sky ${selected}"
      style="float:left;height:40px;width:40px;
      margin-right:3px;margin-bottom:3px;
      borderRadius:10px;background-color:gray;
      color:white;font-size:9px;
      background-size:cover;
      background-image:url('${spng}');"></div>`
    }


    let backgroundStuff = ``;

    const imgUrl = this.previewUrl || this.data.backgroundUrl;
    const prompt = this.previewPrompt || this.data.backgroundPrompt

    if(!imgUrl)this.editPromptMode = true;

    if(!this.editPromptMode){

      let buttons = ``;
      if(!this.previewUrl){
        buttons += `<button class="btn btn-primary btn-green-moon round editBackground" style="float:right;width:100px;">Edit</button>`;
      } else {
        buttons += `<button class="btn btn-primary btn-green-moon round generateBackground" style="float:right;">Generate Again</button>`;
        buttons += `<button class="btn btn-primary btn-green-moon round cancelBackground" style="float:right;">Cancel</button>`;
      }

      backgroundStuff += `<div style="display:flex;">
        <div style="flex-grow:1;height:222px;" id="backgroundPreview">
          <img style="height:222px;" src="${imgUrl}">
        </div>

        <div style="margin-left:15px;margin-left:15px;">
          <div>Backdrop:</div>
          <div style="11px;font-style:italic;">${prompt}</div>
          ${buttons}
        </div>
      </div>`;
    } else {

    //  let random = `A ${this.random(this.adj)} ${this.random(this.things)} ${this.random(this.places)}`;


      let prompt = this.data.backgroundPrompt ||  '';
      backgroundStuff += `<div style="" class="form-label">Describe the Location (be creative)</div>
      <textarea class="form-control" style="height:80px;" id="exhibitBackgroundInput">${prompt}</textarea>
      <div class="d-flex align-items-end flex-column">
        <button style="width:150px;" class="btn btn-primary btn-green-moon round mt-1 generateBackground">Generate</button>
      </div>`

    }

    const heading = this.isNew ? 'New Exhibit' : '';

    const stuff = `
    ${this.isLoading ? loader : ``}
    ${heading}
      <div class="p-0">
      <div class="form-label">Title</div>
      <input class="form-control" id="exhibitNameInput" value="${this.data.name || ''}">

      <div style="" id="skypallet">${skies}</div>

      <div style="clear:both;">
      ${backgroundStuff}
      </div>

      <button class="btn square deleteExhibit" style="background-color:red;">Delete</button>

      <button class="btn btn-primary btn-green-moon round mt-5 w-100 saveExhibit">${this.isNew?'Create':'Save'}</button>

      </div>`

    this.view.innerHTML = stuff;

  }

 getParams() {

    const p = {
      name:exhibitNameInput.value
    }

    if(this.data.id){
      p.id = this.data.id;
    }

    if(this.editPromptMode){
      this.previewPrompt = p.backgroundPrompt = p.prompt = exhibitBackgroundInput.value
    }

    const gs =  document.querySelectorAll('.sky.selected');
    if(gs.length > 0){
      const s = this.skies[gs[0].dataset.index];
        const sfn = kv.fileNameFromUrl(s.url);
        p.skyFileName = sfn;
        if(s.color)p.color = s.color;
    }

    if(this.previewUrl){
      p.backgroundUrl = this.previewUrl;
      p.backgroundPrompt = p.prompt = this.previewPrompt;
    }

    if(!p.backgroundUrl && this.data.backgroundUrl){
      p.backgroundUrl = this.data.backgroundUrl;
    }

    p.ownerId = kv.user.id;


    return p;

  }

  editBackground() {
    this.editPromptMode = true;//this.data.backgroundUrl;
    this.render();
  }

  cancelBackground() {
    this.editPromptMode = false;//this.data.backgroundUrl;
    delete this.previewUrl;
    delete this.previewPrompt;
    this.render();
  }

  async generateBackground(){

    const params = await this.getParams();
    this.setLoading(true, params.prompt + '');
    params.id = kv.makeId(5);
    params.type = 'background';
    //params.prompt += `4k, 8k, Hasselblad, Nikon, Fuji X-100, octane render, vimeostaffpick, incredible, wow, amazing, insane, lush, colorful, beautiful, ethereal`;

    params.url = `https://api.kiloverse.io/${params.skyFileName}.png`;
    //params.url = `https://api.kiloverse.io/landscape.png`;


    //params.maskId = `VCnhF3KrzZwgGiqhSyyt-mask`;
    //params.maskUrl = `/kiloverse/files/NEXrutJEjynnn3VbNy0v-mask.png`;
    params.maskUrl = `/kiloverse/files/OKzhvdlMgda2cscMNUH7-mask.png`;
    // `/kiloverse/files/VCnhF3KrzZwgGiqhSyyt-mask.png`;

    console.log(JSON.stringify(params));

    const t = await kv.api2('dream', 'POST', params);
    this.previewUrl = t.url;
    this.editPromptMode = false;
    this.setLoading(false);

  }

  async removeSkyServer(url) {
      const r = await  kv.api2('skymask', 'POST', {url:url});
      return r.maskUrl;
  }

  async deleteExhibit(e) {
    let result = confirm('Delete exhibit?');
    if(result){
      await kv.api2('deleteexhibit', 'POST', {xid:this.data.xid});
    }
    kv.dismissViewController();
  }
  async saveExhibit(e) {

    const data = this.getParams();
    //warning calling this before getparams wipes out the
    //form because i'm trying this component 'react style'
    this.setLoading(true, 'saving exhibit...');

    if(data.backgroundUrl){
      await this.removeSkyServer(data.backgroundUrl);
    }
    this.data =  await kv.api2('exhibit', 'POST', data);
    kv.updateExhibit(this.data);
    this.setLoading(false);

  }


}

export default ExhibitEditor;
